<template>
    <b-container class="pb-3 mw-100 h-100 login-image" :style="loginImage">
        <b-row class="m-2" align-h="center" v-show="size !== 'cols' && size !== 'sm'">
            <b-col class="mt-5" cols="12" sm="12" md="6" lg="5" xl="5">

                <b-row class="mb-3" align-h="center">
                    <i :class="`fas fa-cogs c-green ${isMobile ? 'fa-5x' : 'fa-10x'}`"/>
                </b-row>

                <b-row align-h="center">
                    <b-nav vertical>
                        <label class="row m-1" v-if="session && session.utente && session.utente.auth_mask && session.utente.auth_mask[maskValue('CHILD', 'READ')] === '1'">
                            <b-link id="child" :to="{ name: 'Child' }" class="BBB-link">
                                {{$tc('message.registry', 1)}} {{$tc('message.child', 2).toLowerCase()}}
                            </b-link>
                        </label>

                        <label class="row m-1" v-if="session && session.utente && session.utente.auth_mask && session.utente.auth_mask[maskValue('TRIP', 'READ')] === '1'">
                            <b-link id="trip" :to="{ name: 'Trip' }" class="BBB-link">
                                {{$tc('message.registry', 1)}} {{$tc('message.trip', 2).toLowerCase()}}
                            </b-link>
                        </label>

                      <label class="row m-1" v-if="session && session.utente && session.utente.auth_mask && session.utente.auth_mask[maskValue('ATTRIBUTE', 'READ')] === '1'">
                        <b-link id="trip" :to="{ name: 'Attribute' }" class="BBB-link">
                          {{$tc('message.registry', 1)}} {{$tc('message.attribute', 2).toLowerCase()}}
                        </b-link>
                      </label>

                      <label class="row m-1" v-if="session && session.utente && session.utente.auth_mask && session.utente.auth_mask[maskValue('DEPOSIT', 'READ')] === '1'">
                        <b-link id="trip" :to="{ name: 'Deposit' }" class="BBB-link">
                          {{$tc('message.registry', 1)}} {{$tc('message.deposit', 2).toLowerCase()}}
                        </b-link>
                      </label>

                      <label class="row m-1" v-if="session && session.utente && session.utente.auth_mask && session.utente.auth_mask[maskValue('TRIP', 'READ')] === '1'">
                        <b-link id="trip" :to="{ name: 'ReportPresences' }" class="BBB-link">
                          {{$tc('message.presenceReport', 1)}}
                        </b-link>
                      </label>

                      <label class="row m-1" v-if="session && session.utente && session.utente.auth_mask && session.utente.auth_mask[maskValue('TRIP', 'READ')] === '1'">
                        <b-link id="trip" :to="{ name: 'ExtraordinaryEvents' }" class="BBB-link">
                          {{$tc('message.report', 0)}} {{$tc('message.extraordinaryEvents', 1).toLowerCase()}}
                        </b-link>
                      </label>

                      <label class="row m-1" v-if="session && session.utente && session.utente.auth_mask && session.utente.auth_mask[maskValue('TRIP', 'READ')] === '1'">
                        <b-link id="trip" :to="{ name: 'TripAssignments' }" class="BBB-link">
                          {{$tc('message.report', 0)}} {{$tc('message.tripAssignments', 1).toLowerCase()}}
                        </b-link>
                      </label>
                    </b-nav>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
  import Vue from 'vue'

  export default {
    name: 'Admin',
    mounted () {
      this.getLoginImage()
      this.$root.$on('resize', () => {
        this.getLoginImage()
      })
    },
    data () {
      const session = Vue.getLocalStorage('session')
      return {
        session,
        loginImage: '',
        size: '',
        isMobile: false
      }
    },
    methods: {
      getLoginImage () {
        const size = Vue.myGetWindowSize()
        let background = ''

        switch (size) {
          case 'cols':
            background = 'background: url("/img/bbbus-login-720x1280.jpg")'
            break

          case 'sm':
            background = 'background: url("/img/bbbus-login-720x1280.jpg")'
            break

          case 'md':
            background = 'background: url("/img/bbbus-login-1024x683.jpg")'
            break

          default:
            background = 'background: url("/img/bbbus-login-1920x1280.jpg")'
            break
        }
        this.loginImage = background
        this.isMobile = size === 'cols' || size === 'sm' || size === 'md'
        this.size = size
      }
    }
  }
</script>
