<template>
  <b-container class="mw-100 operator">
    <b-card class="mt-4 mb-4">
      <standard-table ref="myTable"
                      :items="items"
                      :fields="getFields()"
                      :theadTrClass="'first-3-table-cols'"

                      :row-before-search="{cols: 12,sm: 12,md: 12,lg: 4,xl: 4,order: 1,orderSm: 1,orderMd: 1,orderLg: 3,orderXl: 3}"
                      :row-search="{cols: 12,sm: 12,md: 12,lg: 4,xl: 4,order: 1,orderSm: 2,orderMd: 2,orderLg: 1,orderXl: 1}"
                      :row-after-search="{cols: 12, sm: 12, md: 12, lg: 4, xl: 4, order: 2, orderSm: 3, orderMd: 3, orderLg: 2, orderXl: 2}"

                      :row-pagination="{cols: 12, sm: 12, md: 6, lg: 5, xl: 5}"
                      :row-between-pagination="{cols: 12, sm: 12, md: 1, lg: 1, xl: 4}"
                      :row-per-page="{cols: 12, sm: 12, md: 5, lg: 3, xl: 2}"
                      :row-after-per-page="{cols: 12, sm: 12, md: 12, lg: 3, xl: 1}"

                      fullWidth="true"
                      noContainerPadding="true"
                      @rowInfo="showRowInfo"
                      @rowEdit="pushToEdit"
                      @rowDelete="deleteItem">

        <b-container slot="row-before-search" class="mw-100 p-0">
          <b-row align-h="end">
            <b-col cols="auto">
              <b-button v-on:click="pushToEdit()" class="mb-3 b-c-blue" id="add-operator">
                {{ $t('message.add') }}
              </b-button>
            </b-col>
          </b-row>
        </b-container>

        <b-container slot="row-after-per-page" class="mw-100 p-0">
          <b-row>
            <b-col>
              <b-button class="float-right mt-3 mt-md-0 mt-lg-0 mt-xl-0" id="excel-button"
                        @click="$refs.myTable.excel($tc('message.operator', 2))">Excel
              </b-button>
            </b-col>
          </b-row>
        </b-container>

        <b-container slot="row-after-search" class="mw-100 p-0">
          <b-row>
            <b-col>
              <b-button v-on:click="resetFilters()" class="b-c-green mb-2 mr-2" id="reset-filters">
                {{ $t('message.resetFilters') }}
              </b-button>

              <b-button @click="showCollapse = !showCollapse"
                        :class="showCollapse ? 'b-c-red mb-2 mr-2' : 'b-c-green mb-2 mr-2'" id="show-filters">
                <span v-show="!showCollapse"
                      class="when-opened">{{ $t('message.show') }} {{ $tc('message.filter', 2).toLowerCase() }}</span>
                <span v-show="showCollapse"
                      class="when-closed">{{ $t('message.hide') }} {{ $tc('message.filter', 2).toLowerCase() }}</span>
              </b-button>
            </b-col>
          </b-row>
        </b-container>

        <b-container slot="after-search" class="mw-100 p-0">
          <b-collapse id="my-collapse" v-model="showCollapse">
            <b-container class="mw-100 m-0 p-0">
              <b-row>
                <b-col cols="12" sm="12" md="6" lg="4" xl="3">
                  <b-form-group :label="`${$tc('message.hasUser', 1)}`">
                    <v-select :options="associationOptions"
                              :reduce="obj => obj.value"
                              label="text"
                              v-model="associationFilter">
                    </v-select>
                  </b-form-group>
                </b-col>

                <b-col cols="12" sm="12" md="6" lg="4" xl="3">
                  <b-form-group :label="`${$tc('message.userStatus', 1)}`">
                    <v-select :options="statusOptions"
                              label="text"
                              :reduce="obj => obj.value"
                              v-model="statusFilter">
                    </v-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-container>
          </b-collapse>
        </b-container>
      </standard-table>
    </b-card>

    <b-modal ref="infoModal" id="infoModal" :title="`${$tc('message.details', 1)}`" size="lg">
      <b-container>
        <b-row>
          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <div class="d-flex">
              <h6><b>{{ $tc('message.registrationNumber', 1) }}</b></h6>
              <h6 class="ml-2">{{ infoItem.matricola || $tc('message.emptyValue') }}</h6>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <div class="d-flex">
              <h6><b>{{ $tc('message.surname', 1) }}</b></h6>
              <h6 class="ml-2">{{ infoItem.cognome || $tc('message.emptyValue') }}</h6>
            </div>
          </b-col>

          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <div class="d-flex">
              <h6><b>{{ $tc('message.name', 1) }}</b></h6>
              <h6 class="ml-2">{{ infoItem.nome || $tc('message.emptyValue') }}</h6>
            </div>
          </b-col>

          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <div class="d-flex">
              <h6><b>{{ $tc('message.taxCode', 1) }}</b></h6>
              <h6 class="ml-2">{{ infoItem.codice_fiscale || $tc('message.emptyValue') }}</h6>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <div class="d-flex">
              <h6><b>{{ $tc('message.provinceResidence', 1) }}</b></h6>
              <h6 class="ml-2">{{ infoItem.provincia_residenza || $tc('message.emptyValue') }}</h6>
            </div>
          </b-col>

          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <div class="d-flex">
              <h6><b>{{ $tc('message.commonResidence', 1) }}</b></h6>
              <h6 class="ml-2">{{ infoItem.comune_residenza || $tc('message.emptyValue') }}</h6>
            </div>
          </b-col>

          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <div class="d-flex">
              <h6><b>{{ $tc('message.residenceAddress', 1) }}</b></h6>
              <h6 class="ml-2">{{ infoItem.indirizzo_residenza || $tc('message.emptyValue') }}</h6>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <div class="d-flex">
              <h6><b>{{ $tc('message.officialEmail', 1) }}</b></h6>
              <h6 class="ml-2">{{ infoItem.email_ufficiale || $tc('message.emptyValue') }}</h6>
            </div>
          </b-col>

          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <div class="d-flex">
              <h6><b>{{ $tc('message.secondaryEmail', 1) }}</b></h6>
              <h6 class="ml-2">{{ infoItem.email_secondaria || $tc('message.emptyValue') }}</h6>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <div class="d-flex">
              <h6><b>{{ $tc('message.officialPhone', 1) }}</b></h6>
              <h6 class="ml-2 child2">{{ infoItem.telefono_principale || $tc('message.emptyValue') }}</h6>
            </div>
          </b-col>

          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <div class="d-flex">
              <h6><b>{{ $tc('message.secondaryPhone', 1) }}</b></h6>
              <h6 class="ml-2">{{ infoItem.telefono_secondario || $tc('message.emptyValue') }}</h6>
            </div>
          </b-col>
        </b-row>

        <b-row v-if="infoItem.id_utente">
          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <div class="d-flex">
              <h6><b>{{ $tc('message.username', 1) }}</b></h6>
              <h6 id="username" class="ml-2">{{ infoItem.username || $tc('message.emptyValue') }}</h6>
            </div>
          </b-col>

          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <div class="d-flex">
              <h6><b>{{ $tc('message.userStatus', 1) }}</b></h6>
              <h6 class="ml-2">{{ infoItem.userStatusText || $tc('message.emptyValue') }}</h6>
            </div>
          </b-col>
        </b-row>

        <b-row v-else>
          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <div class="d-flex">
              <h6><b>{{ $tc('message.hasUser', 1) }}</b></h6>
              <h6 class="ml-2">{{ infoItem.hasUser || $tc('message.emptyValue') }}</h6>
            </div>
          </b-col>
        </b-row>
      </b-container>

      <div slot="modal-footer" class="w-100 justify-content-end d-flex">
        <b-button class="b-c-green" @click="$refs.infoModal.hide()"> Ok</b-button>
      </div>
    </b-modal>
  </b-container>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'

export default {
  name: 'Operator',
  created () {
    this.fetchData()
  },
  updated () {
    this.$root.$emit('checkExcel')
  },
  watch: {
    associationFilter (obj) {
      this.getItems(obj, this.statusFilter)
    },
    statusFilter (obj) {
      this.getItems(this.associationFilter, obj)
    }
  },
  data () {
    return {
      associationOptions: [],
      associationFilter: null,
      statusOptions: [],
      statusFilter: null,
      showCollapse: false,
      items: [],
      originalItems: [],
      infoItem: {},
      users: []
    }
  },
  methods: {
    fetchData () {
      this.$root.$emit('activeLoader', true)
      this.getOptions().then(() => {
        this.getOperator()
      })
    },
    getOptions () {
      const axiosArray = []
      this.getStatusOptions()
      this.getAssociationOptions()
      axiosArray.push(this.getUsers())
      return Vue.myAll(axiosArray)
    },
    getStatusOptions () {
      this.statusOptions = [
        { value: false, text: this.$tc('message.enabled', 0) },
        { value: true, text: this.$tc('message.disabled', 0) }
      ]
    },
    getAssociationOptions () {
      this.associationOptions = [
        { value: false, text: this.$tc('message.no', 1) },
        { value: true, text: this.$tc('message.yes', 1) }
      ]
    },
    getUsers () {
      this.users = []
      return Vue.myGet(this, '/utente?all=true', 'utenti').then(users => {
        this.users = users
        return true
      }).catch(error => {
        Vue.manageErrors(this, error)
        return true
      })
    },
    getOperator () {
      this.items = []
      this.originalItems = []
      Vue.myGet(this, '/operatore', 'operatori').then((operators) => {
        const editedItems = []
        operators.forEach(operator => {
          const editedOperator = operator
          editedOperator.userStatusText = this.$tc('message.emptyValue', 1)
          editedOperator.userStatusVal = null
          editedOperator.hasUser = operator.id_utente ? this.$tc('message.yes', 1) : this.$tc('message.no', 1)
          if (operator.id_utente) {
            const foundedUser = _.find(this.users, obj => obj.id === operator.id_utente)
            if (foundedUser) {
              editedOperator.username = foundedUser.username
              editedOperator.userStatusText = foundedUser.sospeso ? this.$tc('message.disabled', 2) : this.$tc('message.enabled', 2)
              editedOperator.userStatusVal = foundedUser.sospeso
            }
          }
          editedItems.push(editedOperator)
        })
        this.items = editedItems
        this.originalItems = _.cloneDeep(editedItems)
        this.$root.$emit('activeLoader', false)
      }).catch(error => {
        Vue.manageErrors(this, error)
      })
    },
    getFields (isInfo) {
      return [
        { key: 'info', label: '', hideInTable: isInfo },
        { key: 'edit', label: '', hideInTable: isInfo },
        { key: 'delete', label: '', hideInTable: isInfo },
        {
          key: 'codice_fiscale',
          label: `${this.$tc('message.taxCode', 1)}`,
          sortable: true,
          sortDirection: 'desc',
          hideInTable: !isInfo
        },
        {
          key: 'matricola',
          label: `${this.$tc('message.registrationNumber', 1)}`,
          sortable: true,
          sortDirection: 'desc',
          hideInTable: !isInfo
        },
        { key: 'cognome', label: `${this.$tc('message.surname', 1)}`, sortable: true, sortDirection: 'desc' },
        { key: 'nome', label: `${this.$tc('message.name', 1)}`, sortable: true, sortDirection: 'desc' },
        {
          key: 'provincia_residenza',
          label: `${this.$tc('message.provinceResidence', 1)}`,
          sortable: true,
          sortDirection: 'desc',
          hideInTable: !isInfo
        },
        {
          key: 'comune_residenza',
          label: `${this.$tc('message.commonResidence', 1)}`,
          sortable: true,
          sortDirection: 'desc',
          hideInTable: !isInfo
        },
        {
          key: 'indirizzo_residenza',
          label: `${this.$tc('message.residenceAddress', 1)}`,
          sortable: true,
          sortDirection: 'desc',
          hideInTable: !isInfo
        },
        {
          key: 'email_ufficiale',
          label: `${this.$tc('message.officialEmail', 1)}`,
          sortable: true,
          sortDirection: 'desc'
        },
        {
          key: 'email_secondaria',
          label: `${this.$tc('message.secondaryEmail', 1)}`,
          sortable: true,
          sortDirection: 'desc',
          hideInTable: !isInfo
        },
        {
          key: 'telefono_principale',
          label: `${this.$tc('message.officialPhone', 1)}`,
          sortable: true,
          sortDirection: 'desc'
        },
        {
          key: 'telefono_secondario',
          label: `${this.$tc('message.secondaryPhone', 1)}`,
          sortable: true,
          sortDirection: 'desc',
          hideInTable: !isInfo
        },
        { key: 'hasUser', label: `${this.$tc('message.hasUser', 1)}`, sortable: true, sortDirection: 'desc' },
        { key: 'userStatusText', label: `${this.$tc('message.userStatus', 1)}`, sortable: true, sortDirection: 'desc' },
        {
          key: 'username',
          label: `${this.$tc('message.username', 1)}`,
          sortable: true,
          sortDirection: 'desc',
          hideInTable: !isInfo
        }
      ]
    },
    showRowInfo (event) {
      this.infoItem = event && event.item
      if (this.$refs.infoModal) this.$refs.infoModal.show()
    },
    pushToEdit (event) {
      const item = event && event.item
      item && item.id
          ? this.$router.push({
            name: 'EditOperator',
            params: { id: item.id }
          })
          : this.$router.push({
            name: 'EditOperator',
            params: { id: 'new' }
          })
    },
    deleteItem (event) {
      const item = event && event.item
      if (item && item.id) {
        this.$root.$emit('deleteItem', {
          description: item.ragione_sociale,
          path: `/operatore/${item.id}`,
          redirect: this.$route.path
        })
      }
    },
    resetFilters () {
      this.statusFilter = null
      this.associationFilter = null
      this.$refs.table.resetSearch()
    },
    getItems (hasUser, userStatus) {
      let editedItems = _.cloneDeep(this.originalItems)
      if (hasUser !== null) editedItems = _.filter(editedItems, obj => hasUser ? obj.id_utente : !obj.id_utente)
      if (userStatus !== null) editedItems = _.filter(editedItems, obj => obj.userStatusVal === userStatus)
      this.items = editedItems
    }
  }
}
</script>
