import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import BootstrapVue from 'bootstrap-vue'
import VueSidebarMenu from 'vue-sidebar-menu'
import VueI18n from 'vue-i18n'
import messages from './i18nMessages'
import IdleVue from 'idle-vue'
import { ValidationProvider, ValidationObserver, extend, validate, localize, configure } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import it from 'vee-validate/dist/locale/it.json'
import en from 'vee-validate/dist/locale/en.json'
import StandardTable from '@/components/vue-plugins/StandardTable'
import LinkList from '@/components/vue-plugins/LinkList'
import NumberFlip from '@/components/vue-plugins/NumberFlip'
import CalendarPicker from '@/components/vue-plugins/Calendarpicker'
import VueFlatPickr from 'vue-flatpickr-component'
import { ToggleButton } from 'vue-js-toggle-button'
import VueSlider from 'vue-slider-component'
import LangFlag from 'vue-lang-code-flags'
import vSelect from 'vue-select'
import draggable from 'vuedraggable'
import _, { isNil } from 'lodash'
import '@/plugins/my-mixin'
// plug-ins
import MyChangeBreadcrumb from './plugins/my-change-breadcrumb'
import MyCheckAllSelected from './plugins/my-check-all-selected'
import MyExcelFromDate from './plugins/my-excel-from-table'
import MyGetWindowSize from './plugins/my-get-window-size'
import MyLocalStorage from './plugins/my-local-storage'
import MyNotify from './plugins/my-notify'
import MyParseExcel from './plugins/my-parse-excel'
import MySelectAll from './plugins/my-select-all'
import MyServerInteraction from './plugins/my-server-interaction'
import MyTableFilter from './plugins/my-table-filter'
import MyTableSorted from './plugins/my-table-sorted'
import MySelectValidationClass from './plugins/my-select-validation'

// style css
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import '@fortawesome/fontawesome-free/css/all.css'
import 'flatpickr/dist/flatpickr.css'
import 'vue-slider-component/theme/default.css'
import 'vue-select/dist/vue-select.css'
require('es6-promise/auto')

extend('taxCode', {
  validate: value => /^[A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1}$/g.test(value)
})
extend('dateMax', {
  validate: (value, arrayOptions) => {
    const firstValue = value
    const secondValue = arrayOptions && arrayOptions[0]
    if (isNil(firstValue) || isNil(secondValue)) return null

    if (firstValue && secondValue) return firstValue <= secondValue
    return false
  }
})
extend('dateMin', {
  validate: (value, arrayOptions) => {
    const firstValue = value
    const secondValue = arrayOptions && arrayOptions[0]
    if (isNil(firstValue) || isNil(secondValue)) return null

    if (firstValue && secondValue) return firstValue >= secondValue
    return false
  }
})
it.messages.dateMin = field => 'La data ' + field + ' è minore della data di inizio'
en.messages.dateMin = field => 'The ' + field + ' field is not valid'
it.messages.dateMax = field => 'La data ' + field + ' è maggiore della data di fine'
en.messages.dateMax = field => 'The ' + field + ' field is not valid'
it.messages.timeMax = field => 'L\' ' + field + ' è minore dell\'orario di inizio'
en.messages.timeMax = field => 'The ' + field + ' field is not valid'
it.messages.taxCode = field => 'Il campo ' + field + ' non è valido'
en.messages.taxCode = field => 'The ' + field + ' field is not valid'

extend('passwordCheck', {
  validate: (value, arrayOptions) => {
    const firstValue = value
    const secondValue = arrayOptions && arrayOptions[1]

    if (!firstValue) return null
    if (firstValue && secondValue) return firstValue === secondValue
    return false
  }
})
it.messages.passwordCheck = (value, arrayOptions) => {
  const firstLabel = arrayOptions[2]
  const secondLabel = arrayOptions[3]
  if (firstLabel && secondLabel) return `La password del campo ${firstLabel} non combacia con la password del campo ${secondLabel}`
  return 'La password di conferma non corrisponde alla password principale'
}
en.messages.passwordCheck = (value, arrayOptions) => {
  const firstLabel = arrayOptions && arrayOptions[2]
  const secondLabel = arrayOptions && arrayOptions[3]
  if (firstLabel && secondLabel) return `Password in ${firstLabel} field does not match with password in ${secondLabel} field`
  return 'Password check does not match with main password'
}
extend('checkCalendar', {
  validate: (value, options) => {
    const calendar = _.first(options)
    if (!value) return null
    return !!calendar
  }
})
it.messages.checkCalendar = (value) => {
  if (!value) return null
  return 'Non è presente alcun calendario standard per la struttura selezionata'
}
en.messages.checkCalendar = (value) => {
  if (!value) return null
  return 'There are no standard calendar for selected structure'
}

for (const rule in rules) {
  extend(rule, rules[rule])
}

localize({ it, en })

const validationConfig = {
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  },
  bails: true,
  skipOptional: true,
  mode: 'aggressive',
  useConstraintAttrs: true
}

configure(validationConfig)

Vue.use(BootstrapVue)
Vue.use(VueSidebarMenu)
Vue.use(VueI18n)
Vue.use(VueFlatPickr, { readonly: false })
Vue.config.productionTip = false

const i18n = new VueI18n({ locale: navigator.language === 'it-IT' ? 'it' : 'en', messages, preserveDirectiveContent: true })

const eventsHub = new Vue()
const seconds = 60
const milliseconds = 1000
const minutes = 15

Vue.use(IdleVue, { eventEmitter: eventsHub, idleTime: minutes * seconds * milliseconds })
Vue.directive('validate', validate())
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('standard-table', StandardTable)
Vue.component('switch-button', ToggleButton)
Vue.component('link-list', LinkList)
Vue.component('number-flip', NumberFlip)
Vue.component('calendar-picker', CalendarPicker)
Vue.component('slider', VueSlider)
Vue.component('flag', LangFlag)
Vue.component('v-select', vSelect)
Vue.component('draggable', draggable)

Vue.use(MyChangeBreadcrumb)
Vue.use(MyCheckAllSelected)
Vue.use(MyExcelFromDate)
Vue.use(MyGetWindowSize)
Vue.use(MyLocalStorage)
Vue.use(MyNotify)
Vue.use(MyParseExcel)
Vue.use(MySelectAll)
Vue.use(MyServerInteraction)
Vue.use(MyTableFilter)
Vue.use(MyTableSorted)
Vue.use(MySelectValidationClass)

window.onload = () => {
  const newStart = new Date().getTime()
  const lastEnd = Vue.getLocalStorage('destroyed')
  let tabs = Vue.getLocalStorage('tabs')
  Vue.deleteLocalStorage('created')
  Vue.setLocalStorage('created', newStart)

  if (newStart - lastEnd > 60000 && tabs === null) {
    Vue.deleteLocalStorage('session')
    router.push({ name: 'Logout' }).catch(() => {})
  } else {
    Vue.deleteLocalStorage('destroyed')
  }

  if (tabs) {
    tabs += 1
    Vue.setLocalStorage('tabs', tabs)
  } else {
    tabs = 1
    Vue.setLocalStorage('tabs', tabs)
  }
}

window.onunload = () => {
  Vue.deleteLocalStorage('destroyed')
  Vue.setLocalStorage('destroyed', new Date().getTime())

  let inactive = Vue.getLocalStorage('inactive')
  let tabs = Vue.getLocalStorage('tabs')
  if (tabs) {
    tabs -= 1
    Vue.setLocalStorage('tabs', tabs)
  }

  if (tabs === 0) {
    Vue.deleteLocalStorage('tabs')
  }

  if (inactive && inactive >= 1) {
    inactive -= 1
    Vue.setLocalStorage('inactive', inactive)
  }

  if (inactive < 1) {
    Vue.setLocalStorage('inactive', 0)
  }
}

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
