import { render, staticRenderFns } from "./EditLegalPerson.vue?vue&type=template&id=a96b8046&"
import script from "./EditLegalPerson.vue?vue&type=script&lang=js&"
export * from "./EditLegalPerson.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports