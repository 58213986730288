/* eslint-disable no-param-reassign */

const MySelectAll = {
  install (Vue) {
    Vue.mySelectAll = (items, allSelected, perPage, currentPage, disableAutoUnSelection) => {
      const operativeIndexStart = (perPage * currentPage) - perPage
      const operativeIndexEnd = perPage * currentPage
      const editedItems = []
      items.forEach((item, index) => {
        if (index >= operativeIndexStart && index < operativeIndexEnd) {
          const editedItem = item
          editedItem.selected = allSelected
          editedItems.push(editedItem)
        } else {
          const editedItem = item
          if (!disableAutoUnSelection) editedItem.selected = false
          editedItems.push(editedItem)
        }
      })
      return editedItems
    }
  }
}

export default MySelectAll
