/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import location from '../../prop/location'
import { isNil } from 'lodash'

let BASE_URL = ''
const axios = require('axios')

switch (location) {
  case 'local':
    // BASE_URL = 'https://bimbumbus-milano-dev.awtech.it/ds'
    BASE_URL = 'http://localhost:8805'
    break

  case 'dev':
    BASE_URL = 'https://bimbumbus-milano-dev.awtech.it/ds'
    break

  case 'stage':
    BASE_URL = 'https://bimbumbus-milano-stage.awtech.it/ds'
    break

  case 'prod':
    BASE_URL = 'https://bimbumbus-milano.awtech.it/ds'
    break

  default:
    BASE_URL = ''
    break
}

function checkToken (Vue) {
  const session = Vue.getLocalStorage('session')
  const token = session && session.token
  axios.defaults.headers.Authorization = `BEARER ${token}`
  axios.defaults.baseURL = BASE_URL
}

function getErrorMessage (it, errorObj) {
  const error = errorObj && errorObj.response
  let errorMessage = ''
  if (error && error.data && error.data.constraint) {
    const constraints = it.$t(`constraints.${error.data.constraint}`, 1)
    let completeMessage = ''

    if (Array.isArray(constraints)) {
      constraints.forEach((constraint, index) => {
        const message = it.$tc(constraint.message, constraint.option)
        completeMessage += index !== 0 ? ` ${message.toLowerCase()}` : message
      })
    } else {
      completeMessage = constraints
    }
    errorMessage = completeMessage
  } else if (error && error.data && error.data.code) {
    errorMessage = it.$t(`httpCode.${error.status}.${error.data.code}`, 1)
  } else if (error && error.status) {
    if (it && it.$t) {
      errorMessage = it.$t(`httpCode.${error.status}`, 1)
      if ((error.status === 403 && error.data && error.data.code === 0) || error.status === 440) {
        it.$root.$emit('mustBeDisconnected', errorMessage)
      }
    }
  } else if (error) errorMessage = error
  return errorMessage
}

const MyServerInteraction = {
  install (Vue) {
    Vue.myGet = (it, path, obj) => {
      checkToken(Vue)
      return axios.get(path).then((response) => {
        if (response && response.data && response.data[obj]) {
          return response.data[obj]
        } else if (response && response.data) {
          return response.data
        }
        return response
      }).catch(r => { throw r })
    }
    Vue.patchOrPost = (patch, path, obj) => {
      checkToken(Vue)
      return patch ? axios.patch(path, obj) : axios.post(path, obj)
    }
    Vue.myDelete = (path) => {
      checkToken(Vue)
      return axios.delete(path).then(r => r).catch(r => { throw r })
    }
    Vue.getBaseUrl = () => {
      checkToken(Vue)
      return BASE_URL
    }
    Vue.myAll = (array) => {
      return axios.all(array).then(r => r).catch(r => { throw r })
    }
    Vue.manageErrors = (it, error, hideMessage, otherInfo) => {
      it.$root.$emit('activeLoader', false)
      let message = getErrorMessage(it, error)
      if (!isNil(otherInfo)) message += otherInfo
      if (message && (isNil(hideMessage) || !hideMessage)) {
        Vue.error(it, message)
        console.log(message)
      }
      return message
    }
    Vue.myLogin = (it, username, password) => {
      checkToken(Vue)
      return axios.get('/login', {
        auth: { username, password }
      }).then((response) => {
        const session = response.data
        Vue.setLocalStorage('session', session)
        Vue.deleteLocalStorage('isExpelled')
        return response
      }).catch((error) => {
        throw error
      })
    }
      Vue.getAttributes = () => {
        checkToken(Vue)
        return Vue.myGet(this, '/attributo', 'attributi').then((attributes) => {
          return attributes
        }).catch(error => {
          throw error
        })
      }
  }
}

export default MyServerInteraction
