<template>
    <b-container class="mw-100 h-100">
        <b-row align-h="center" align-v="center">
            <b-col cols="auto">
                <b-button v-if="roleId === 1" @click="clearPermissions()">
                    1. Pulisci permessi
                </b-button>
            </b-col>

            <b-col cols="auto">
                <b-button v-if="roleId === 1 && permissionCleared" @click="updatePermissions()">
                    2. Aggiorna permessi
                </b-button>
            </b-col>
        </b-row>

        <b-row v-if="errors.length !== 0">
            <b-col cols="12">
                Errori:
            </b-col>

            <b-col cols="12">
                <div v-for="(error, index) in errors" :key="index" class="c-red">
                    [{{index}}] {{error}}
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
  import Vue from 'vue'
  import _ from 'lodash'
  import resourcesObj from './../../prop/resource'

  export default {
    name: 'ResourcesUpdate',
    data () {
      const session = Vue.getLocalStorage('session')
      const roleId = session && session.utente && session.utente.ruolo && session.utente.ruolo.id
      return {
        roleId,
        resources: [],
        permissionCleared: false,
        errors: [],
        originalResources: []
      }
    },
    mounted () {
      if (this.roleId !== 1) this.logOut()
      else this.resources = resourcesObj.risorse
    },
    methods: {
      logOut () {
        this.$router.push('/logout')
      },
      clearPermissions () {
        const notFoundedItems = []
        const errorMessages = []
        const extraResources = _.cloneDeep(this.resources)
        this.$root.$emit('activeLoader', true)
        Vue.myGet(this, '/risorsa', 'risorse').then(resources => {
          resources.forEach(item => {
            const foundedItem = _.find(extraResources, obj => obj.local_url === item.local_url && obj.metodo === item.metodo)
            if (!foundedItem) notFoundedItems.push(item)
            else _.remove(extraResources, obj => obj.local_url === item.local_url && obj.metodo === item.metodo)
          })

          if (notFoundedItems.length === 0 && extraResources.length === 0) {
            Vue.success(this, 'Controllo di congruenza tra il server e il file resource.json superato con successo')
            this.resetPermissions(resources)
          } else {
            notFoundedItems.forEach(item => {
              errorMessages.push(`Errore sul servizio: "${item.local_url}", metodo:"${item.metodo}", motivo: "Non presente nel file resource.json"`)
            })

            extraResources.forEach(item => {
              errorMessages.push(`Errore sul servizio: "${item.local_url}", metodo:"${item.metodo}", motivo: "Servizio presente in resource.json, ma non sul server"`)
            })

            this.errors = errorMessages
            this.$root.$emit('activeLoader', false)
          }
        }).catch(error => {
          Vue.manageErrors(this, error)
          this.logOut()
        })
      },
      resetPermissions (resources) {
        const axiosArray = []
        resources.forEach(item => {
          const editedObj = item
          editedObj.ruoli = [{ id: 1 }]
          axiosArray.push(Vue.patchOrPost(true, `risorsa/${item.id}`, editedObj))
        })
        Vue.myAll(axiosArray).then(() => {
          Vue.success(this, 'Tutti i servizi sono stati reimpostati')
          this.permissionCleared = true
          this.$root.$emit('activeLoader', false)
          this.originalResources = resources
        })
      },
      updatePermissions () {
        this.$root.$emit('activeLoader', true)
        const axiosArray = []
        const localResources = _.cloneDeep(this.resources)
        this.originalResources.forEach(item => {
          const foundedIem = _.find(localResources, obj => obj.local_url === item.local_url && obj.metodo === item.metodo)
          foundedIem.id = item.id
          axiosArray.push(Vue.patchOrPost(true, `risorsa/${foundedIem.id}`, foundedIem))
        })
        Vue.myAll(axiosArray).then(() => {
          Vue.success(this, 'Tutti i servizi sono stati aggiornati')
          this.$root.$emit('activeLoader', false)
          this.permissionCleared = false
        })
      }
    }
  }
</script>
