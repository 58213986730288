/* eslint-disable no-param-reassign */
const routeViews = require('../router-views')
const _ = require('lodash')

let items = []

const MyChangeBreadcrumb = {
  install (Vue) {
    Vue.myChangeBreadcrumb = (it, to, roleId) => {
      const editedTo = {
        path: to.path,
        params: to.params
      }
      if (to.params && to.params.id) {
        let isDynamicRoute = false
        const id = `${to.params.id}`
        const pathSections = to.path.split('/')
        let newPath = ''
        pathSections.forEach((pathSection) => {
          if (pathSection) {
            if (pathSection === id) {
              isDynamicRoute = true
              newPath += '/:id'
            } else {
              newPath += `/${pathSection}`
            }
          }
        })
        if (isDynamicRoute) {
          editedTo.path = newPath
        }
      }
      items = []
      switch (roleId) {
        case 1:
          if (editedTo.path !== '/superadmin' && editedTo.path !== '/') items.push({ text: 'Home', href: '#/superadmin' })
          break

        case 2:
          if (editedTo.path !== '/admin' && editedTo.path !== '/') items.push({ text: 'Home', href: '#/admin' })
          break

        case 3:
          if (editedTo.path !== '/operator_home' && editedTo.path !== '/') items.push({ text: 'Home', href: '#/operator_home' })
          break

        default:
          break
      }
      routeViews.default.allItems.forEach((group) => {
        const branch = getChild(it, group, editedTo, [])
        if (branch.length !== 0) {
          const reverseBranch = _.reverse(branch)
          reverseBranch.forEach((branchRoad) => {
            items.push(branchRoad)
          })
        }
      })
      return items
    }
  }
}

function getChildName (it, group) {
  let name = ''
  if (group.title && Array.isArray(group.title)) {
    group.title.forEach((namePart, index) => {
      if (!isNaN(namePart.num) && index === 0) {
        name += `${it.$tc(namePart.option, namePart.num)} `
      } else if (!isNaN(namePart.num) && index !== 0) {
        name += `${it.$tc(namePart.option, namePart.num).toLowerCase()} `
      } else if ((namePart.num === null || namePart.num === undefined) && index !== 0) {
        name += `${it.$t(namePart.option).toLowerCase()} `
      } else {
        name += `${it.$t(namePart.option)} `
      }
    })
  } else {
    name = group.title
  }
  return name
}

function getChild (it, group, to, branch) {
  const newBranch = branch
  let result = []
  if (group.child && to.path !== group.path) {
    const branchObj = {
      text: getChildName(it, group)
    }
    if (group.path) {
      branchObj.href = `#${group.path}`
    }
    group.child.forEach((child) => {
      const foundedBranch = getChild(it, child, to, newBranch)
      if (foundedBranch.length !== 0) {
        newBranch.push(branchObj)
        result = foundedBranch
      }
    })
    return result
  } else if (to.path === group.path) {
    newBranch.push({ text: getChildName(it, group), href: `#${group.path}` })
    return newBranch
  }
  return []
}

export default MyChangeBreadcrumb
