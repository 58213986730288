<template>
  <b-container :class="tabIndex === 1 ? 'mw-100' : ''">
    <b-card class="trip  mt-4 mb-4">
      <b-tabs v-model="tabIndex">
        <b-tab :title="`${$tc('message.singleTrip', 2)}`" active>
      <standard-table ref="myTable"
                      :items="itemsSingleTrips"
                      :fields="getFields()"
                      :theadTrClass="session && session.utente && session.utente.auth_mask && session.utente.auth_mask[maskValue('TRIP', 'UPDATE')] === '1'
                      && session && session.utente && session.utente.auth_mask && session.utente.auth_mask[maskValue('TRIP', 'DELETE')] === '1'
                      ? 'first-3-table-cols'
                      : session && session.utente && session.utente.auth_mask
                      && session.utente.auth_mask[maskValue('STOP', 'READ')] === '1' ? 'first-2-table-cols' : ''"
                      id="stops"
                      :row-before-search="{cols: 12,sm: 12,md: 12,lg: 4,xl: 4,order: 1,orderSm: 1,orderMd: 1,orderLg: 3,orderXl: 3}"
                      :row-search="{cols: 12,sm: 12,md: 12,lg: 4,xl: 4,order: 1,orderSm: 2,orderMd: 2,orderLg: 1,orderXl: 1}"
                      :row-after-search="{cols: 12, sm: 12, md: 12, lg: 4, xl: 4, order: 2, orderSm: 3, orderMd: 3, orderLg: 2, orderXl: 2}"

                      :row-pagination="{cols: 12, sm: 12, md: 6, lg: 5, xl: 5}"
                      :row-between-pagination="{cols: 12, sm: 12, md: 1, lg: 1, xl: 4}"
                      :row-per-page="{cols: 12, sm: 12, md: 5, lg: 3, xl: 2}"
                      :row-after-per-page="{cols: 12, sm: 12, md: 12, lg: 3, xl: 1}"

                      fullWidth="true"
                      noContainerPadding="true"
                      @rowInfo="pushToShowInfo"
                      @rowEdit="pushToEdit"
                      @rowDelete="deleteItem">

        <b-container slot="row-before-search" class="mw-100 p-0" v-if="session && session.utente && session.utente.auth_mask && session.utente.auth_mask[maskValue('TRIP', 'CREATE')] === '1'">
          <b-row align-h="end">
            <b-col cols="auto">
              <b-button v-on:click="pushToEdit()" class="mb-3 b-c-blue" id="add-trip">
                {{ $t('message.add') }}
              </b-button>
            </b-col>
            <b-col cols="auto">
              <b-button v-on:click="showImportModal" class="mb-3 b-c-blue" id="add-trip">
                {{ $t('message.importTrip') }}
              </b-button>
            </b-col>
          </b-row>
        </b-container>

        <b-container slot="row-after-per-page" class="mw-100 p-0">
          <b-row>
            <b-col>
              <b-button class="float-right mt-3 mt-md-0 mt-lg-0 mt-xl-0" id="excel-button"
                        @click="$refs.myTable.excel($tc('message.trip', 2))">Excel
              </b-button>
            </b-col>
          </b-row>
        </b-container>

        <b-container slot="row-after-search" class="mw-100 p-0">
          <b-row>
            <b-col>
              <b-button v-on:click="resetFilters()" class="b-c-green mb-2 mr-2" id="reset-filters">
                {{ $t('message.resetFilters') }}
              </b-button>

              <b-button @click="showCollapse = !showCollapse"
                        :class="showCollapse ? 'b-c-red mb-2 mr-2' : 'b-c-green mb-2 mr-2'" id="show-filters">
                <span v-show="!showCollapse"
                      class="when-opened">{{ $t('message.show') }} {{ $tc('message.filter', 2).toLowerCase() }}</span>
                <span v-show="showCollapse"
                      class="when-closed">{{ $t('message.hide') }} {{ $tc('message.filter', 2).toLowerCase() }}</span>
              </b-button>
            </b-col>
          </b-row>
        </b-container>

        <b-container slot="after-search" class="mw-100 p-0">
          <b-collapse id="my-collapse" v-model="showCollapse">
            <b-container class="mw-100 m-0 p-0">
              <b-row>
                <b-col cols="12" sm="12" md="6" lg="4" xl="3">
                  <b-form-group :label="`${$tc('message.tripStatus', 1)}`">
                    <v-select :options="statusOptions"
                              label="text"
                              :reduce="obj => obj.value"
                              v-model="statusFilter">
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12" sm="12" md="6" lg="6" xl="3">
                  <b-form-group :label="`${$tc('message.dateStart', 1)}`">
                    <b-input-group>
                      <flat-pickr id="start"
                                  ref="start"
                                  v-model="startFilter"
                                  @input="getTrip(startFilter, endFilter)"
                                  :config="startConfig">
                      </flat-pickr>

                      <div class="input-group-append">
                        <b-button class="fas fa-trash"
                                  @click="startFilter = null"
                                  :disabled="startFilter === null">
                        </b-button>
                      </div>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col cols="12" sm="12" md="6" lg="6" xl="3">
                  <b-form-group :label="`${$tc('message.dateEnd', 1)}`">
                    <b-input-group>
                      <flat-pickr id="end"
                                  ref="end"
                                  v-model="endFilter"
                                  @input="getTrip(startFilter, endFilter)"
                                  :config="endConfig">
                      </flat-pickr>

                      <div class="input-group-append">
                        <b-button class="fas fa-trash"
                                  @click="endFilter = null"
                                  :disabled="endFilter === null">
                        </b-button>
                      </div>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-container>
          </b-collapse>
        </b-container>
      </standard-table>
        </b-tab>

        <b-tab :title="`${$tc('message.periodicTrip', 2)}`">
          <standard-table ref="myTable"
                          :items="itemsTemplates"
                          :fields="getFields(null, null, 'PERIODIC')"
                          :theadTrClass="session && session.utente && session.utente.auth_mask && session.utente.auth_mask[maskValue('TRIP', 'UPDATE')] === '1'
                      && session && session.utente && session.utente.auth_mask && session.utente.auth_mask[maskValue('TRIP', 'DELETE')] === '1'
                      ? 'first-3-table-cols'
                      : session && session.utente && session.utente.auth_mask
                      && session.utente.auth_mask[maskValue('STOP', 'READ')] === '1' ? 'first-table-2-cols' : ''"
                          id="templates"
                          :row-before-search="{cols: 12,sm: 12,md: 12,lg: 4,xl: 4,order: 1,orderSm: 1,orderMd: 1,orderLg: 3,orderXl: 3}"
                          :row-search="{cols: 12,sm: 12,md: 12,lg: 4,xl: 4,order: 1,orderSm: 2,orderMd: 2,orderLg: 1,orderXl: 1}"
                          :row-after-search="{cols: 12, sm: 12, md: 12, lg: 4, xl: 4, order: 2, orderSm: 3, orderMd: 3, orderLg: 2, orderXl: 2}"

                          :row-pagination="{cols: 12, sm: 12, md: 6, lg: 5, xl: 5}"
                          :row-between-pagination="{cols: 12, sm: 12, md: 1, lg: 1, xl: 4}"
                          :row-per-page="{cols: 12, sm: 12, md: 5, lg: 3, xl: 2}"
                          :row-after-per-page="{cols: 12, sm: 12, md: 12, lg: 3, xl: 1}"

                          :sub-table-fields="getFields(null, null, 'SUBPERIODIC')"
                          :sub-tr-class="session && session.utente && session.utente.auth_mask && session.utente.auth_mask[maskValue('TRIP', 'UPDATE')] === '1'
                      && session && session.utente && session.utente.auth_mask && session.utente.auth_mask[maskValue('TRIP', 'DELETE')] === '1'
                      ? 'first-3-table-cols'
                      : session && session.utente && session.utente.auth_mask
                      && session.utente.auth_mask[maskValue('STOP', 'READ')] === '1' ? 'first-table-2-cols' : ''"
                          @rowEditSub="pushToEditSub"
                          @rowInfoSub="pushToShowInfoSub"
                          @rowDeleteSub="deleteItemSub"

                          fullWidth="true"
                          noContainerPadding="true"
                          @rowInfo="pushToShowInfo"
                          @rowEdit="pushToEdit"
                          @rowDelete="deleteItem">

            <b-container slot="row-before-search" class="mw-100 p-0" v-if="session && session.utente && session.utente.auth_mask && session.utente.auth_mask[maskValue('TRIP', 'CREATE')] === '1'">
              <b-row align-h="end">
                <b-col cols="auto">
                  <b-button v-on:click="pushToEdit()" class="mb-3 b-c-blue" id="add-trip">
                    {{ $t('message.add') }}
                  </b-button>
                </b-col>
                <b-col cols="auto">
                  <b-button v-on:click="showImportModal" class="mb-3 b-c-blue" id="add-trip">
                    {{ $t('message.importTemplate') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-container>

            <b-container slot="row-after-per-page" class="mw-100 p-0">
              <b-row>
                <b-col>
                  <b-button class="float-right mt-3 mt-md-0 mt-lg-0 mt-xl-0" id="excel-button"
                            @click="$refs.myTable.excel($tc('message.trip', 2))">Excel
                  </b-button>
                </b-col>
              </b-row>
            </b-container>

            <b-container slot="row-after-search" class="mw-100 p-0">
              <b-row>
                <b-col>
                  <b-button v-on:click="resetFilters()" class="b-c-green mb-2 mr-2" id="reset-filters">
                    {{ $t('message.resetFilters') }}
                  </b-button>

                  <b-button @click="showCollapse = !showCollapse"
                            :class="showCollapse ? 'b-c-red mb-2 mr-2' : 'b-c-green mb-2 mr-2'" id="show-filters">
                <span v-show="!showCollapse"
                      class="when-opened">{{ $t('message.show') }} {{ $tc('message.filter', 2).toLowerCase() }}</span>
                    <span v-show="showCollapse"
                          class="when-closed">{{ $t('message.hide') }} {{ $tc('message.filter', 2).toLowerCase() }}</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-container>

            <b-container slot="after-search" class="mw-100 p-0">
              <b-collapse id="my-collapse" v-model="showCollapse">
                <b-container class="mw-100 m-0 p-0">
                  <b-row>
                    <b-col cols="12" sm="12" md="6" lg="4" xl="3">
                      <b-form-group :label="`${$tc('message.tripStatus', 1)}`">
                        <v-select :options="statusOptions"
                                  label="text"
                                  :reduce="obj => obj.value"
                                  v-model="statusFilter">
                        </v-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-container>
              </b-collapse>
            </b-container>
          </standard-table>
        </b-tab>
      </b-tabs>
    </b-card>

    <b-modal ref="infoModal" id="infoModal" :title="`${$tc('message.details', 1)}`">
      <b-container>
        <b-row>
          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <div class="d-flex">
              <h6><b>{{ $tc('message.name', 1) }}</b></h6>
              <h6 class="ml-2">{{ infoItem.nome || $tc('message.emptyValue') }}</h6>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <div class="d-flex">
              <h6><b>{{ $tc('message.description', 1) }}</b></h6>
              <h6 class="ml-2">{{ infoItem.descrizione || $tc('message.emptyValue') }}</h6>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <div class="d-flex">
              <h6><b>{{ $tc('message.enabled', 0) }}</b></h6>
              <h6 class="ml-2">{{ infoItem.attiva ? $tc('message.yes', 1) : $tc('message.no', 1) }}</h6>
            </div>
          </b-col>
        </b-row>
      </b-container>

      <div slot="modal-footer" class="w-100 justify-content-end d-flex">
        <b-button class="b-c-green" @click="$refs.infoModal.hide()"> Ok</b-button>
      </div>
    </b-modal>
    <b-modal ref="importModal" id="importModal" :title="`${$tc('message.importTrip', 1)}`" size="lg">
      <b-container>
        <b-row>
          <b-col>
            <!-- Document -->
            <b-form-group label="Excel:">
              <b-form-file v-model="tripImportFile"
                           id="import-children-file"
                           ref="importChildrenFile"
                           accept=".xlsx, .xls"
                           v-on:input="tripFileImported"
                           :placeholder="$tc('message.selectFile', 1)">
              </b-form-file>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>

      <div slot="modal-footer" class="w-100 justify-content-end d-flex">
        <b-button class="b-c-green m-1"
                  v-on:click="startImport(tripImportFile)"
                  :disabled="!enableImport || !tripImportFile"> {{$t('message.import')}}
        </b-button>

        <b-button v-if="tabIndex === 1"
                  type="button"
                  class="m-1"
                  href="./ModelloImportPercorsoPeriodico.xlsx"
                  :target="'_blanck'" download> {{$tc('message.modelDownloadTemplate', 1)}}
        </b-button>

        <b-button v-if="tabIndex === 0"
                  type="button"
                  class="m-1"
                  href="./ModelloImportPercorsoSingolo.xlsx"
                  :target="'_blanck'" download> {{$tc('message.modelDownload', 1)}}
        </b-button>

        <b-button type="button"
                  class="m-1"
                  @click="$refs.importModal.hide()"> {{$tc('message.close', 1)}}
        </b-button>
      </div>
    </b-modal>
    <b-modal ref="importModalErrors" id="importModalErrors" :title="`${$t('message.caution')}!`"
             @shown="errorsAreShown" size="lg"
             no-close-on-backdrop no-close-on-esc hide-header-close>
      <b-container fluid>
        <b-row>
          <b-col>
            <h4>{{$t('errors.errorsList')}}:</h4>
          </b-col>
        </b-row>

        <b-row>
          <b-container>
            <b-list-group>
              <b-list-group-item v-for="(item, index) in importErrors" :key="index">
                <b-row>
                  <b-col cols="12" sm="12" v-if="item.item">
                    <h6 class="c-red font-weight-bold">{{$tc('message.row', 1)}}: {{item.item.index}}</h6>
                    <h6 class="c-red font-weight-bold">{{item.item.type === 'G' ? $tc('message.trip', 1) : item.item.type === 'F' ? $tc('message.stop', 1) : item.item.type === 'R' ? $tc('message.register', 1) : ''}}</h6>
                  </b-col>

                  <b-col cols="12" sm="12">
                    <div v-for="message in item.errors" :key="message">
                      <h6 class="c-red font-italic">- {{message}} </h6>
                    </div>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </b-container>
        </b-row>
      </b-container>

      <div slot="modal-footer" class="w-100 justify-content-end d-flex">
        <b-button id="nw-ok-error-message" class="m-1 b-c-green" @click="hideMainErrorMessageModal()">Ok</b-button>
      </div>
    </b-modal>
  </b-container>
</template>

<script>
import Vue from 'vue'
import _, { isNil } from 'lodash'
import { DateTime } from 'luxon'
import { Italian } from 'flatpickr/dist/l10n/it'

export default {
  name: 'Trip',
  created () {
    this.fetchData()
  },
  updated () {
    this.$root.$emit('checkExcel')
  },
  watch: {
    statusFilter () {
      this.$refs.myTable.resetSearch()
      this.getTrip()
    },
      startFilter () {
        this.endConfig.minDate = this.startFilter
      },
      endFilter () {
        this.startConfig.maxDate = this.endFilter
      },
      tabIndex () {
        if (this.tabIndex === 0) {
          this.endFilter = DateTime.fromISO(new Date(new Date().setDate(new Date().getDate() + 7)).toISOString()).toISODate()
          this.startFilter = `${DateTime.local().toISODate()}`
          this.getTrip()
        } else {
          this.getTrip()
        }
      }
  },
  data () {
    const session = Vue.getLocalStorage('session')
    const endFilter = DateTime.fromISO(new Date(new Date().setDate(new Date().getDate() + 7)).toISOString()).toISODate()
    const startFilter = `${DateTime.local().toISODate()}`
    return {
      session,
      endFilter,
      startFilter,
      startConfig: {
        dateFormat: 'Y-m-d',
        mode: 'single',
        altFormat: 'D j M Y',
        altInput: true,
        locale: Italian,
        minDate: null,
        maxDate: endFilter,
        disable: []
      },
      endConfig: {
        dateFormat: 'Y-m-d',
        mode: 'single',
        altFormat: 'D j M Y',
        altInput: true,
        locale: Italian,
        minDate: startFilter,
        maxDate: null,
        disable: []
      },
      associationOptions: [],
      associationFilter: null,
      statusOptions: [],
      statusFilter: true,
      showCollapse: false,
      itemsSingleTrips: [],
      itemsTemplates: [],
      originalItems: [],
      infoItem: {},
      users: [],
      tripImportFile: null,
      enableImport: false,
      newImportedTripID: null,
      newImportedStopID: [],
      importErrors: [],
      axiosArray: [],
      definedTrip: false,
      newImportedTripStopID: null,
      tabIndex: 0,
      variationsOfTemplate: [],
      currentVariations: [],
      standardAssistants: []
    }
  },
  methods: {
    fetchData () {
      this.$root.$emit('activeLoader', true)
      this.getOptions().then(() => {
        this.getTrip()
        this.getDeposits()
      })
    },
    getAssistants () {
      return Vue.myGet(this, '/bus/assistente', 'assistenti').then(assistants => {
        this.standardAssistants = _.orderBy(assistants, 'id_letterale')
        return true
      }).catch(error => {
        Vue.manageErrors(this, error)
        return true
      })
    },
    getOptions () {
      const axiosArray = []
      axiosArray.push(this.getStatusOptions())
      axiosArray.push(this.getAssistants())
      return Vue.myAll(axiosArray)
    },
    getStatusOptions () {
      this.statusOptions = [
        { value: true, text: this.$tc('message.enabled', 0) },
        { value: false, text: this.$tc('message.disabled', 0) }
      ]
      return this.statusOptions
    },
    getTrip () {
      this.itemsSingleTrips = []
      this.originalItems = []
      this.variationsOfTemplate = []
      let path = '/bus/gita?'
      if (this.tabIndex === 0) {
        if (!isNil(this.startFilter)) {
          path += 'da=' + this.startFilter + '&'
        }
        if (!isNil(this.endFilter)) {
          path += 'a=' + this.endFilter + '&'
        }
      }

      if (!isNil(this.statusFilter)) {
        path += 'attiva=' + this.statusFilter + '&'
      }
      Vue.myGet(this, path, 'gite').then((trips) => {
        const editedItemsSingleTrips = []
        trips.forEach(trip => {
          const editedTrip = trip
          editedTrip.activeText = trip.attiva ? this.$tc('message.yes', 1) : this.$tc('message.no', 1)
          if (!isNil(editedTrip.data)) {
            editedTrip.data = DateTime.fromISO(editedTrip.data).toFormat('dd/MM/yyyy')
          }
          if (isNil(editedTrip.id_periodico)) {
            editedItemsSingleTrips.push(editedTrip)
          } else {
            this.variationsOfTemplate.push(editedTrip)
          }
        })
        this.itemsSingleTrips = _.orderBy(editedItemsSingleTrips, 'data')
        this.originalItems = _.cloneDeep(this.itemsSingleTrips)
        this.$root.$emit('activeLoader', false)
        this.itemsSingleTrips = _.filter(this.itemsSingleTrips, obj => obj.attiva === this.statusFilter)

        Vue.myGet(this, '/bus/percorso_periodico', 'percorsi_periodici').then((periodicTrips) => {
          const editedItemsPeriodicTrips = []
          periodicTrips.forEach(trip => {
            const editedTrip = trip
            if (!isNil(editedTrip.da)) {
              editedTrip.da = DateTime.fromISO(editedTrip.da).toFormat('dd/MM/yyyy')
            }
            if (!isNil(editedTrip.a)) {
              editedTrip.a = DateTime.fromISO(editedTrip.a).toFormat('dd/MM/yyyy')
            }
            editedTrip.activeText = trip.attiva ? this.$tc('message.yes', 1) : this.$tc('message.no', 1)
            editedTrip.children = _.filter(this.variationsOfTemplate, (variation) => variation.id_periodico === editedTrip.id)
            editedItemsPeriodicTrips.push(editedTrip)
          })
          this.itemsTemplates = _.orderBy(editedItemsPeriodicTrips, 'nome')
          this.$root.$emit('activeLoader', false)
          this.itemsTemplates = _.filter(this.itemsTemplates, obj => obj.attiva === this.statusFilter)
        }).catch(error => {
          Vue.manageErrors(this, error)
        })
      }).catch(error => {
        Vue.manageErrors(this, error)
      })
    },
    getFields (fieldsToShow, hideOther, type) {
      const fields = []
      if (this.session && this.session.utente && this.session.utente.auth_mask && this.session.utente.auth_mask[this.maskValue('TRIP', 'UPDATE')] === '1') {
        fields.push({ key: 'edit', label: '' })
      }
      if (this.session && this.session.utente && this.session.utente.auth_mask && this.session.utente.auth_mask[this.maskValue('TRIP', 'DELETE')] === '1') {
        fields.push({ key: 'delete', label: '' })
      }
      fields.push({ key: 'info', label: '' })
      fields.push(
          { key: 'nome', label: `${this.$tc('message.name', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'linea', label: `${this.$tc('message.line', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'tabella', label: `${this.$tc('message.table', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'activeText', label: `${this.$tc('message.enabled', 0)}`, sortable: true, sortDirection: 'desc' }
      )
      if (type === 'PERIODIC') {
        fields.push({ key: 'da', label: `${this.$tc('message.dateStart', 1)}`, sortable: true, sortDirection: 'desc' })
        fields.push({ key: 'a', label: `${this.$tc('message.dateEnd', 1)}`, sortable: true, sortDirection: 'desc' })
        fields.push({ key: 'variations', label: `${this.$tc('message.name', 1)}`, sortable: true, sortDirection: 'desc' })
      } else {
        fields.push({ key: 'data', label: `${this.$tc('message.date', 1)}`, sortable: true, sortDirection: 'desc' })
      }
      if (!fieldsToShow || fieldsToShow.length === 0) return fields
      else {
        const editedFields = []
        const fields = []
        fields.push(
            { key: 'tipo' },
            { key: 'linea' },
            { key: 'tabella' },
            { key: 'descrizione_percorso' },
            { key: 'nome_percorso' },
            { key: 'vettura' },
            { key: 'data' },
            { key: 'da' },
            { key: 'a' },
            { key: 'id_deposito' },
            { key: 'struttura' },
            { key: 'indirizzo' },
            { key: 'numero_civico' },
            { key: 'descrizione_fermata' },
            { key: 'orario_inizio' },
            { key: 'orario_fine' },
            { key: 'Nome' },
            { key: 'Cognome' },
            { key: 'codice_fiscale' },
            { key: 'stato' },
            { key: 'assistente' },
            { key: 'note' }
        )
        fields.forEach(field => {
          const foundedField = _.find(fieldsToShow, obj => obj === field.key)
          if (foundedField) {
            editedFields.push(field)
          } else if (hideOther) {
            const editedField = _.cloneDeep(field)
            editedField.hideInTable = true
            editedFields.push(editedField)
          }
        })
        return fields
      }
    },
    showRowInfo (event) {
      this.infoItem = event && event.item
      if (this.$refs.infoModal) this.$refs.infoModal.show()
    },
    pushToEdit (event) {
      const item = event && event.item
      Vue.setLocalStorage('tripType', this.tabIndex === 0 ? 'SINGLE' : 'PERIODIC')
      item && item.id
          ? this.$router.push({
            name: 'EditTrip',
            params: { id: item.id }
          })
          : this.$router.push({
            name: 'EditTrip',
            params: { id: 'new' }
          })
    },
    pushToShowInfo (event) {
      const item = event && event.item
      Vue.setLocalStorage('tripType', this.tabIndex === 1 ? 'PERIODIC' : 'SINGLE')
          this.$router.push({
            name: 'ShowTripInfo',
            params: { id: item.id }
          })
    },
    pushToShowInfoSub (event) {
      const item = event && event.item
      Vue.setLocalStorage('tripType', 'SINGLE')
      this.$router.push({
        name: 'ShowTripInfo',
        params: { id: item.id }
      })
    },
    pushToEditSub (event) {
      const item = event && event.item
      Vue.setLocalStorage('tripType', 'SINGLE')
      item && item.id
          ? this.$router.push({
            name: 'EditTrip',
            params: { id: item.id }
          })
          : this.$router.push({
            name: 'EditTrip',
            params: { id: 'new' }
          })
    },
    deleteItemSub (event) {
      const item = event && event.item
      if (item && item.id) {
        this.$root.$emit('deleteItem', {
          description: item.nome,
          path: `/bus/gita/${item.id}`,
          redirect: this.$route.path
        })
      }
    },
    deleteItem (event) {
      const item = event && event.item
      if (item && item.id) {
        this.$root.$emit('deleteItem', {
          description: item.nome,
          path: `/bus/${this.tabIndex === 1 ? 'percorso_periodico' : 'gita'}/${item.id}`,
          redirect: this.$route.path
        })
      }
    },
    resetFilters () {
      this.statusFilter = null
      this.associationFilter = null
      this.$refs.myTable.resetSearch()
    },
    getItems (status) {
      let editedItems = _.cloneDeep(this.originalItems)
      if (status !== null) editedItems = _.filter(editedItems, obj => obj.attiva === status)
      this.items = editedItems
    },
    showImportModal () {
      this.$refs.importModal.show()
      if (this.$refs.importTripFile) this.$refs.importTripFile.reset()
    },
    // import functions
    tripFileImported (file) {
      if (file && file.name) {
        const xls = _.endsWith(file.name, 'xls')
        const xlsx = _.endsWith(file.name, 'xlsx')
        const ods = _.endsWith(file.name, 'ods')
        if (xls || xlsx || ods) {
          this.enableImport = true
        } else {
          Vue.warning(this, this.$tc('warnings.onlyExcel', 1))
          this.enableImport = false
          if (this.$refs.importTripFile) this.$refs.importTripFile.reset()
        }
      }
    },
    startImport (file) {
      this.$root.$emit('activeLoader', true)
      new Promise((resolve) => {
        resolve(Vue.parseExcel(file, 1))
      }).then(async (result) => {
        const fieldsSeed = [
          'tipo',
          'linea',
          'tabella',
          'descrizione_percorso',
          'nome_percorso',
          'vettura',
          'data',
          'da',
          'a',
          'id_deposito',
          'struttura',
          'indirizzo',
          'numero_civico',
          'descrizione_fermata',
          'orario_inizio',
          'orario_fine',
          'Nome',
          'Cognome',
          'codice_fiscale',
          'stato',
          'assistente',
          'note'
        ]
        const axiosArray = []
        const templatePage = _.first(result)
        let goAhead = true
        if (_.first(templatePage) && Object.keys(_.first(templatePage)).length !== 0) {
          const allColumns = Object.keys(_.first(templatePage))
          const fields = this.getFields(fieldsSeed, true)
          let hasCurrentLang = false
          fields.forEach(field => {
            if (!hasCurrentLang) {
              hasCurrentLang = _.find(allColumns, obj => obj === field.key)
            }
          })
          goAhead = hasCurrentLang

          if (!hasCurrentLang) {
            Vue.warning(this, this.$tc('warnings.fileHasDifferentLang', 1))
          }
        } else {
          goAhead = false
          Vue.warning(this, this.$tc('warnings.fileIsEmpty', 1))
        }

        if (goAhead) {
          templatePage.forEach((item) => {
            axiosArray.push(item)
          })
          const results = []
          for (const row of axiosArray) {
            results.push(await this.checkDataToImport(row))
          }
          const errorsTripImport = []
          const itemToSaveInFiles = []
          results.forEach((result) => {
            const errors = result.errors
            const item = result.item
            const index = 2 + _.indexOf(results, result)
            const editedItem = item
            itemToSaveInFiles.push(item)
            editedItem.index = index
            if (errors.length !== 0) {
              const editedErrors = []
              errors.forEach((error) => {
                editedErrors.push(error)
              })
              errorsTripImport.push({ errors: editedErrors, item: editedItem })
            }
          })
          this.closeImportModal()
          if (errorsTripImport.length !== 0) {
            this.openImportModalError(errorsTripImport)
          } else {
            await this.makeRequests(itemToSaveInFiles).then(async (response) => {
              await this.addAssociationPersonTripStop(response.children, 'bambini').then(async () => {
                await this.addAssociationPersonTripStop(response.operators, 'operatori').then(() => {
                const errorsTripImport = []
                response.results.forEach((result) => {
                  if (!isNil(result.error) && result.error.length > 0) {
                    const item = result.item
                    errorsTripImport.push({ errors: [result.error], item })
                  }
                })
                  if (errorsTripImport.length !== 0) {
                    this.openImportModalError(errorsTripImport)
                  } else {
                    Vue.success(this, this.$t('message.importSuccess'))
                    this.$root.$emit('activeLoader', false)
                    this.$router.push({ name: 'RefreshPage', params: { name: 'Trip' } })
                  }
              }).catch((obj) => {
                  errorsTripImport.push({ errors: [this.$t('errors.registerError')], item: obj.item })
                  this.openImportModalError(errorsTripImport)
                })
              }).catch((obj) => {
                errorsTripImport.push({ errors: [this.$t('errors.registerError')], item: obj.item })
                this.openImportModalError(errorsTripImport)
              })
            }).catch((obj) => {
              errorsTripImport.push({ errors: [this.$t('errors.stopError')], item: obj.item })
              this.openImportModalError(errorsTripImport)
            })
          }
        } else {
          this.$root.$emit('activeLoader', false)
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    async makeRequests (itemToSaveInFiles) {
      const results = []
      const operators = []
      const children = []

      for (const row of itemToSaveInFiles) {
        const childToRegister = []
        const result = {
          error: '',
          item: {}
        }
        switch (row.type) {
          case 'P':
            delete row.type
            await this.addTrip(row).catch((err) => {
              result.error = err
              result.item = row
            })
            break
          case 'F':
            delete row.type
            // eslint-disable-next-line no-case-declarations
            let i = _.indexOf(itemToSaveInFiles, row) + 1
            // eslint-disable-next-line no-case-declarations
            let exit = false
            while (i < itemToSaveInFiles.length && !exit) {
              if (itemToSaveInFiles[i].type === 'R') {
                delete row.type
                childToRegister.push(itemToSaveInFiles[i])
                i = i + 1
              } else exit = true
            }
            await this.addStop(row).then(async (r) => {
              const requestBody = {
                id_fermata: r.data.id,
                orario_inizio: row.orario_inizio,
                orario_fine: row.orario_fine
              }
              if (this.tabIndex === 0) {
                requestBody.id_gita = this.newImportedTripID
              } else {
                requestBody.id_periodico = this.newImportedTripID
              }
              return await this.addTripStop(requestBody).then(async tripStop => {
                this.newImportedTripStopID = tripStop.data.id
                childToRegister.forEach(item => {
                  if (this.tabIndex === 0) {
                    item.id_gita_fermata = this.newImportedTripStopID
                  } else {
                    item.id_periodico_fermata = this.newImportedTripStopID
                  }
                  if (isNil(item.id_bambino)) {
                    operators.push(item)
                  } else {
                    children.push(item)
                  }
                })
              }).catch(errorObj => {
                result.error = this.$t('errors.stopError')
                result.item = errorObj.item
              })
            }).catch(errorObj => {
              result.error = this.$t('errors.stopError')
              result.item = errorObj.item
            })
              results.push(result)
            break
        }
      }
      return { results, children, operators }
    },
    async getDeposits () {
      await Vue.myGet(this, '/deposito', 'depositi').then((deposits) => {
        this.depositOptions = deposits
      }).catch(() => {
      })
    },
    async checkDataToImport (row) {
      const editedItem = {}
      const errors = []
      const dateFormat = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }

      const type = row[_.first(this.getFields(['tipo'])).label] || row.tipo
      const line = row[_.first(this.getFields(['linea'])).label] || row.linea
      const table = row[_.first(this.getFields(['tabella'])).label] || row.tabella
      const descriptionTrip = row[_.first(this.getFields(['descrizione_percorso'])).label] || row.descrizione_percorso
      const nameTrip = row[_.first(this.getFields(['nome_percorso'])).label] || row.nome_percorso
      const nameStop = row[_.first(this.getFields(['nome_fermata'])).label] || row.nome_fermata
      const vehicle = row[_.first(this.getFields(['vettura'])).label] || row.vettura
      const tripDate = row[_.first(this.getFields(['data'])).label] || row.data
      const tripDateFrom = row[_.first(this.getFields(['da'])).label] || row.da
      const tripDateTo = row[_.first(this.getFields(['a'])).label] || row.a
      const depositId = row[_.first(this.getFields(['id_deposito'])).label] || row.id_deposito
      const tripDateVariation = row[_.first(this.getFields(['data_variazione'])).label] || row.data_variazione
      const structure = row[_.first(this.getFields(['struttura'])).label] || row.struttura
      const address = row[_.first(this.getFields(['indirizzo'])).label] || row.indirizzo
      const civicNumber = row[_.first(this.getFields(['numero_civico'])).label] || row.numero_civico
      const descriptionStop = row[_.first(this.getFields(['descrizione_fermata'])).label] || row.descrizione_fermata
      const startTime = row[_.first(this.getFields(['orario_inizio'])).label] || row.orario_inizio
      const endTime = row[_.first(this.getFields(['orario_fine'])).label] || row.orario_fine
       const taxCode = row[_.first(this.getFields(['codice_fiscale'])).label] || row.codice_fiscale
       const status = row[_.first(this.getFields(['stato'])).label] || row.stato
       const assistant = row[_.first(this.getFields(['assistente'])).label] || row.assistente
       const notes = row[_.first(this.getFields(['note'])).label] || row.note
      if ((!type || _.trim(type).length === 0) && taxCode.length > 0) {
        errors.push(this.$t('errors.typeMissing'))
        return { errors, item: editedItem }
      } else {
        editedItem.type = type
        switch (type) {
          case 'P':
            if (isNil(line) || (!isNil(line) && line.length > 30) || _.trim(line).length === 0) {
              errors.push(this.$t('errors.line'))
            } else editedItem.linea = line.toString()

            if (isNil(table) || (!isNil(table) && table.length > 30) || _.trim(table).length === 0) {
              errors.push(this.$t('errors.table'))
            } else editedItem.tabella = table.toString()

            if (!isNil(descriptionTrip) && descriptionTrip.length > 50) {
              errors.push(this.$t('errors.descriptionTrip'))
            } else editedItem.descrizione = descriptionTrip

            if (isNil(nameTrip) || (!isNil(nameTrip) && nameTrip.length > 50) || _.trim(nameTrip).length === 0) {
              errors.push(this.$t('errors.nameTrip'))
            } else editedItem.nome = nameTrip

            if (!isNil(vehicle) && vehicle.length > 50) {
              errors.push(this.$t('errors.vehicle'))
            } else editedItem.vettura = vehicle

            if (isNil(depositId)) {
              errors.push(this.$t('errors.deposit'))
            } else {
              const deposit = _.find(this.depositOptions, item => item.id === depositId)
              if (!isNil(deposit)) {
                editedItem.id_deposito = deposit.id
              } else errors.push(this.$t('errors.depositNotExist'))
            }
            if (this.tabIndex === 0) {
              if (!isNaN(tripDate)) {
                if (!DateTime.fromFormat(new Date(Date.UTC(0, 0, tripDate - 1)).toLocaleDateString('it-IT', dateFormat), 'dd/MM/yyyy').isValid) {
                  errors.push(this.$t('errors.tripDate'))
                } else {
                  const date = DateTime.fromFormat(new Date(Date.UTC(0, 0, tripDate - 1)).toLocaleDateString('it-IT', dateFormat), 'dd/MM/yyyy')
                  if (date.toSQLDate() < DateTime.local().toSQLDate()) {
                    errors.push(`${this.$tc('errors.tripDateMinThanNow')} ${date.toLocaleString()} )`)
                  } else {
                    editedItem.data = date.toSQLDate()
                  }
                }
              } else {
                errors.push(this.$t('errors.tripDate'))
              }
            } else {
              if ((!tripDateFrom || isNaN(tripDateFrom) || !tripDateTo || isNaN(tripDateTo)) && (!tripDateVariation || isNaN(tripDateVariation))) {
                errors.push(this.$t('errors.tripDate'))
              } else if (!isNaN(tripDateFrom) && !isNaN(tripDateTo)) {
                if (!DateTime.fromFormat(new Date(Date.UTC(0, 0, tripDateFrom - 1)).toLocaleDateString('it-IT', dateFormat), 'dd/MM/yyyy').isValid ||
                    !DateTime.fromFormat(new Date(Date.UTC(0, 0, tripDateTo - 1)).toLocaleDateString('it-IT', dateFormat), 'dd/MM/yyyy').isValid) {
                  errors.push(this.$t('errors.tripDate'))
                } else {
                  const dateFrom = DateTime.fromFormat(new Date(Date.UTC(0, 0, tripDateFrom - 1)).toLocaleDateString('it-IT', dateFormat), 'dd/MM/yyyy')
                  const dateTo = DateTime.fromFormat(new Date(Date.UTC(0, 0, tripDateTo - 1)).toLocaleDateString('it-IT', dateFormat), 'dd/MM/yyyy')
                  if (dateFrom.toSQLDate() < DateTime.local().toSQLDate()) {
                    errors.push(`${this.$tc('errors.tripDateMinThanNow')} ${dateFrom.toLocaleString()} )`)
                  } else if (dateTo.toSQLDate() <= DateTime.local().toSQLDate()) {
                    errors.push(`${this.$tc('errors.tripDateMinThanNow')} ${dateFrom.toLocaleString()} )`)
                  } else if (dateFrom.toSQLDate() > dateTo.toSQLDate()) {
                    errors.push(`${this.$tc('errors.tripDateMinThanNow')} ${dateFrom.toLocaleString()} )`)
                  } else {
                    editedItem.da = dateFrom.toSQLDate()
                    editedItem.a = dateTo.toSQLDate()
                    let days = ''
                    days += !isNil(row.lun) && row.lun.toLowerCase() === 'x' ? '1' : '0'
                    days += !isNil(row.mar) && row.mar.toLowerCase() === 'x' ? '1' : '0'
                    days += !isNil(row.mer) && row.mer.toLowerCase() === 'x' ? '1' : '0'
                    days += !isNil(row.gio) && row.gio.toLowerCase() === 'x' ? '1' : '0'
                    days += !isNil(row.ven) && row.ven.toLowerCase() === 'x' ? '1' : '0'
                    days += !isNil(row.sab) && row.sab.toLowerCase() === 'x' ? '1' : '0'
                    editedItem.maschera_giorni = days
                  }
                }
              }
            }
            break
          case 'F': {
            if (isNil(address) || (!isNil(address) && address.length > 50) || _.trim(address).length === 0) {
              errors.push(this.$t('errors.address'))
            } else editedItem.indirizzo = address.toString()
            const validHHMMstring = (str) => /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(str)
            if (isNil(startTime) || (!isNil(startTime) && (startTime.length === 0 || !validHHMMstring(startTime)))) {
              errors.push(this.$t('errors.startTime'))
            } else editedItem.orario_inizio = startTime.toString()

            if (!isNil(structure) && structure.length > 50) {
              errors.push(this.$t('errors.structureStop'))
            } else if (!isNil(structure)) editedItem.struttura = structure

            if (!isNil(civicNumber) && civicNumber.length > 20) {
              errors.push(this.$t('errors.civicNumber'))
            } else if (!isNil(civicNumber)) editedItem.civico = civicNumber.toString()

            if (!isNil(descriptionStop) && descriptionStop.length > 50) {
              errors.push(this.$t('errors.descriptionStop'))
            } else if (!isNil(descriptionStop)) editedItem.descrizione = descriptionStop

            if (!isNil(endTime) && (endTime.length === 0 || !validHHMMstring(endTime))) {
              errors.push(this.$t('errors.endTime'))
            } else if (!isNil(endTime)) editedItem.orario_fine = endTime.toString()

            if (isNil(nameStop) || (!isNil(nameStop) && nameStop.length > 50) || _.trim(nameStop).length === 0) {
              errors.push(this.$t('errors.nameStop'))
            } else editedItem.nome = nameStop
          }
          break
          case 'R': {
              if (isNil(status) || (!isNil(status) && status !== 'SALITA' && status !== 'DISCESA')) {
              errors.push(this.$t('errors.status'))
            } else editedItem.salita = status === 'SALITA'

              if (!isNil(notes) && notes.length > 100) {
              errors.push(this.$t('errors.notes'))
            } else if (!isNil(notes)) editedItem.note = notes

            if (isNil(taxCode) || (!isNil(taxCode) && taxCode.length !== 16)) {
              if (!isNil(assistant) && assistant.length === 1 && !isNil(_.find(this.standardAssistants, item => item.id_letterale === assistant))) {
                editedItem.id_assistente = _.find(this.standardAssistants, item => item.id_letterale === assistant).id
              } else errors.push(this.$t('errors.assistant'))
            } else {
              await Vue.myGet(this, '/bambino?codice_fiscale=' + taxCode, 'bambini').then(children => {
                if (children.length > 0) {
                  editedItem.id_bambino = _.first(children).id
                } else errors.push(this.$t('errors.taxCodeNotExist'))
              }).catch(() => {
                errors.push(this.$t('errors.taxCodeNotExist'))
              })
            } }
            }
      }
      return { errors, item: editedItem }
    },
    async addTrip (item) {
      item.attiva = true
      if (!isNil(item.maschera_giorni)) {
        Vue.patchOrPost(false, '/bus/percorso_periodico', item).then(r => {
          this.newImportedTripID = r.data.id
          return r
        }).catch(errorObj => {
          return errorObj
        })
      } else {
        item.forza_nfc = false
        Vue.patchOrPost(false, '/bus/gita', item).then(r => {
          this.newImportedTripID = r.data.id
          return r
        }).catch(errorObj => {
          return errorObj
        })
      }
    },
    async addStop (item) {
        return await Vue.patchOrPost(false, '/bus/fermata', item).then(async r => {
          return r
            }).catch((errorObj) => {
          // eslint-disable-next-line no-throw-literal
          throw { errorObj, item }
        })
    },
    async addTripStop (requestBody) {
      return await Vue.patchOrPost(false, this.tabIndex === 0 ? '/bus/gita_fermata' : '/bus/periodico_fermata', requestBody).then(async tripStop => {
        return tripStop
      }).catch((errorObj) => {
        // eslint-disable-next-line no-throw-literal
        throw { errorObj }
      })
    },
    async addAssociationPersonTripStop (item, type) {
      let path = '/bus/' + type
      path += this.tabIndex === 0 ? '_gita_fermata' : '_periodico_fermata'

      return await Vue.patchOrPost(false, path, item).then(r => {
          return r
        }).catch((errorObj) => {
        // eslint-disable-next-line no-throw-literal
          throw { errorObj, item }
        })
    },
      closeImportModal () {
      this.$refs.importModal.hide()
      this.tripImportFile = null
    },
    openImportModalError (errors) {
      this.importErrors = errors
      this.$refs.importModalErrors.show()
    },
    errorsAreShown () {
      this.$root.$emit('activeLoader', false)
    },
    hideMainErrorMessageModal () {
      if (this.$refs.importModalErrors) this.$refs.importModalErrors.hide()
    }
  }
}
</script>
