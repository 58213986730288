const MyCheckAllSelected = {}

MyCheckAllSelected.install = Vue => {
  Vue.checkAllSelected = (items, perPage, currentPage) => {
    const operativeIndexStart = (perPage * currentPage) - perPage
    const operativeIndexEnd = perPage * currentPage
    let selectedCounter = 0
    let itemsChecked = 0
    items.forEach((item, index) => {
      if (index >= operativeIndexStart && index < operativeIndexEnd) {
        itemsChecked += 1
        if (item.selected) {
          selectedCounter += 1
        }
      }
    })
    return selectedCounter === itemsChecked && itemsChecked !== 0
  }
}

export default MyCheckAllSelected
