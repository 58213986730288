<template>
    <b-container>
    </b-container>
</template>

<script>
  export default {
    name: 'RefreshPage',
    beforeCreate () {
      const params = this.$route.params
      const query = this.$route.query
      if (params.redirect) this.$router.push(params.redirect).catch(() => {})
      else this.$router.push({ name: this.$route.params.name, params: { id: this.$route.params.id }, query: query || {} }).catch(() => {})
    }
  }
</script>

<style scoped>

</style>
