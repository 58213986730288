import * as XLSX from 'xlsx'
import _ from 'lodash'

const MyParseExcel = {
  install (Vue) {
    Vue.parseExcel = (file, rRange) => new Promise((resolve) => {
      const dataToReturn = []
      const reader = new FileReader()

      reader.onload = (e) => {
        const data = e.target.result
        const workbook = XLSX.read(data, {
          type: 'binary'
        })
        workbook.SheetNames.forEach((sheetName) => {
          const XLRowObject = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], !_.isNil(rRange) ? { range: rRange } : {})
          const jsonObject = JSON.stringify(XLRowObject)
          dataToReturn.push(JSON.parse(jsonObject))
        })
        return resolve(dataToReturn)
      }

      reader.onerror = () => resolve(dataToReturn)

      reader.readAsBinaryString(file)
    })
  }
}

export default MyParseExcel
