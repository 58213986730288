<template>
    <b-container fluid>
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <header class="modal-header">
                    <h5 class="my-modal-title">{{$tc('message.recipient', 1)}}</h5>
                </header>

                <div class="modal-body">
                    <ValidationObserver ref="form" v-slot="{ invalid, pending }">
                        <b-row>
                            <b-col cols="12" sm="12" md="12" lg="9" xl="9">
                                <!-- Description -->
                                <ValidationProvider :rules="{ max: 50 }" :name="`${$tc('message.email', 1)}`" v-slot="{ errors }">
                                    <b-form-group :label="`${$tc('message.email', 1)}`">
                                        <b-form-input id="email"
                                                      type="email"
                                                      v-model="input.email"
                                                      :state="lodash.first(errors) && itemChanged ? false : null">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>

                        <b-row class="my-border-top" align-h="end">
                            <b-col cols="12" sm="12" md="12" lg="auto" xl="auto">
                                <b-button id="save"
                                          type="button"
                                          class="float-right mt-2 b-c-green"
                                          :disabled="invalid || pending || !itemChanged"
                                          @click="save(false)"> {{$tc('message.save', 1)}}
                                </b-button>
                            </b-col>

                            <b-col cols="12" sm="12" md="12" lg="auto" xl="auto">
                                <b-button id="save-and-exit"
                                          type="button"
                                          class="float-right mt-2 b-c-green"
                                          :disabled="invalid || pending || !itemChanged"
                                          @click="save(true)"> {{$tc('message.saveClose', 1)}}
                                </b-button>
                            </b-col>

                            <b-col cols="12" sm="12" md="12" lg="auto" xl="auto">
                                <b-button id="cancel-button"
                                          type="button"
                                          class="float-right mt-2"
                                          @click="exitCheck(itemChanged)"> {{$tc('message.close', 1)}}
                                </b-button>
                            </b-col>
                        </b-row>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </b-container>
</template>

<script>
  import Vue from 'vue'
  import _ from 'lodash'

  export default {
    name: 'EditReportRecipient',
    created () {
      this.fetchData().then(() => {
        this.addWatcher()
      })
    },
    mounted () {
      this.$root.$on('change-lang', () => {
        this.$nextTick(() => {
          if (this && this.$refs && this.$refs.form) this.$refs.form.validate()
        })
      })
    },
    data () {
      const reportRecipientId = this.$route.params.id
      const lodash = _
      const backPath = '/registry/report_recipient'
      return {
        backPath,
        reportRecipientId,
        lodash,
        input: {},
        watcher: null,
        itemChanged: false
      }
    },
    methods: {
      resetWatcher () {
        this.$root.$emit('activeLoader', true)
        if (this.watcher) this.watcher()
        this.itemChanged = false
      },
      addWatcher () {
        this.$root.$emit('activeLoader', false)
        this.watcher = this.$watch('input', () => {
          this.itemChanged = true
        }, { deep: true })
      },
      fetchData () {
        this.resetWatcher()
        const id = this.reportRecipientId
        if (id !== 'new') {
          return Vue.myGet(this, `/destinatario_segnalazione/${id}`).then(result => {
            this.input = result
            return true
          }).catch(error => {
            Vue.manageErrors(this, error)
            return true
          })
        }
        return new Promise(resolve => resolve())
      },
      exitCheck (itemChanged) {
        if (itemChanged) this.$root.$emit('exit-check', { redirect: this.backPath })
        else this.$router.push(this.backPath)
      },
      save (pushBack) {
        Vue.patchOrPost(this.reportRecipientId !== 'new',
        `/destinatario_segnalazione${this.reportRecipientId !== 'new' ? '/' + this.reportRecipientId : ''}`, this.input).then(r => {
          Vue.success(this, `${this.$tc('message.recipient', 1)} ${this.$t(this.reportRecipientId !== 'new' ? 'success.putSuccess' : 'success.postSuccess')}`)
          if (pushBack) this.$router.push(this.backPath)
          else this.$router.push({ name: 'RefreshPage', params: { id: this.input.id || r.data.id, name: 'EditReportRecipient' } })
        }).catch(errorObj => {
          console.log(errorObj)
          Vue.manageErrors(this, errorObj)
        })
      }
    }
  }
</script>
