<template>
  <b-container class="mw-100 pt-2 pb-1">
    <b-row align-v="center">
      <b-col cols="auto" class="d-flex flex-row align-items-start">
        <h4 class="d-inline-flex bbbus-icon"/>
        <h3 class="d-inline-flex mb-1 c-white"> bim </h3>
        <h3 class="d-inline-flex mb-1 c-yellow"> bum </h3>
        <h3 class="d-inline-flex mb-1 c-white"> bus </h3>
      </b-col>

      <b-col>
        <b-button class="float-right b-c-green" @click="showInfoModal" size="sm" v-if="logged">
          <i class="fas fa-user"/>
        </b-button>
      </b-col>
    </b-row>

    <b-modal ref="infoModal" id="infoModal" no-close-on-backdrop no-close-on-esc hide-header-close>
      <b-container>
        <b-row>
          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <div class="d-flex">
              <h6><b>{{$tc('message.username', 1)}}</b></h6>
              <h6 class="ml-2">{{info.username || $tc('message.emptyValue')}}</h6>
            </div>
          </b-col>

          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <div class="d-flex">
              <h6><b>{{$tc('message.role', 1)}}</b></h6>
              <h6 class="ml-2">{{info.role || $tc('message.emptyValue')}}</h6>
            </div>
          </b-col>
        </b-row>
      </b-container>

      <div slot="modal-footer" class="w-100 justify-content-end d-flex">
        <b-button class="b-c-green" @click="$refs.infoModal.hide()"> Ok
        </b-button>
      </div>
    </b-modal>
  </b-container>
</template>

<script>
  import Vue from 'vue'

  export default {
    name: 'MyHeader',
    watch: {
      '$route' () {
        this.checkIfLogged()
      }
    },
    mounted () {
      this.checkIfLogged()
    },
    data () {
      return {
        logged: false,
        info: {
          username: null,
          role: null
        }
      }
    },
    methods: {
      showInfoModal () {
        const info = Vue.getLocalStorage('session')
        this.info.username = info && info.utente && info.utente.username
        this.info.role = info && info.utente && info.utente.ruolo && info.utente.ruolo.descrizione
        this.$refs.infoModal.show()
      },
      checkIfLogged () {
        const session = Vue.getLocalStorage('session')
        this.logged = !!(session && session.token)
      }
    }
  }
</script>
