<template>
  <b-container>
    <b-card class="mt-4 mb-4">
      <standard-table ref="table"
                      :items="items"
                      :fields="fields"
                      :row-before-search="{cols: 12,sm: 12,md: 6,lg: 4,xl: 4,order: 1,orderSm: 1,orderMd: 1,orderLg: 3,orderXl: 3}"
                      :row-search="{cols: 12,sm: 12,md: 6,lg: 4,xl: 4,order: 1,orderSm: 2,orderMd: 2,orderLg: 1,orderXl: 1}"
                      :row-after-search="{cols: 12, sm: 12, md: 6, lg: 4, xl: 4, order: 2, orderSm: 3, orderMd: 3, orderLg: 2, orderXl: 2}"
                      :row-pagination="{cols: 12, sm: 12, md: 6, lg: 5, xl: 5}"
                      :row-between-pagination="{cols: 12, sm: 6, md: 1, lg: 1, xl: 4}"
                      :row-per-page="{cols: 12, sm: 12, md: 5, lg: 3, xl: 2}"
                      :row-after-per-page="{cols: 12, sm: 12, md: 6, lg: 3, xl: 1}"

                      :sub-table-fields="subFields"
                      :sub-tr-class="'sub-table-reports'"
                      fullWidth="true"
                      noContainerPadding="true">
     <!--   <b-container slot="row-after-per-page" class="mw-100 p-0">
          <b-row>
            <b-col>
              <b-button class="float-right mt-3 mt-md-0 mt-lg-0 mt-xl-0" id="excel-button"
                        @click="$refs.table.excel($tc('message.trip', 1), customFields, itemsForExcel)">Excel
              </b-button>
            </b-col>
          </b-row>
        </b-container> -->

        <b-container slot="after-search" class="mw-100 p-0">
          <b-collapse id="my-collapse" v-model="showCollapse">
            <b-container class="mw-100 m-0 p-0">
              <b-row>
                <b-col cols="12" sm="12" md="6" lg="6" xl="3">
                  <b-form-group :label="`${$tc('message.from', 1).toLowerCase()}`">
                    <b-input-group>
                      <flat-pickr id="start"
                                  ref="start"
                                  @input="fetchData(null, startFilter, endFilter)"
                                  v-model="startFilter"
                                  :config="startConfig">
                      </flat-pickr>

                      <div class="input-group-append">
                        <b-button class="fas fa-trash"
                                  @click="startFilter = null">
                        </b-button>
                      </div>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col cols="12" sm="12" md="6" lg="6" xl="3">
                  <b-form-group :label="`${$tc('message.to', 1).toLowerCase()}`">
                    <b-input-group>
                      <flat-pickr id="end"
                                  ref="end"
                                  @input="fetchData(null, startFilter, endFilter)"
                                  v-model="endFilter"
                                  :config="endConfig">
                      </flat-pickr>

                      <div class="input-group-append">
                        <b-button class="fas fa-trash"
                                  @click="endFilter = null">
                        </b-button>
                      </div>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col cols="12" sm="12" md="6" lg="6" xl="3">
                  <b-form-group :label="`${$tc('message.trip', 1)}`">
                    <v-select :options="tripOptions"
                              label="nome"
                              @input="tripFilter === null ? initDates() : fetchData(tripFilter, null, null)"
                              :placeholder="`${$tc('message.selectOption', 1)}`"
                              v-model="tripFilter">
                    </v-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-container>
          </b-collapse>
        </b-container>

        <b-container slot="row-after-search" class="mw-100 p-0">
          <b-row>
            <b-col>
              <b-button @click="showCollapse = !showCollapse"
                        :class="showCollapse ? 'b-c-red mb-2 mr-2' : 'b-c-green mb-2 mr-2'" id="show-filters">
                <span v-show="!showCollapse"
                      class="when-opened">{{ $t('message.show') }} {{ $tc('message.filter', 2).toLowerCase() }}</span>
                <span v-show="showCollapse"
                      class="when-closed">{{ $t('message.hide') }} {{ $tc('message.filter', 2).toLowerCase() }}</span>
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </standard-table>
    </b-card>
  </b-container>
</template>

<script>
import Vue from 'vue'
import { isNil } from 'lodash'
import { DateTime } from 'luxon'
import { Italian } from 'flatpickr/dist/l10n/it'

export default {
  name: 'TripAssignments',
  created () {
    this.fetchTripsName()
  },
  data () {
    const startFilter = DateTime.fromISO(new Date(new Date().setDate(new Date().getDate() - 7)).toISOString()).toISODate()
    const endFilter = `${DateTime.local().toISODate()}`

    return {
      startFilter,
      endFilter,
      firstDate: null,
      lastDate: null,
      yearNumber: null,
      items: [],
      tripOptions: [],
      tripFilter: null,
      fields: [
        {
          key: 'data',
          label: `${this.$tc('message.date', 1)}`,
          sortable: true,
          sortDirection: 'desc'
        },
        { key: 'gita', label: `${this.$tc('message.trip', 1)}`, sortable: true, sortDirection: 'desc' },
        { key: 'assignments', label: '', sortable: true, sortDirection: 'desc' }
      ],
      subFields: [
        { key: 'operatore', label: `${this.$tc('message.operator', 1)}`, sortable: true, sortDirection: 'desc' }
      ],
      customFields: [],
      itemsForExcel: [],
      datesString: [],
      showCollapse: true,
      startConfig: {
        dateFormat: 'Y-m-d',
        mode: 'single',
        altFormat: 'D j M Y',
        altInput: true,
        locale: Italian,
        minDate: null,
        maxDate: endFilter,
        disable: []
      },
      endConfig: {
        dateFormat: 'Y-m-d',
        mode: 'single',
        altFormat: 'D j M Y',
        altInput: true,
        locale: Italian,
        minDate: startFilter,
        maxDate: null,
        disable: []
      },
      choiceOk: false,
      childrenOfThisYear: [],
      tripStops: []
    }
  },
  methods: {
    fetchTripsName () {
      this.$root.$emit('activeLoader', true)
      Vue.myGet(this, '/bus/gita', 'gite').then((trips) => {
        trips.forEach(trip => {
          this.tripOptions.push({ data: trip.data, nome: trip.nome, id: trip.id })
        })
        Vue.myGet(this, '/bambino', 'bambini').then(async (children) => {
          children.forEach((child) => {
            this.childrenOfThisYear.push(child)
          })
          await this.fetchData()
          this.$root.$emit('activeLoader', false)
        }).catch(error => {
          Vue.manageErrors(this, error)
        })
      }).catch(error => {
        Vue.manageErrors(this, error)
      })
    },
    async fetchData (tripFilter, startFilter, endFilter) {
      if (!isNil(tripFilter)) {
        this.startFilter = null
        this.endFilter = null
      } else if (!isNil(startFilter) || !isNil(endFilter)) {
        this.tripFilter = null
      }
      this.$root.$emit('activeLoader', true)
      let url = '/bus/assegnamento_percorso?'
      if (!isNil(this.startFilter)) {
        url += 'da=' + this.startFilter + '&'
      }
      if (!isNil(this.endFilter)) {
        url += 'a=' + this.endFilter + '&'
      }
      if (!isNil(this.tripFilter)) {
        url += 'gita=' + this.tripFilter.id
      }

        await Vue.myGet(this, url, 'assegnamenti').then((assignments) => {
          this.items = []
          assignments.forEach((assignment) => {
              const newItem = {
                id_gita: assignment.id_gita,
                gita: assignment.gita.nome,
                data: assignment.gita.data,
                children: []
              }
              assignment.operatori.forEach((operator) => {
                newItem.children.push({
                  operatore: operator.nome + ' ' + operator.cognome
                })
              })
              this.items.push(newItem)
          })
          this.items = this.sortFormattedDate(this.items)
          this.$root.$emit('activeLoader', false)
        }).catch(error => {
          Vue.manageErrors(this, error)
        })
    },
    formatDate (date) {
      const day = new Date(date).getDate().toString().length === 1 ? '0' + new Date(date).getDate().toString() : new Date(date).getDate().toString()
      const month = (new Date(date).getMonth() + 1).toString().length === 1 ? '0' + (new Date(date).getMonth() + 1).toString() : (new Date(date).getMonth() + 1).toString()
      const year = new Date(date).getFullYear()
      return day + '/' +
          month + '/' +
          year
    },
    sortFormattedDate (items) {
      return items.sort(function (firstDate, secondDate) {
        const firstDateArray = firstDate.data.split('/')
        const convertFirstDateArray = new Date(firstDateArray[2] + '/' + firstDateArray[1] + '/' + firstDateArray[0])
        const secondDateArray = secondDate.data.split('/')
        const convertSecondDateArray = new Date(secondDateArray[2] + '/' + secondDateArray[1] + '/' + secondDateArray[0])
        return new Date(convertFirstDateArray) - new Date(convertSecondDateArray)
      })
    },
    initDates () {
      this.startFilter = DateTime.fromISO(new Date(new Date().setDate(new Date().getDate() - 7)).toISOString()).toISODate()
      this.endFilter = `${DateTime.local().toISODate()}`
    },
    getDatesInRange (startDate, endDate) {
      const date = new Date(startDate.getTime())

      const dates = []

      // eslint-disable-next-line no-unmodified-loop-condition
      while (date <= endDate) {
        dates.push(new Date(date))
        date.setDate(date.getDate() + 1)
      }

      return dates
    }
  },
  watch: {
    startFilter () {
        this.endConfig.minDate = this.startFilter
    },
    endFilter () {
        this.startConfig.maxDate = this.endFilter
    }
  }
}
</script>

<style scoped>

</style>
