<template>
  <b-dropdown id="lang-dropdown" dropup no-caret variant="link" class="show">
    <template slot="button-content">
      <flag iso='en' v-if="currentLang !== 'it'"/>
      <flag iso='it' v-if="currentLang !== 'en'"/>
    </template>

    <b-dropdown-item v-on:click="changeLang('it')" v-if="currentLang !== 'it'">
      <flag iso='it'/>
    </b-dropdown-item>

    <b-dropdown-item v-on:click="changeLang('en')" v-if="currentLang !== 'en'">
      <flag iso='en'/>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import Vue from 'vue'
import { localize } from 'vee-validate'

export default {
  name: 'changeLanguage',
  data () {
    const sessionLang = Vue.getLocalStorage('lang')
    return {
      currentLang: sessionLang || this.$i18n.locale
    }
  },
  mounted () {
    const browserLang = navigator.language === 'it-IT' ? 'it' : 'en'
    this.changeLang(Vue.getLocalStorage('lang') || browserLang || this.$i18n.locale)
  },
  methods: {
    changeLang (locale) {
      this.$i18n.locale = locale
      this.currentLang = locale
      this.$root.$emit('change-lang', locale)
      localize(locale)
      Vue.setLocalStorage('lang', locale)
    }
  }
}
</script>

<style scoped>
  #lang-dropdown {
    margin-right: 30px !important;
    height: 20px !important;
    width: 20px !important;
  }

  #lang-dropdown > button {
    height: 20px !important;
    width: 20px !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .flag {
    display: block;
    z-index: 9999;
  }
</style>
