const MyAuthorizationMaskIndexes = {
    CRUD (section, operation) {
        switch (section) {
            case 'CHILD': {
                switch (operation) {
                    case 'CREATE':
                        return 0
                    case 'READ':
                        return 1
                    case 'UPDATE':
                        return 2
                    case 'DELETE':
                        return 3
                }
                return
            }

            case 'TRIP':{
                switch (operation) {
                    case 'CREATE':
                        return 4
                    case 'READ':
                        return 5
                    case 'UPDATE':
                        return 6
                    case 'DELETE':
                        return 7
                }
                return
            }

            case 'STOP':{
                switch (operation) {
                    case 'CREATE':
                        return 8
                    case 'READ':
                        return 9
                    case 'UPDATE':
                        return 10
                    case 'DELETE':
                        return 11
                }
                return
            }

            case 'ASSOCIATION_CHILD_TRIP':{
                switch (operation) {
                    case 'CREATE':
                        return 12
                    case 'READ':
                        return 13
                    case 'UPDATE':
                        return 14
                    case 'DELETE':
                        return 15
                }
                return
            }

            case 'ASSOCIATION_OPERATOR_TRIP':{
                switch (operation) {
                    case 'CREATE':
                        return 16
                    case 'READ':
                        return 17
                    case 'UPDATE':
                        return 18
                    case 'DELETE':
                        return 19
                }
                return
            }

            case 'ATTRIBUTE':{
                switch (operation) {
                    case 'CREATE':
                        return 20
                    case 'READ':
                        return 21
                    case 'UPDATE':
                        return 22
                    case 'DELETE':
                        return 23
                }
                return
            }

            case 'DEPOSIT':{
                switch (operation) {
                    case 'CREATE':
                        return 24
                    case 'READ':
                        return 25
                    case 'UPDATE':
                        return 26
                    case 'DELETE':
                        return 27
                }
            }
        }
    },
    pathNameForTranslate (pathName) {
        switch (pathName) {
            case 'child':
                return 'CHILD'
            case 'trip':
                return 'TRIP'
            case 'stop':
                return 'STOP'
            case 'attribute':
                return 'ATTRIBUTE'
            case 'deposit':
                return 'DEPOSIT'
        }
    }
}
export default MyAuthorizationMaskIndexes
