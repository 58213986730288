<template>
    <b-container fluid>
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <header class="modal-header">
                    <h5 class="my-modal-title">{{$tc('message.child', 1)}}</h5>
                </header>

                <div class="modal-body">
                    <ValidationObserver ref="form" v-slot="{ invalid, pending }">
                        <b-row>
                            <b-col class="mt-1" cols="12" sm="12" md="12" lg="6" xl="6">
                                <!-- Name -->
                                <ValidationProvider :rules="{ required: true, max: 50 }" :name="`${$tc('message.name', 1)} (*)`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.name', 1)} (*)`">
                                        <b-form-input id="name"
                                                      autocomplete="off"
                                                      type="text"
                                                      v-model="child.nome"
                                                      :state="lodash.first(errors) && itemChanged ? false : (valid ? (itemChanged ? true : null) : null)">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>

                                <!-- Surname -->
                                <ValidationProvider :rules="{ required: true, max: 50 }" :name="`${$tc('message.surname', 1)} (*)`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.surname', 1)} (*)`">
                                        <b-form-input id="surname"
                                                      type="text"
                                                      autocomplete="off"
                                                      v-model="child.cognome"
                                                      :state="lodash.first(errors) && itemChanged ? false : (valid ? (itemChanged ? true : null) : null)">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>

                                <!-- Fiscal code -->
                                <ValidationProvider :rules="{ taxCode: true, required: true }" :name="`${$tc('message.taxCode', 1)}`"
                                                    ref="validationTaxCode"
                                                    v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.taxCode', 1)} (*)`">
                                        <b-form-input id="child-fiscal-code"
                                                      v-model="child.codice_fiscale"
                                                      :state="lodash.first(errors) && itemChanged ? false : (valid ? (itemChanged ? true : null) : null)">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>

                            <b-col class="mt-1" cols="12" sm="12" md="12" lg="6" xl="6">
                                <!-- Birth date -->
                                <ValidationProvider :rules="{ required: true }" :name="`${$tc('message.birthDate', 1)} (*)`"
                                                    v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.birthDate', 1)} (*)`">
                                        <b-input-group>
                                            <flat-pickr id="birth-date"
                                                        ref="birthDate"
                                                        :class="getFlatPickrClass('birthDate', valid, errors, itemChanged, true)"
                                                        v-model="child.data_nascita"
                                                        :config="birthDateConfig">
                                            </flat-pickr>

                                            <div class="input-group-append">
                                                <b-button class="fas fa-trash"
                                                          @click="child.data_nascita = null"
                                                          :disabled="!child.data_nascita">
                                                </b-button>
                                            </div>

                                            <b-form-invalid-feedback id="inputLiveFeedback">
                                                {{ lodash.first(errors) }}
                                            </b-form-invalid-feedback>
                                        </b-input-group>
                                    </b-form-group>
                                </ValidationProvider>

                                <!-- City -->
                                <ValidationProvider :rules="{ max: 30, required: true }" :name="`${$tc('message.commonResidence', 1)}`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.commonResidence', 1)} (*)`">
                                        <b-form-input id="city"
                                                      v-model="child.comune_residenza"
                                                      :state="lodash.first(errors) && itemChanged ? false : (valid ? (itemChanged ? true : null) : null)">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>

                                <!-- Country -->
                                <ValidationProvider :rules="{ max: 30, required: true }" :name="`${$tc('message.provinceResidence', 1)}`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.provinceResidence', 1)} (*)`">
                                        <b-form-input id="province"
                                                      v-model="child.provincia_residenza"
                                                      :state="lodash.first(errors) && itemChanged ? false : (valid ? (itemChanged ? true : null) : null)">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>

                            <b-col cols="12" sm="8" md="9" lg="12" xl="12">
                                <!-- Address -->
                                <ValidationProvider :rules="{ max: 50, required: true }" :name="`${$tc('message.address', 1)}`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.address', 1)} (*)`">
                                        <b-form-input id="address"
                                                      type="text"
                                                      v-model="child.indirizzo_residenza"
                                                      :state="lodash.first(errors) && itemChanged ? false : (valid ? (itemChanged ? true : null) : null)">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>

                        <b-form-row class="border m-2"/>

                        <b-row>
                            <b-col class="mt-1" cols="12" sm="12" md="12" lg="6" xl="6">
                                <!-- Parent 1 -->
                                <ValidationProvider :rules="{ required: true, max: 50 }" :name="`${$tc('message.parent1', 1)}`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.parent1', 1)} (*)`">
                                        <b-form-input id="parent1"
                                                      type="text"
                                                      autocomplete="off"
                                                      v-model="child.genitore_1"
                                                      :state="lodash.first(errors) && itemChanged ? false : (valid ? (itemChanged ? true : null) : null)">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>

                                <!-- Fiscal code 1 -->
                                <ValidationProvider :rules="{ taxCode: true, required: false }" :name="`${$tc('message.firstTaxCode', 1)}`" v-slot="{ errors }">
                                    <b-form-group :label="`${$tc('message.firstTaxCode', 1)}`">
                                        <b-form-input id="first-parent-fiscal-code"
                                                      v-model="child.codice_fiscale_r1"
                                                      :state="lodash.first(errors) ? false : null">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>

                                <!-- Phone 1 -->
                                <ValidationProvider :rules="{ min: 0, max: 20, required: true }" :name="`${$tc('message.phone', 1)} 1 (*)`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.phone', 1)} 1 (*)`">
                                        <b-form-input id="phone1"
                                                      type="number"
                                                      min="0"
                                                      max="20"
                                                      v-model="child.telefono_principale"
                                                      :state="lodash.first(errors) && itemChanged ? false : (valid ? (itemChanged ? true : null) : null)">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>

                                <!-- Email 1 -->
                                <ValidationProvider :rules="{ max: 50, email: true }" :name="`${$tc('message.email', 1)} 1`" v-slot="{ errors }">
                                    <b-form-group :label="`${$tc('message.email', 1)} 1`">
                                        <b-form-input id="email"
                                                      v-model="child.email_ufficiale"
                                                      :state="lodash.first(errors) ? false : null">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>

                            <b-col class="mt-1" cols="12" sm="12" md="12" lg="6" xl="6">
                                <!-- Parent 2 -->
                                <ValidationProvider :rules="{ max: 50 }" :name="`${$tc('message.parent2', 1)}`" v-slot="{ errors }">
                                    <b-form-group :label="`${$tc('message.parent2', 1)}`">
                                        <b-form-input id="parent1"
                                                      type="text"
                                                      v-model="child.genitore_2"
                                                      :state="lodash.first(errors) ? false : null">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>

                                <!-- Fiscal code 2 -->
                                <ValidationProvider :rules="{ taxCode: true, required: false }" :name="`${$tc('message.otherTaxCode', 1)}`" v-slot="{ errors }">
                                    <b-form-group :label="`${$tc('message.otherTaxCode', 1)}`">
                                        <b-form-input id="second-parent-fiscal-code"
                                                      v-model="child.codice_fiscale_r2"
                                                      :state="lodash.first(errors) ? false : null">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>

                                <!-- Phone 2 -->
                                <ValidationProvider :rules="{ min: 0, max: 20, required: false }" :name="`${$tc('message.phone', 1)} 2`" v-slot="{ errors }">
                                    <b-form-group :label="`${$tc('message.phone', 1)} 2`">
                                        <b-form-input id="phone2"
                                                      type="number"
                                                      min="0"
                                                      max="12"
                                                      v-model="child.telefono_secondario"
                                                      :state="lodash.first(errors) ? false : null">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>

                                <!-- Email 2 -->
                                <ValidationProvider :rules="{ max: 50, email: true, required: false }" :name="`${$tc('message.email', 1)} 2`" v-slot="{ errors }">
                                    <b-form-group :label="`${$tc('message.email', 1)} 2`">
                                        <b-form-input id="email2"
                                                      v-model="child.email_secondaria"
                                                      :state="lodash.first(errors) ? false : null">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>

                        <b-form-row class="border m-2"/>

                        <b-row>
                            <b-col class="mt-1" cols="12" sm="12" md="12" lg="6" xl="6">
                                <!-- Date start -->
                                <ValidationProvider :rules="child.data_cancellazione != null ? { required: true, dateMax:  [child.data_cancellazione]} : {required: true}" :name="`${$tc('message.dateStart', 1)}`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.dateStart', 1)} (*)`">
                                        <b-input-group>
                                            <flat-pickr id="start-date"
                                                        ref="startDate"
                                                        :class="getFlatPickrClass('startDate', valid, errors, itemChanged, true)"
                                                        v-model="child.data_iscrizione"
                                                        :config="startDateConfig">
                                            </flat-pickr>

                                            <div class="input-group-append">
                                                <b-button class="fas fa-trash"
                                                          @click="child.data_iscrizione = null"
                                                          :disabled="!child.data_iscrizione">
                                                </b-button>
                                            </div>

                                            <b-form-invalid-feedback id="inputLiveFeedback">
                                                {{ lodash.first(errors) }}
                                            </b-form-invalid-feedback>
                                        </b-input-group>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>

                            <b-col class="mt-1" cols="12" sm="12" md="12" lg="6" xl="6">
                                <!-- Discharge date -->
                                <ValidationProvider :rules="child.data_iscrizione != null ? { required: true, dateMin: [child.data_iscrizione] } : {required: true}"  :name="`${$tc('message.discharge', 1)}`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.discharge', 1)} (*)`">
                                        <b-input-group>
                                            <flat-pickr id="end-date"
                                                        ref="endDate"
                                                        :class="getFlatPickrClass('endDate', valid, errors, itemChanged, true)"
                                                        v-model="child.data_cancellazione"
                                                        :config="endDateConfig">
                                            </flat-pickr>

                                            <div class="input-group-append">
                                                <b-button class="fas fa-trash"
                                                          @click="child.data_cancellazione = null"
                                                          :disabled="!child.data_cancellazione">
                                                </b-button>
                                            </div>

                                            <b-form-invalid-feedback id="inputLiveFeedback">
                                                {{ lodash.first(errors) }}
                                            </b-form-invalid-feedback>
                                        </b-input-group>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                          <b-col class="mt-1" cols="12" sm="12" md="12" lg="3" xl="3">
                            <b-form-group :label="`${$tc('message.specialEquipment', 1)}`">
                              <b-form-checkbox @click.native.stop v-model="child.dotazioni_speciali">
                              </b-form-checkbox>
                            </b-form-group>
                          </b-col>
                          <b-col class="mt-1" cols="12" sm="12" md="12" lg="3" xl="3">
                            <b-form-group :label="`${$tc('message.accompanied', 1)}`">
                              <b-form-checkbox @click.native.stop v-model="child.accompagnato">
                              </b-form-checkbox>
                            </b-form-group>
                          </b-col>
                            <b-col class="mt-1" cols="12" sm="12" md="12" lg="6" xl="6">
                                <!-- Structure -->
                                <ValidationProvider :rules="{ required: true }" :name="`${$tc('message.structure', 1)} (*)`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.structure', 1)} (*)`">
                                        <v-select :options="structureOptions"
                                                  :reduce="obj => obj.id"
                                                  id="structure"
                                                  ref="structure"
                                                  label="den_sintetica"
                                                  :class="checkSelectStyle('structure', itemChanged, valid, errors, true)"
                                                  v-model="child.id_struttura">
                                        </v-select>

                                        <div id="inputLiveFeedback" v-if="errors.length !== 0" class="my-invalid-feedback">
                                            {{ lodash.first(errors) }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                          <b-col class="mt-1" cols="12" sm="12" md="12" lg="3" xl="3">
                            <!-- Attributes -->
                            <b-form-group :label="`${$tc('message.select', 1)} ${$tc('message.attribute', 2).toLowerCase()}`">

                              <b-button @click="$refs.attributesModal.show()"
                                        :disabled="!child.id"
                                        class="mt-3 mt-md-0 mt-lg-0 mt-xl-0 b-c-green"
                                        id="attribute-button">{{$tc('message.attribute', 2)}}
                              </b-button>
                            </b-form-group>
                          </b-col>
                        </b-row>

                        <b-row class="my-border-top mt-3" align-h="end">
                          <b-col cols="12" sm="12" md="12" lg="3" xl="3">
                            <b-button id="open-enabled-person"
                                      type="button"
                                      class="float-left mt-2 b-c-green"
                                      :disabled="!child.id"
                                      v-b-modal.enablePersonModal
                            > {{$tc('message.enabledPerson', 2)}}
                            </b-button>
                          </b-col>

                          <b-col cols="12" sm="12" md="12" lg="9" xl="9">
                                <b-button id="cancel-button"
                                          type="button"
                                          class="float-right ml-3 mt-2"
                                          @click="exitCheck(itemChanged)"> {{$tc('message.close', 1)}}
                                </b-button>

                                <b-button id="save-and-exit"
                                          type="button"
                                          class="float-right ml-3 mt-2 b-c-green"
                                          :disabled="invalid || pending || !itemChanged  || child.data_cancellazione < child.data_iscrizione"
                                          @click="save(true)"> {{$tc('message.saveClose', 1)}}
                                </b-button>

                                <b-button id="save"
                                          type="button"
                                          class="float-right ml-3 mt-2 b-c-green"
                                          :disabled="invalid || pending || !itemChanged || child.data_cancellazione < child.data_iscrizione"
                                          @click="save(false)"> {{$tc('message.save', 1)}}
                                </b-button>
                            </b-col>
                        </b-row>
                    </ValidationObserver>
                </div>
            </div>
        </div>

      <b-modal ref="attributesModal" id="attributes-modal"
               :title="`${$tc('message.attribute', 2)} ${$tc('message.of', 1).toLowerCase() } ${$tc('message.child', 1).toLowerCase()}`" size="lg"
               no-close-on-backdrop no-close-on-esc hide-header-close>
        <standard-table ref="attributesTable"
                        :theadTrClass="'first-table-cols'"
                        :items="attributes"
                        :fields="attributesFields"
                        :disableAutoUnSelection="true"

                        :before-search="{cols: 12,sm: 12,md: 12,lg: 6,xl: 6}"

                        :row-search="{cols: 12,sm: 12,md: 6,lg: 6,xl: 6}"
                        :row-after-search="{cols: 12, sm: 12, md: 12, lg: 6, xl: 6}"

                        :row-pagination="{cols: 12, sm: 12, md: 6, lg: 5, xl: 5}"
                        :row-between-pagination="{cols: 12, sm: 12, md: 1, lg: 4, xl: 4}"
                        :row-per-page="{cols: 12, sm: 12, md: 5, lg: 3, xl: 3}"
                        @rowSelected="handleAttributeRowSelected"
                        @allSelected="handleAttributeAllSelected"

                        fullWidth="true"
                        noContainerPadding="true"
        >
        </standard-table>

        <div slot="modal-footer" class="w-100 justify-content-end d-flex">
          <b-button type="button"
                    class="m-1 b-c-green"
                    :disabled="!attributesChanged"
                    @click="hideAttributesModal(); itemChanged = true"> {{ $tc('message.choose', 1) }}
          </b-button>

          <b-button type="button"
                    class="m-1"
                    @click="hideAttributesModal()">
            {{ $tc('message.close', 1) }}
          </b-button>
        </div>
      </b-modal>

      <!-- Modal enabled person list -->
      <b-modal id="enablePersonModal"
               ref="enablePersonModal"  no-close-on-backdrop no-close-on-esc hide-header-close
               :title="`${$tc('message.handlingEnabledPerson', 1)}`" size="lg">
        <b-container fluid>
          <b-row>
            <standard-table ref="enablePersonTable"
                            :theadTrClass="'first-3-table-cols'"
                            :items="enabledPersonItems"
                            :fields="enabledPersonsFields"
                            :disableAutoUnSelection="true"

                            :before-search="{cols: 12,sm: 12,md: 6,lg: 6,xl: 6}"

                            :row-search="{cols: 12,sm: 12,md: 6,lg: 6,xl: 6}"
                            :row-after-search="{cols: 12, sm: 12, md: 6, lg: 6, xl: 6}"

                            :row-pagination="{cols: 12, sm: 12, md: 6, lg: 5, xl: 5}"
                            :row-between-pagination="{cols: 12, sm: 12, md: 1, lg: 4, xl: 4}"
                            :row-per-page="{cols: 12, sm: 12, md: 5, lg: 3, xl: 3}"

                            @rowEdit="openEditEnabledPerson"
                            @rowInfo="showRowInfo"
                            @rowDelete="openDeleteModal"
                            fullWidth="true"
                            noContainerPadding="true"
            >
              <b-container slot="row-before-search" class="mw-100 p-0 mb-2">
                <b-row align-h="end">
                  <b-col cols="auto">
                      <b-button class="b-c-green" v-on:click="refreshEnabledPerson(true)">{{ $tc('message.add', 1) }} {{ $tc('message.person', 1).toLowerCase() }} </b-button>
                  </b-col>
                </b-row>
              </b-container>
            </standard-table>
          </b-row>
        </b-container>
        <div slot="modal-footer" class="w-100">
          <b-button id="enabled-person-cancel"
                    type="button"
                    class="float-right ml-3 mt-2"
                    @click="$refs.enablePersonModal.hide()"> {{ $tc('message.close', 1) }}
          </b-button>
        </div>
      </b-modal>

      <!-- Modal add enabled person -->
      <b-modal id="addEnabledPersonModal"
               ref="addEnabledPersonModal"
               hide-footer
               :title="`${$tc('message.add', 2)} ${$tc('message.enabledPerson', 1).toLowerCase()}`">
        <ValidationObserver ref="form" v-slot="{ invalid, pending }">
        <div v-on:change="newEnabledPersonChanged = true" class="m-1">

          <b-row align-h="center">
            <b-col class="mt-1" cols="12" sm="12" md="12" lg="10" xl="10">
              <!-- Name -->
              <ValidationProvider :rules="{ required: true, max: 50 }" :name="`${$tc('message.name', 1)} (*)`" v-slot="{ valid, errors }">
                <b-form-group :label="`${$tc('message.name', 1)} (*)`">
                  <b-form-input id="name"
                                type="text"
                                autocomplete="off"
                                v-model="newEnabledPerson.nome"
                                @input="newEnabledPersonChanged = true"
                                :state="lodash.first(errors) && newEnabledPersonChanged ? false : (valid ? (newEnabledPersonChanged ? true : null) : null)">
                  </b-form-input>

                  <b-form-invalid-feedback id="inputLiveFeedback">
                    {{ lodash.first(errors) }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>

              <!-- Surname -->
              <ValidationProvider :rules="{ required: true, max: 50 }" :name="`${$tc('message.surname', 1)} (*)`" v-slot="{ valid, errors }">
                <b-form-group :label="`${$tc('message.surname', 1)} (*)`">
                  <b-form-input id="surname"
                                type="text"
                                autocomplete="off"
                                @input="newEnabledPersonChanged = true"
                                v-model="newEnabledPerson.cognome"
                                :state="lodash.first(errors) && newEnabledPersonChanged ? false : (valid ? (newEnabledPersonChanged ? true : null) : null)">
                  </b-form-input>

                  <b-form-invalid-feedback id="inputLiveFeedback">
                    {{ lodash.first(errors) }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>

              <!-- Fiscal code -->
              <ValidationProvider :rules="{ taxCode: true, required: true }" :name="`${$tc('message.taxCode', 1)} (*)`"
                                  ref="validationTaxCode"
                                  v-slot="{ valid, errors }">
                <b-form-group :label="`${$tc('message.taxCode', 1)} (*)`">
                  <b-form-input id="child-fiscal-code"
                                v-model="newEnabledPerson.codice_fiscale"
                                @input="newEnabledPersonChanged = true"
                                :state="lodash.first(errors) && newEnabledPersonChanged ? false : (valid ? (newEnabledPersonChanged ? true : null) : null)">
                  </b-form-input>

                  <b-form-invalid-feedback id="inputLiveFeedback">
                    {{ lodash.first(errors) }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>

              <!-- Phone 1 -->
              <ValidationProvider :rules="{ min: 0, max: 20, required: true }" :name="`${$tc('message.phone', 1)} 1 (*)`" v-slot="{ valid, errors }">
                <b-form-group :label="`${$tc('message.phone', 1)} 1 (*)`">
                  <b-form-input id="phone1"
                                type="number"
                                min="0"
                                max="20"
                                @input="newEnabledPersonChanged = true"
                                v-model="newEnabledPerson.numero_telefono"
                                :state="lodash.first(errors) && newEnabledPersonChanged ? false : (valid ? (newEnabledPersonChanged ? true : null) : null)">
                  </b-form-input>

                  <b-form-invalid-feedback id="inputLiveFeedback">
                    {{ lodash.first(errors) }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>

              <!-- Document -->
              <ValidationProvider :rules="{ required: true }" :name="`${$tc('message.document', 1)}`"
                                  ref="newFile"
                                  v-slot="{ valid, errors }">
              <b-form-group
                  :label="`${$tc('message.document', 1)} (*)`"
                  label-for="enable-person-document">
                <b-form-file v-model="newFile"
                             :placeholder="`${$tc('message.select', 1)} ${$tc('message.file', 1)}`"
                             id="enable-person-document"
                             @input="newEnabledPersonChanged = true"
                             ref="newFile"
                             :state="lodash.first(errors) && newEnabledPersonChanged ? false : (valid ? (newEnabledPersonChanged ? true : null) : null)"
                             name="newFile">
                </b-form-file>
                <b-form-invalid-feedback id="inputLiveFeedback">
                  {{ lodash.first(errors) }}
                </b-form-invalid-feedback>
              </b-form-group>
              </ValidationProvider>

              <!-- Document -->
              <b-form-group v-if="newEnabledPerson.url_documento"
                  :label="`${$tc('message.showDocument', 1)}`"
                  label-for="enable-person-document">
                <b-button
                          type="button"
                          class="fas fa-eye b-c-green"
                          @click="downloadFile(newEnabledPerson.url_documento, `${$tc('message.document', 1)}`)">
                </b-button>
              </b-form-group>

              <!-- Document description -->
              <ValidationProvider :rules="{ max: 30 }" :name="`${$tc('message.document', 1)}`"
                                  ref="validationTaxCode"
                                  v-slot="{ valid, errors }">
              <b-form-group :label="`${$tc('message.description', 1)} ${$tc('message.document', 1).toLowerCase()}`">
                <b-form-input v-model="newEnabledPerson.didascalia"
                              id="document-description"
                              autocomplete="off"
                              @input="newEnabledPersonChanged = true"
                              :state="newEnabledPerson.didascalia && newEnabledPerson.didascalia.length > 0 && lodash.first(errors) && newEnabledPersonChanged ? false : (valid ? (newEnabledPersonChanged ? true : null) : null)"
                              name="documentDescription">
                </b-form-input>
                <b-form-invalid-feedback id="inputLiveFeedback">
                  {{ lodash.first(errors) }}
                </b-form-invalid-feedback>
              </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
        </div>

        <div slot="modal-footer" class="w-100">
          <b-button id="add-enabled-person-cancel"
                    type="button"
                    class="float-right ml-3 mt-2"
                    @click="$refs.addEnabledPersonModal.hide('button')">{{ $tc('message.close', 1) }}
          </b-button>

          <b-button id="add-enabled-person-save-and-close"
                    type="button"
                    class="float-right ml-3 mt-2 b-c-green"
                    :disabled="invalid || pending || !newEnabledPersonChanged || ((newEnabledPerson.id === null || newEnabledPerson.id === undefined)&& (newFile === undefined || newFile === null))"
                    v-on:click="addDocumentToEnabledPerson(true)">{{ $tc('message.saveClose', 1) }}
          </b-button>
        </div>
        </ValidationObserver>
      </b-modal>
      <b-modal ref="infoEnabledPersonModal" id="infoEnabledPersonModal" :title="`${$tc('message.details', 1)}`" size="lg">
        <b-container>
          <b-row>
            <b-col cols="12" sm="12" md="12" lg="12" xl="12">
              <div class="d-flex">
                <h6><b>{{$tc('message.name', 1)}}</b></h6>
                <h6 class="ml-2">{{infoEnabledPersonItem.nome || $tc('message.emptyValue')}}</h6>
              </div>
            </b-col>

            <b-col cols="12" sm="12" md="12" lg="12" xl="12">
              <div class="d-flex">
                <h6><b>{{$tc('message.surname', 1)}}</b></h6>
                <h6 class="ml-2">{{infoEnabledPersonItem.cognome || $tc('message.emptyValue')}}</h6>
              </div>
            </b-col>

            <b-col cols="12" sm="12" md="12" lg="12" xl="12">
              <div class="d-flex">
                <h6><b>{{$tc('message.taxCode', 1)}}</b></h6>
                <h6 class="ml-2">{{infoEnabledPersonItem.codice_fiscale || $tc('message.emptyValue')}}</h6>
              </div>
            </b-col>

            <b-col cols="12" sm="12" md="12" lg="12" xl="12">
              <div class="d-flex">
                <h6><b>{{$tc('message.showDocument', 1)}}</b></h6>
              <b-button
                  type="button"
                  class="fas fa-eye b-c-green p-2 m-2"
                  @click="downloadFile(infoEnabledPersonItem.url_documento, `${$tc('message.document', 1)}`)">
              </b-button>
              </div>
            </b-col>

            <b-col cols="12" sm="12" md="12" lg="12" xl="12">
              <div class="d-flex">
                <h6><b>{{$tc('message.description', 1)}} {{$tc('message.document', 1).toLowerCase()}}</b></h6>
                <h6 class="ml-2">{{infoEnabledPersonItem.didascalia || $tc('message.emptyValue')}}</h6>
              </div>
            </b-col>
          </b-row>
        </b-container>

        <div slot="modal-footer" class="w-100 justify-content-end d-flex">
          <b-button class="b-c-green" @click="$refs.infoEnabledPersonModal.hide()"> Ok </b-button>
        </div>
      </b-modal>

      <b-modal ref="deleteModal" id="deleteModal" :title="`${$t('message.caution')}!`">
        <b-container fluid>
          <h5>{{$t('message.deleteCheck')}} {{deleteItem.description}}?</h5>
        </b-container>

        <div slot="modal-footer" class="w-100 justify-content-end d-flex">
          <b-button id="nw-delete-close" class="m-1 b-c-green" @click="$refs.deleteModal.hide()">{{$t('message.cancel')}}</b-button>

          <b-button id="nw-delete-continue" class="m-1 b-c-green" @click="deleteItem()">{{$t('message.continue')}}</b-button>
        </div>
      </b-modal>
    </b-container>
</template>

<script>
  import Vue from 'vue'
  import _ from 'lodash'
  import { DateTime } from 'luxon'
  import { Italian } from 'flatpickr/dist/l10n/it.js'

  export default {
    name: 'EditChild',
    created () {
      this.fetchData().then(() => {
        this.addWatcher()
      })
    },
    mounted () {
      this.$root.$on('change-lang', () => {
        this.$nextTick(() => {
          if (this && this.$refs && this.$refs.form) this.$refs.form.validate()
        })
      })
    },
    data () {
      const yearId = Vue.getLocalStorage('year')
      const childId = this.$route.params.id
      const lodash = _
      const backPath = '/registry/child'
      const dateConfig = {
        dateFormat: 'Y-m-d',
        mode: 'single',
        altFormat: 'D j M Y',
        altInput: true,
        locale: Italian,
        minDate: '',
        maxDate: '',
        disable: []
      }
      const genericChild = {
        data_cancellazione: null,
        data_iscrizione: null,
        id: null
      }
      return {
        DateTime,
        backPath,
        childId,
        lodash,
        yearId,
        originalStructureChild: null,
        child: _.cloneDeep(genericChild),
        watcher: null,
        watcherChild: null,
        watcherStructure: null,
        itemChanged: false,
        birthDateConfig: _.cloneDeep(dateConfig),
        startDateConfig: _.cloneDeep(dateConfig),
        endDateConfig: _.cloneDeep(dateConfig),
        yearOptions: [],
        structureOptions: [],
        editChild: {},
        attributes: [],
        attributesChanged: false,
        attributesFields: [
          { key: 'select', label: '' },
          { key: 'nome', label: `${this.$tc('message.name', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'descrizione', label: `${this.$tc('message.description', 1)}`, sortable: true, sortDirection: 'desc' }
        ],
        enabledPersonsFields: [
          { key: 'edit', label: '' },
          { key: 'info', label: '' },
          { key: 'delete', label: '' },
          { key: 'nome', label: `${this.$tc('message.name', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'cognome', label: `${this.$tc('message.surname', 1)}`, sortable: true, sortDirection: 'desc' }
        ],
        enabledPersonItems: [],
        enabledPersonChanged: false,
        newEnabledPerson: {},
        newEnabledPersonChanged: false,
        newFile: null,
        documentDescription: '',
        newEnabledPersonStarted: false,
        childWatcher: null,
        infoEnabledPersonItem: {},
        currentEnabledPersonToDelete: {}
      }
    },
    methods: {
      resetWatcher () {
        this.$root.$emit('activeLoader', true)
        if (this.watcher) this.watcher()
        if (this.watcherChild) this.watcherChild()
        if (this.watcherStructure) this.watcherStructure()
        this.itemChanged = false
      },
      addWatcher () {
        this.$root.$emit('activeLoader', false)
        this.watcherChild = this.$watch('child', newVal => {
          this.itemChanged = true
        }, { deep: true })
      },
      fetchData () {
        this.resetWatcher()
        return this.getOptions().then(() => {
          return this.getChild()
        })
      },
      getOptions () {
        const axiosArray = []
        axiosArray.push(this.getStructures())
        return Vue.myAll(axiosArray)
      },
      getStructures () {
        return Vue.myGet(this, '/struttura', 'strutture').then(structures => {
          this.structureOptions = structures
          return true
        }).catch(error => {
          Vue.manageErrors(this, error)
          return true
        })
      },
      getChild () {
        if (this.childId !== 'new') {
          return Vue.myGet(this, `/bambino/${this.childId}`, 'bambini').then((child) => {
            this.child = child
            this.getAttributes()
            this.getEnabledPersons()
            return child
          }).catch(error => {
            Vue.manageErrors(this, error)
            return true
          })
        } else {
          this.getAttributes()
        }
      },
      getAttributes () {
        this.attributes = []
        Vue.myGet(this, '/attributo', 'attributi').then((attributes) => {
          if (!_.isNil(this.child) && !_.isNil(this.child.attributi) && this.child.attributi.length > 0) {
            this.child.attributi.forEach(attribute => {
              const currentAttribute = _.find(attributes, obj => obj.id === attribute.id)
              if (!_.isNil(currentAttribute)) {
                currentAttribute.selected = true
              }
            })
          }
          this.attributes = attributes
          this.$root.$emit('activeLoader', false)
        }).catch(error => {
          console.log(error)
          Vue.manageErrors(this, error)
        })
      },
      getEnabledPersons () {
        this.enabledPersonItems = []
        Vue.myGet(this, '/persona_abilitata?bambino=' + this.child.id, 'persone').then((enabledPersons) => {
          this.enabledPersonItems = enabledPersons
          this.$root.$emit('activeLoader', false)
        }).catch(error => {
          console.log(error)
          Vue.manageErrors(this, error)
        })
      },
      exitCheck (itemChanged) {
        if (itemChanged) this.$root.$emit('exit-check', { redirect: this.backPath })
        else this.$router.push(this.backPath)
      },
      save (pushBack) {
        this.child.codice_fiscale = this.child.codice_fiscale.toUpperCase()
        this.child.attributi = this.getAttributesToAssociate()
        Vue.patchOrPost(this.childId !== 'new',
        `/bambino${this.childId !== 'new' ? '/' + this.childId : ''}`, this.child).then(r => {
          Vue.success(this, `${this.$tc('message.child', 1)} ${this.$t(this.childId !== 'new' ? 'success.putSuccess' : 'success.postSuccess')}`)
          if (pushBack) this.$router.push(this.backPath)
          else this.$router.push({ name: 'RefreshPage', params: { id: r.data.id, name: 'EditChild' } })
        }).catch(errorObj => {
          const error = errorObj.response
          if (error && error.data && error.data.constraint && error.data.constraint === 'codice_fiscale' &&
          this.$refs && this.$refs.validationTaxCode) {
            this.$refs.validationTaxCode.setErrors([this.$tc('constraints.codice_fiscale', 1)])
          }
          Vue.manageErrors(this, errorObj)
        })
      },
      getFlatPickrClass (ref, valid, errors, itemChanged, isRequired) {
        const nullClass = 'form-control'
        const falseClass = 'form-control is-invalid'
        const trueClass = 'form-control is-valid'
        const result = isRequired
        ? _.first(errors) && itemChanged ? falseClass : (valid ? (itemChanged ? trueClass : nullClass) : nullClass)
        : _.first(errors) ? falseClass : nullClass

        if (this.$refs && this.$refs[ref] && this.$refs[ref].$el && this.$refs[ref].$el.parentElement) {
          const el = this.$refs[ref].$el.parentElement.children && this.$refs[ref].$el.parentElement.children[1]
          el.className = result
        }

        return result
      },
      checkSelectStyle (ref, groupChanged, valid, errors, required) {
        Vue.MySelectValidationClass(this, ref, groupChanged, valid, errors, required)
      },
      getFields () {
        return [
          { key: 'edit', label: '', hideInTable: this.itemChanged },
          { key: 'structure', label: `${this.$tc('message.structure', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'startDate', label: `${this.$tc('message.dateStart', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'endDate', label: `${this.$tc('message.discharge', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'endDate', hideInTable: true, hideInExcel: true }
        ]
      },
      handleAttributeAllSelected (event) {
        this.attributesChanged = true
        const changedItems = Array.isArray(event) ? event : [event]
        changedItems.forEach(item => {
          item.selected = !item.selected
        })
      },
      handleAttributeRowSelected (item) {
        this.attributesChanged = true
        const originalIndex = _.findIndex(this.attributes, obj => obj.id === item.id)
        this.attributes[originalIndex].selected = item.selected
      },
      getAttributesToAssociate () {
        const attributesArray = []
        this.attributes.forEach((item) => {
          if (item.selected) {
            attributesArray.push({ id: item.id })
          }
        })
        return attributesArray
      },
      hideAttributesModal () {
        this.$refs.attributesModal.hide()
      },
      openEditEnabledPerson (event) {
        this.newEnabledPerson = event && event.item
        if (this.newEnabledPerson.documento) {
          this.newEnabledPerson.didascalia = this.newEnabledPerson.documento.didascalia
          this.newEnabledPerson.url_documento = Vue.getBaseUrl() + this.newEnabledPerson.documento.url_documento
        }
        this.$refs.addEnabledPersonModal.show()
      },
      refreshEnabledPerson (open) {
        this.newEnabledPerson = {}
        this.documentDescription = null
        this.newFile = null
        this.enabledPersonChanged = false
        this.checkEnabledPersonWatcher()
        if (open) {
          this.enabledPersonChanged = false
          this.$refs.addEnabledPersonModal.show()
          this.addEnabledPersonWatcher()
        }
      },
      addEnabledPersonWatcher () {
        this.childWatcher = this.$watch('newEnabledPerson', () => {
          this.newEnabledPersonChanged = true
        }, { deep: true })
      },
      checkEnabledPersonWatcher () {
        if (this.childWatcher) {
          this.childWatcher()
          this.newEnabledPersonChanged = false
        }
      },
      checkAddEnabledPerson (obj) {
        if (obj.trigger && this.newEnabledPersonChanged) {
          obj.preventDefault()
          const modalObj = {
            modalItSelf: this.$refs.addEnabledPersonModal,
            modalBeforeItSelf: this.$refs.enablePersonModal
          }
          this.$root.$emit('showExitMessageModal', { route: { name: 'Child', params: { structure: this.structure, year: this.year } }, modalObj })
        } else if (obj.trigger) {
          this.$refs.enablePersonModal.show()
        }
      },
      addDocumentToEnabledPerson (exit) {
        const formData = new FormData()
        if (!_.isNil(this.newFile) || !_.isNil(this.newEnabledPerson.didascalia)) {
          formData.append('dati', JSON.stringify({ didascalia: this.newEnabledPerson.didascalia }))
          if (!_.isNil(this.newFile)) { formData.append('file', this.newFile) }
          let path = '/documento'
          if (!_.isNil(this.newEnabledPerson.documento) && !_.isNil(this.newEnabledPerson.documento.id)) path += '/' + this.newEnabledPerson.documento.id
          Vue.patchOrPost(!_.isNil(this.newEnabledPerson.documento), path,
              formData, 'documenti').then((response) => {
            this.addPerson(response.data.id, exit)
          }).catch((error) => {
            Vue.manageErrors(this, error)
          })
        } else {
          this.addPerson(null, exit)
        }
      },
      addPerson (documentId, exit) {
        const editedPerson = this.newEnabledPerson
        if (!_.isNil(documentId)) { editedPerson.id_documento = documentId }
        delete editedPerson.abilitato_bambino_id
        let path = '/persona_abilitata'
        if (!_.isNil(this.newEnabledPerson.id)) path += '/' + this.newEnabledPerson.id
        Vue.patchOrPost(!_.isNil(this.newEnabledPerson.id), path, editedPerson, 'persone').then((response) => {
          if (_.isNil(this.newEnabledPerson.id)) {
            this.addEnabledPerson(response.data.id, exit)
          } else {
            Vue.success(this, `${this.$tc('message.child', 1)} ${this.$t(this.childId !== 'new' ? 'success.putSuccess' : 'success.postSuccess')}`)
            this.$router.push({ name: 'RefreshPage', params: { id: this.child.id, name: 'EditChild' } })
          }
        }).catch((error) => {
          console.log(error)
          Vue.manageErrors(this, error)
        })
      },
      addEnabledPerson (personId, exit) {
        let path = '/abilitato_bambino'
        if (!_.isNil(this.newEnabledPerson.abilitato_bambino_id)) path += '/' + this.newEnabledPerson.abilitato_bambino_id
        Vue.patchOrPost(!_.isNil(this.newEnabledPerson.abilitato_bambino_id), path,
            { id_persona: personId, id_bambino: parseInt(this.child.id, 10) },
            'persona_abilitata_bambino')
            .then(() => {
              Vue.success(this, `${this.$tc('message.child', 1)} ${this.$t(this.childId !== 'new' ? 'success.putSuccess' : 'success.postSuccess')}`)
              if (exit) {
                this.newFile = null
                this.documentDescription = ''
                this.newEnabledPerson = {}
                this.newEnabledPersonStarted = false
                this.newEnabledPersonChanged = false
                this.$refs.enablePersonModal.show()
              }
              this.$router.push({ name: 'RefreshPage', params: { id: this.child.id, name: 'EditChild' } })
            })
            .catch((error) => {
              this.checkEnabledPersonWatcher()
              this.refreshEnabledPerson()
              Vue.manageErrors(this, error)
            })
      },
      closeAddEnabledPersonModal () {
        this.$refs.addEnabledPersonModal.hide('button')
      },
      downloadFile (fileURL, fileName) {
        Vue.downloadFile(fileURL, fileName)
      },
      showRowInfo (event) {
        this.infoEnabledPersonItem = event && event.item
        if (this.infoEnabledPersonItem.documento) {
          this.infoEnabledPersonItem.didascalia = this.infoEnabledPersonItem.documento.didascalia
          this.infoEnabledPersonItem.url_documento = Vue.getBaseUrl() + this.infoEnabledPersonItem.documento.url_documento
        }
        this.$refs.infoEnabledPersonModal.show()
      },
      deleteItem () {
        const item = this.currentEnabledPersonToDelete
        this.$refs.deleteModal.hide()
        return Vue.myDelete(`/abilitato_bambino/${item.abilitato_bambino_id}`).then(() => {
          return Vue.myDelete(`/persona_abilitata/${item.id}`).then(() => {
            Vue.success(this, `${this.$tc('message.child', 1)} ${this.$t(this.childId !== 'new' ? 'success.putSuccess' : 'success.postSuccess')}`)
            this.$router.push({ name: 'RefreshPage', params: { id: this.child.id, name: 'EditChild' } })
          }).catch(error => {
            Vue.manageErrors(this, error)
            this.$router.push({ name: 'RefreshPage', params: { id: this.child.id, name: 'EditChild' } })
          })
        }).catch(error => {
          Vue.manageErrors(this, error)
          this.$router.push({ name: 'RefreshPage', params: { id: this.child.id, name: 'EditChild' } })
        })
      },
      openDeleteModal (event) {
        this.currentEnabledPersonToDelete = event && event.item
        this.$refs.deleteModal.show()
      }
    }
  }
</script>
