// main components
import Vue from 'vue'
import CripNotice from 'crip-vue-notice'

Vue.use(CripNotice, {
  duration: 5,
  className: 'notification',
  styles: { top: 0, right: 0 },
  icons: {
    info: 'fa fa-info-circle',
    success: 'fa fa-check-circle',
    warning: 'fa fa-exclamation-circle',
    error: 'fa fa-times-circle',
    close: 'fa fa-times'
  }
})

const MyNotify = {
  install (Vue) {
    Vue.info = (it, message) => {
      it.$notice.info({
        title: it.$t('message.info'),
        description: message
      })
    }

    Vue.success = (it, message) => {
      it.$notice.success({
        title: 'Ok',
        description: message
      })
    }

    Vue.warning = (it, message) => {
      it.$notice.warning({
        title: it.$t('message.warning'),
        description: message
      })
    }

    Vue.error = (it, message) => {
      it.$notice.error({
        title: it.$t('message.error'),
        description: message
      })
    }
  }
}

export default MyNotify
