/*
For messages that has only a variant like:
      "warning: 'Warnings'"
use: "<template> <h1>{{ $t('message.warning') }}, it is danger!</h1> </template>" syntax to obtain:
Warning, it is danger!

Else if message contains multiple options divided by "|" like:
      "key: 'Key | Keys'"
use: "<template> <h1>There are: 5 {{ $tc('message.warning', 2) }}</h1> </template>" syntax to obtain:
There are: 5 Keys

File input "Browse" button changing by css, edit in App.vue .lang-en { content: "Browse"} or .lang-it { content: "Cerca"}

If need to change default language see prop/language.js
 */

const messages = {
  en: {
    message: {
      resource: 'Resource',
      up: 'Up',
      down: 'Down',
      status: 'Status',
      willBeRemoved: 'Following children will be removed from stop.',
      plateNumber: 'Plate number',
      bus: 'Bus | Buses',
      route: 'Route',
      stop: 'Stop | Stops',
      civicNumber: 'Civic number',
      trip: 'Trip | Trips',
      tripStatus: 'Trip status',
      changeStructure: 'Change structure',
      modelDownload: 'Model download',
      export: 'Export',
      importChildren: 'Import children',
      exportChildren: 'Export children',
      importTrip: 'Import trip',
      importTemplate: 'Import trip template',
      role: 'Role',
      passwordCheck: 'Password check',
      calendar: 'Calendar',
      dateStart: 'Start date',
      dateEnd: 'End date',
      discharge: 'Discharge date',
      birthDate: 'Birth date',
      tripDate: 'Trip date',
      parent1: 'Main parent',
      parent2: 'Other parent',
      firstTaxCode: 'First parent tax code',
      otherTaxCode: 'Other parent tax code',
      child: 'Child | Children',
      manageUser: 'User management',
      userStatus: 'User status',
      username: 'Username',
      hasUser: 'Has user',
      photo: 'Photo',
      commonResidence: 'Common residence',
      provinceResidence: 'Province residence',
      residenceAddress: 'Residence address',
      registrationNumber: 'Reg. number',
      section: 'Section | Sections',
      filter: 'Filter | Filters',
      selectLabel: 'Select',
      deselectLabel: 'Remove',
      selectedLabel: 'Selected',
      licenceLight: 'Light',
      licenceFull: 'Full',
      syntheticName: 'Synthetic name',
      denomination: 'Denomination',
      administrativeCode: 'Administrative code',
      hourLimitAppeal: 'Hour limit appeal | Hour appeal',
      offsetAlert: 'Offset alert',
      licenceType: 'Licence type',
      city: 'City',
      officialPhone: 'Official phone',
      secondaryPhone: 'Secondary telephone',
      phone: 'Phone number',
      businessName: 'Business name',
      province: 'Province',
      pecMail: 'PEC email',
      VATNumber: 'VAT number',
      address: 'Address',
      fax: 'FAX',
      officialEmail: 'Official email',
      secondaryEmail: 'Secondary email',
      common: 'Common',
      cap: 'Postal code',
      sessionExpired: 'Session has been inactive for more than 15 minutes, please log in again',
      forbidden: 'Access denied',
      tokenExpired: 'Session is expired',
      navigate: 'Navigate',
      login: 'Login | Login',
      logout: 'Logout | Logout',
      organization: 'Organization | Organizations',
      configuration: 'Configuration | Configurations',
      test: 'Test | Tests',
      table: 'Table | Tables',
      assignment: 'Assignment',
      key: 'Key | Keys',
      vehicle: 'Vehicle | Vehicles',
      registry: 'Registry | Registries',
      person: 'Person | People',
      attachment: 'Attachment | Attachments',
      type: 'Type | Types',
      document: 'Document | Documents',
      user: 'User | Users',
      password: 'Password',
      contact: 'Contact | Contacts',
      search: 'Search | Search',
      close: 'Close',
      clear: 'Clear',
      select: 'Select',
      unselect: 'Unselect',
      all: 'All',
      add: 'Add',
      added: 'Added | Added | Added',
      emptyText: 'There\'s no items',
      emptyFilteredText: 'No items found',
      row: 'Row | Rows',
      selectOption: 'Select Option',
      noOption: 'Options not found',
      edit: 'Edit',
      data: 'Data',
      details: 'Details',
      disable: 'Disable',
      disabled: 'Disabled | Disabled | Disabled',
      enable: 'Enable',
      enabled: 'Enabled | Enabled | Enabled',
      save: 'Save',
      saveClose: 'Save and close',
      begin: 'Begin | Begin',
      actual: 'Actual | Actual',
      update: 'Update | Updates',
      info: 'Info',
      warning: 'Warnings',
      error: 'Error',
      note: 'Note | Notes',
      page404: 'Sorry, page not found',
      required: 'Required | Required',
      description: 'Description',
      customer: 'Customer',
      done: 'Done | Done',
      caution: 'Caution',
      upload: 'Upload',
      item: 'Item',
      not: 'Not',
      deleteCheck: 'Are you sure to delete item',
      delete: 'Delete',
      continue: 'Continue',
      start: 'Start',
      end: 'End',
      max: 'Max | Max',
      min: 'Min | Min',
      year: 'Year',
      workInProgress: 'Work in progress ...',
      removed: 'Removed',
      yes: 'Yes',
      no: 'No',
      last: 'Last',
      first: 'First',
      refresh: 'Refresh',
      noDate: 'No date',
      report: 'Report | Report | Reports',
      emptyValue: '- - - - - -',
      new: 'New | New',
      forThisVehicle: 'For this vehicle',
      in: 'In',
      month: 'Month | Months',
      inDate: 'In date',
      or: 'Or',
      noSave: 'Don\'t save',
      cancel: 'Cancel',
      unsavedChanges: 'Redirect in progress, there are some changes.',
      remain: 'Remain',
      list: 'List',
      associated: 'Associated',
      available: 'Available | Available',
      found: 'Found | Found',
      when: 'When',
      notification: 'Notification | Notifications',
      and: 'And',
      reminder: 'Reminder',
      shortcut: 'Shortcut | Shortcuts',
      show: 'Show',
      hide: 'Hide',
      shown: 'Shown | Shown',
      hidden: 'Hidden | Hidden',
      searchBy: 'Search by',
      filterBy: 'Filter by',
      surname: 'Surname',
      taxCode: 'Tax code',
      staff: 'Staff',
      name: 'Name',
      resetFilters: 'Reset filters',
      maxLength: 'Max length',
      maxValue: 'Max value',
      import: 'Import',
      selectFile: 'Select file',
      browse: 'Browse',
      notFound: 'Not found',
      email: 'Email',
      summary: 'Summary',
      saveAndContinue: 'Save and continue',
      continueNoSave: 'Continue without saving',
      alreadyExist: 'Already exist',
      from: 'From',
      to: 'To',
      manual: 'Manual',
      automatic: 'Automatic',
      legalPerson: 'Legal person',
      structure: 'Structure | Structures',
      operator: 'Operator | Operators',
      importSuccess: 'Import success',
      present: 'Present',
      date: 'Date',
      nominative: 'Nominativ',
      totalChildAttendance: 'Total child attendance',
      associate: 'Associate | Association',
      recipient: 'Recipient',
      line: 'Line',
      startTime: 'Start time',
      endTime: 'end time',
      departureAdded: 'Departure correctly added for ',
      attribute: 'Attribute | Attributes',
      of: 'Of',
      choose: 'Choose',
      specialEquipment: 'Special equipment',
      enabledPerson: 'Enabled person | Enabled persons',
      handlingEnabledPerson: 'Handling of people that are enabled to take the child',
      file: 'File',
      showDocument: 'Show document',
      functionsConfiguration: 'Functions configuration',
      accompanied: 'Accompanied',
      deposit: 'Deposit | Deposits',
      register: 'Register',
      for: 'for',
      single: 'Single',
      serial: 'Serial',
      days: 'Days',
      monday: 'Monday',
      thursday: 'Thursday',
      wednesday: 'Wednesday',
      tuesday: 'Tuesday',
      friday: 'Friday',
      saturday: 'Saturday',
      appliedVariation: 'Variation applied',
      assistantId: 'Identify for assistant',
      id: 'ID',
      sendNotification: 'Send notification',
      notificationSendedToRecipients: 'Notification sended to recipients',
      presenceReport: 'Presence report',
      presences: 'Presences',
      variations: 'Variations',
      extraordinaryEvents: 'Extraordinary events',
      delay: 'Delay in minutes',
      tripAssignments: 'Trip assignments',
      assignments: 'Assignments',
      oldPassword: 'Insert old password',
      insertNewPassword: 'Insert new password',
      insertConfirmPassword: 'Insert new password for confirm',
      changePassword: 'Change password',
      back: 'Back',
      forgottenPassword: 'Forgot password ',
      confirmUser: 'Confirm user',
      confirm: 'Confirm',
      periodicTrip: 'Periodic trip | Periodic trips',
      singleTrip: 'Single trip | Single trips',
      active: 'Active',
      notActive: 'Not active',
      absent: 'Absent'
    },
    info: {
      childHistoryForCurrentYear: 'Child status in year',
      editStructureChildButton: 'Button is enabled only if there is no changes and discharge data is present.',
      specialEquipment: 'The number of total supplies for the trip is automatically calculated based on the number of children who need them and who are included in the trip',
      operatorAssociations: 'Operators can be associated in single trip but not with the periodic one'
    },
    success: {
      postSuccess: 'correctly added',
      putSuccess: 'correctly modified,',
      passwordChanged: 'Password changed.',
      resetPassword: 'The request was executed successfully, ' +
        'the new code will be sent on the communication channels registered in the system'
    },
    warnings: {
      editStructureChildImport:
      '* The massive import of the child\'s personal data will not change the data:' +
      'structure, section and date of registration in case the child is already present in the system',
      exitCheck: 'Some changes have been made, are you sure to continue?',
      checkDate: 'Error on date, check before save',
      canNotBeBefore: 'can note be before | can note be before',
      sureToDelete: 'Are you sure to delete item',
      continue: 'Continue',
      invalid: 'Invalid | Invalid',
      valid: 'Valid | Valid',
      onlyExcel: 'xls, xlsx or ods file authorized only',
      fileHasDifferentLang: 'The file\'s language diverging from the browser\'s language. Change file or browser language.',
      fileIsEmpty: 'The file is empty',
      noCalendarForSelectedStructure: 'There are no standard calendar for selected structure.',
      roleNotEnabled: 'Current user has no access permission by browser',
      notProgrammedTripForThisDate: 'There are not trip programmed for the specific date'
    },
    errors: {
      noTripManager: 'No trip manager found',
      importChildren1: 'Child is already exist in structure ',
      importChildren2: 'for year ',
      importChildren3: 'from ',
      importChildren4: 'to ',
      checkRegistrationDate: 'check registration date',
      checkDischarge: 'check cancellation date',
      currentYear1: 'School year start at ',
      currentYear2: 'and finish at',
      birthDateMoreThanNow: 'Child\'s birth date can not be further than current date, (birth date: ',
      tripDateMinThanNow: 'Trip date can not be minus than current date, (trip date: ',
      birthDateMoreThanMinDate: 'Child\'s birth date can not be further than min school year date (start of school year: ',
      inUse: 'Selected item has already been used and can not be removed',
      generic: 'Something went wrong',
      unusual: 'Item already created',
      login: 'Wrong username or password',
      taxCode: 'Invalid fiscal code',
      duplicateTaxCode: 'Fiscal code already exist',
      birthDate: 'Birth date is required. (Use DD/MM/YYY format)',
      tripDate: 'Trip date is required. (Use DD/MM/YYY format)',
      residenceAddress: 'Residence address is required. (Max 50 char)',
      residenceCommon: 'Common address is required. (Max 30 char)',
      residenceProvince: 'Province address is required. (Max 30 char)',
      mainPhone: 'Main phone number is required. (Max 20 numbers)',
      parent1: 'Main parent is required. (Max 50 char)',
      parent2: 'The secondary parent cannot exceed 50 characters.',
      taxCodeParent1: 'Invalid main parent fiscal code.',
      taxCodeParent2: 'Invalid secondary parent fiscal code.',
      mainEmail: 'Main email must not exceed 50 characters',
      secondaryEmail: 'Secondary email must not exceed 50 characters',
      secondaryPhone: 'The secondary telephone must consist of numbers and cannot exceed 20 digits',
      section: 'The identifier or name of an existing section is required.',
      sectionNotFound: 'The identifier or section name was not found. (Value sought:',
      structure: 'The identifier or name of an existing structure is required',
      structureNotFound: 'The identifier or the name of the structure was not found. (Value sought:',
      registrationDate: 'The registration date is required. (format DD/MM/YYYY)',
      discharge: 'The cancellation date is required. (format DD/MM/YYYY)',
      registrationDateAndDischargeAreIncompatible: 'The discharge date is before registration date',
      errorsList: 'Error list',
      isADuplicateOfRow: 'is a duplicate of the line',
      sectionOfOtherStructure: 'Selected structure is not compatible with selected section',
      alreadyAssociated: 'Child is already associated to structure',
      surnameLength: 'Surname(*) error, it is required and has 50 characters of max length',
      nameLength: 'Name(*) error, it is required and has 50 characters of max length',
      genericMaxLength1: 'Error on max length. (Max',
      genericMaxLength2: 'characters)',
      departureNotValid: 'The departed status is not valid for the child ',
      departureRegistration: 'Departure registration',
      typeMissing: 'Record type missing',
      muchEmails: 'It is not possible to insert more email addresses',
      line: 'The line field is requested. (Max 30 chars)',
      table: 'The table field is requested. (Max 30 chars)',
      descriptionTrip: 'The trip description field is not valid. (Max 50 chars)',
      nameTrip: 'The trip name field is not valid. (Max 50 chars)',
      nameStop: 'The stop name field is not valid. (Max 50 chars)',
      vehicle: 'The vehicle field is not valid. (Max 50 chars)',
      address: 'The address field is not valid. (Max 50 chars)',
      status: 'The status field is not valid. (Possible values are SALITA or DISCESA)',
      notes: 'The notes field is not valid. (Max 100 chars)',
      startTime: 'The hour field is requested in format HH:MM.',
      structureStop: 'The structure field is not valid. (Max 50 chars)',
      civicNumber: 'The civic number field is not valid. (Max 30 chars)',
      registerError: 'An error occurred while inserting the log.',
      deposit: 'The id_deposito field is required. It can be obtained from the deposit registry.',
      depositNotExist: 'The inserted id_deposito field is not existing in the system.',
      assistant: 'The assistant ID was not entered or is not valid.',
      taxCodeNotExist: 'The tax code entered does not exist in the system.',
      stopError: 'An error occurred while entering the stop.',
      sessionExpired: 'The session has been inactive for more than 15 minutes, log in again',
      newPassword: 'Enter the password (minimum 6 characters), the new password must be different from the old one',
      newPasswordConfirm: 'Enter the confirmation password correctly and check that it is different from the old password',
      muchReset: 'Maximum number of resets reached (3), ' +
        'the button will be re-enabled after 30 seconds',
      invalidUsername: 'The username entered is not valid',
      resetPassword: 'Error requesting password reset'
    },
    months: {
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'October',
      november: 'November',
      december: 'December'
    },
    constraints: {
      codice_fiscale: 'The tax code is duplicated',
      anno_struttura_bambino: 'Child already associated with the structure for the specified year',
      attributi_bambino: 'Duplicate attribute',
      foto_bambino: 'Photo already associated with the indicated child',
      giorno_voce_calendario: 'Day already entered for the indicated calendar item',
      anno_scolastico: 'School year already registered',
      attributo_struttura: 'Attribute already present for the indicated structure',
      partita_iva: 'Duplicate VAT number',
      ragione_sociale: 'Duplicate business name',
      username: 'Username already present',
      struttura_sezione: 'Section already associated with the indicated structure',
      anno_bambino: 'Child already associated with a structure for the selected school year',
      denominazione_struttura: 'Duplicate structure name',
      targa: 'Plate already present in the system',
      fermata_gita: 'Stop already present in this trip'
    },
    httpCode: {
      400: {
        2: 'Error reading or interpreting an input parameter',
        4: 'Internal server error',
        7: 'Cannot delete or update',
        8: 'Unique violation. Duplicate key value',
        10: 'Error during insertion or modification of an entity due to the violation of a uniqueness constraint',
        11: 'Error during insertion or modification of an entity due to the violation of a constraint on one or more columns'
      },
      403: {
        0: 'Access denied'
      },
      404: 'Resource not found',
      409: {
        7: 'Conflict during removal'
      },
      413: 'Data size too large',
      422: {
        3: 'Error during communication'
      },
      423: {
        1: 'The password has expired'
      },
      440: 'The session has expired',
      500: {
        1: 'Unexpected server side error',
        4: 'Key not allowed',
        5: 'Not enough space to add a new key',
        6: 'Key is being edited',
        7: 'Incorrect key index',
        8: 'Unable to update tracker information',
        9: 'The command could not be updated'
      },
      502: 'Error 502, communication error'
    }
  },
  it: {
    message: {
      resource: 'Risorsa',
      up: 'Salita',
      down: 'Discesa',
      status: 'Stato',
      willBeRemoved: 'I seguenti bambini verranno rimossi dalla fermata.',
      plateNumber: 'Targa',
      bus: 'Mezzo | Mezzi',
      route: 'Percorso',
      stop: 'Fermata | Fermate',
      civicNumber: 'Civico',
      trip: 'Percorso | Percorsi',
      tripStatus: 'Stato percorso',
      changeStructure: 'Cambio struttura',
      modelDownload: 'Scarica modello',
      modelDownloadTemplate: 'Scarica modello periodico',
      modelVariationDownload: 'Scarica modello variazione',
      export: 'Esporta',
      importChildren: 'Importa bambini',
      exportChildren: 'Esporta bambini',
      importTrip: 'Importa percorso',
      importTemplate: 'Importa percorso periodico',
      role: 'Ruolo',
      passwordCheck: 'Password di conferma',
      calendar: 'Calendario',
      dateStart: 'Data inizio',
      dateEnd: 'Data fine',
      discharge: 'Data dimissioni',
      birthDate: 'Data di nascita',
      tripDate: 'Data percorso',
      parent1: 'Genitore principale',
      parent2: 'Genitore secondario',
      firstTaxCode: 'Codice fiscale genitore principale',
      otherTaxCode: 'Codice fiscale genitore secondario',
      child: 'Bambino | Bambini',
      manageUser: 'Gestione utenza',
      userStatus: 'Stato utenza',
      username: 'Nome utente',
      hasUser: 'Utenza associata',
      photo: 'Foto',
      provinceResidence: 'Provincia di residenza',
      commonResidence: 'Comune di residenza',
      residenceAddress: 'Indirizzo di residenza',
      registrationNumber: 'Matricola',
      section: 'Sezione | Sezioni',
      selectLabel: 'Seleziona',
      deselectLabel: 'Rimuovi',
      selectedLabel: 'Selezionato',
      licenceLight: 'Light',
      licenceFull: 'Full',
      syntheticName: 'Denominazione sintetica',
      denomination: 'Denominazione',
      administrativeCode: 'Codice amministrazione',
      hourLimitAppeal: 'Orario limite d\'appello | Ora appello',
      offsetAlert: 'Offset di avviso',
      licenceType: 'Tipo licenza',
      city: 'Città',
      phone: 'Telefono',
      officialPhone: 'Telefono ufficiale',
      secondaryPhone: 'Telefono secondario',
      businessName: 'Ragione sociale',
      province: 'Provincia',
      pecMail: 'PEC email',
      VATNumber: 'Partita IVA',
      address: 'Indirizzo',
      fax: 'FAX',
      officialEmail: 'Email ufficiale',
      secondaryEmail: 'Email secondaria',
      common: 'Comune',
      cap: 'CAP',
      sessionExpired: 'La sessione risulta inattiva da più di 15 minuti, eseguire nuovamente l\'accesso',
      forbidden: 'Accesso negato',
      tokenExpired: 'La sessione risulta scaduta',
      navigate: 'Navigazione',
      login: 'Accesso | accessi',
      logout: 'Uscita | Uscite',
      organization: 'Organizzazione | Organizzazioni',
      configuration: 'Configurazione | Configurazioni',
      test: 'Prova | Prove',
      table: 'Tabella | Tabelle',
      assignment: 'Assegnazione',
      key: 'Chiave | Chiavi',
      vehicle: 'Veicolo | Veicoli',
      registry: 'Anagrafica | Anagrafiche',
      person: 'Persona | persone',
      attachment: 'Allegato | Allegati',
      management: 'Gestione',
      type: 'Tipologia | Tipologie',
      document: 'Documento | Documenti',
      user: 'Utente | Utenti',
      password: 'Password',
      contact: 'Contatto | Contatti',
      search: 'Ricerca | Ricerche',
      close: 'Chiudi',
      clear: 'Cancella',
      select: 'Seleziona',
      unselect: 'Deseleziona',
      all: 'Tutti',
      add: 'Aggiungi',
      added: 'Aggiunto | Aggiunta | Aggiunte',
      emptyText: 'Non ci sono elementi presenti',
      emptyFilteredText: 'La ricerca non ha prodotto risultati',
      row: 'Riga | Righe',
      selectOption: 'Selezionare un\'opzione',
      noOption: 'Opzioni non disponibili',
      edit: 'Modifica',
      data: 'Dati',
      details: 'Dettaglio',
      disable: 'Disabilita',
      disabled: 'Disabilitato | Disabilitazione | Disabilitata',
      enable: 'Abilita',
      enabled: 'Abilitato | Abilitazione | Abilitata',
      save: 'Salva',
      saveClose: 'Salva e chiudi',
      begin: 'Iniziale | Iniziali',
      actual: 'Attuale | Attuali',
      update: 'Aggiornamento | Aggiornamenti',
      info: 'Informazione',
      warning: 'Attenzione',
      error: 'Errore',
      note: 'Nota | Note',
      page404: 'Ops, pagina non trovata',
      required: 'Obbligatorio | Obbligatoria',
      description: 'Descrizione',
      customer: 'Cliente',
      done: 'Eseguito | Eseguita',
      caution: 'Attenzione',
      upload: 'Caricamento',
      item: 'Elemento',
      not: 'Non',
      deleteCheck: 'Si è sicuri di voler eliminare l\'elemento ',
      deleteWarning: 'Non è possibile eliminare questa fermata: questo percorso non può avere una prima fermata a cui è associata una struttura. ' +
          'Disassocia la struttura dalla prossima fermata e riprova.',
      delete: 'Eliminazione',
      continue: 'Prosegui',
      start: 'Inizio',
      end: 'Fine',
      max: 'Massimo | Massimi',
      min: 'Minimo | Minimi',
      year: 'Anno',
      frequency: 'Frequenza',
      workInProgress: 'Pagina in fase di sviluppo ...',
      removed: 'Rimosso',
      yes: 'Sì',
      no: 'No',
      last: 'Ultimo',
      first: 'Primo',
      refresh: 'Ricarica',
      noDate: 'Nessuna data',
      report: 'Report | Segnalazione | Segnalazioni',
      emptyValue: '- - - - - -',
      new: 'Nuovo | Nuova',
      forThisVehicle: 'Per questo veicolo',
      in: 'Tra',
      month: 'Mese | Mesi',
      inDate: 'In data',
      or: 'Oppure',
      noSave: 'Non salvare',
      cancel: 'Annulla',
      unsavedChanges: 'La pagina verrà ricaricata e sono state apportate alcune modifiche.',
      valid: 'Valido | Valida',
      remain: 'Rimani',
      list: 'Lista',
      associated: 'Associato',
      available: 'Disponibile | Disponibili',
      found: 'Trovato | Trovati',
      when: 'Quando',
      notification: 'Notifica | Notifiche',
      and: 'E',
      reminder: 'Promemoria',
      shortcut: 'Scorciatoia | Scorciatoie',
      show: 'Mostra',
      hide: 'Nascondi',
      shown: 'Visualizzato | Visualizzati',
      hidden: 'Nascosto | Nascosti',
      filter: 'Filtro | Filtri',
      searchBy: 'Ricerca per',
      filterBy: 'Filtra per',
      missed: 'Mancante | Mancanti',
      surname: 'Cognome',
      taxCode: 'Codice fiscale',
      staff: 'Personale',
      name: 'Nome',
      resetFilters: 'Pulisci filtri',
      maxLength: 'Lunghezza massima',
      maxValue: 'Valore massimo',
      import: 'Importa',
      selectFile: 'Seleziona il file',
      browse: 'Ricerca',
      notFound: 'Non trovato',
      email: 'Email',
      summary: 'Riepilogo',
      saveAndContinue: 'Salva e prosegui',
      continueNoSave: 'Prosegui senza salvare',
      alreadyExist: 'Già esistente',
      from: 'Da',
      to: 'A',
      manual: 'Manuale',
      automatic: 'Automatico',
      legalPerson: 'Persona giuridica | Persone giuridiche',
      structure: 'Struttura | Strutture',
      operator: 'Operatore | Operatori',
      importSuccess: 'Importazione eseguita con successo',
      present: 'Presente',
      date: 'Data',
      nominative: 'Nominativo',
      totalChildAttendance: 'Totale presenze bambino',
      associate: 'Associa | Associazione',
      recipient: 'Destinatario',
      line: 'Linea',
      startTime: 'Orario inizio',
      endTime: 'Orario fine',
      departureAdded: 'Stato salita correttamente aggiunto per ',
      attribute: 'Attributo | Attributi',
      of: 'Del',
      choose: 'Scegli',
      specialEquipment: 'Dotazioni speciali',
      enabledPerson: 'Persona abilitata | Persone abilitate',
      handlingEnabledPerson: 'Gestione delle persone abilitate al ritiro del bambino',
      file: 'File',
      showDocument: 'Mostra documento caricato',
      functionsConfiguration: 'Configurazione funzioni',
      accompanied: 'Accompagnato',
      deposit: 'Deposito | Depositi',
      register: 'Registro',
      for: 'per',
      single: 'Singoli',
      serial: 'Periodici',
      days: 'Giorni',
      monday: 'Lunedì',
      thursday: 'Martedì',
      wednesday: 'Mercoledì',
      tuesday: 'Giovedì',
      friday: 'Venerdì',
      saturday: 'Sabato',
      appliedVariation: 'Variazione applicata',
      assistantId: 'Identificativo assistente',
      id: 'ID',
      sendNotification: 'Invia notifica',
      notificationSendedToRecipients: 'Notifica inviata ai destinatari',
      presenceReport: 'Report delle presenze',
      presences: 'Presenze',
      variations: 'Variazioni',
      extraordinaryEvents: 'Eventi straordinari',
      delay: 'Ritardo in minuti',
      tripAssignments: 'Assegnamenti percorso',
      assignments: 'Assegnamenti',
      oldPassword: 'Inserire la vecchia password',
      insertNewPassword: 'Inserire la nuova password',
      insertConfirmPassword: 'Inserire nuovamente la password',
      changePassword: 'Cambia password',
      back: 'Indietro',
      forgottenPassword: 'Password dimenticata',
      confirmUser: 'Conferma utente',
      confirm: 'Conferma',
      periodicTrip: 'Percorso periodico | Percorsi periodici',
      singleTrip: 'Percorso singolo | Percorsi singoli',
      active: 'Attivi',
      notActive: 'Non attivi',
      absent: 'Assente'
    },
    info: {
      childHistoryForCurrentYear: 'Stato del bambino nell\'anno',
      editStructureChildButton: 'É possibile cambiare struttura se presente una data di dimissioni e nessuna modifica in attesa di essere salvata',
      specialEquipment: 'Il numero di dotazioni totali per il percorso viene calcolato automaticamente sulla base del numero di bambini che ne hanno necessità e che risultano inseriti nel percorso',
      operatorAssociations: 'Gli operatori possono essere associati ai singoli percorsi ma non a quello periodico'
    },
    success: {
      postSuccess: 'aggiunto con successo',
      putSuccess: 'aggiornato con successo',
      passwordChanged: 'Password cambiata con successo.',
      resetPassword: 'La richiesta è stata eseguita con successo, ' +
        'verrà inviato il nuovo codice sui canali cominucativi censiti nel sistema'
    },
    warnings: {
      editStructureChildImport: '* L\'importazione massiva dei dati anagrafici del bambino non terrà di conto dei dati: ' +
      'struttura, sezione e data di iscrizione nel caso in cui il bambino sia già presente nel sistema',
      exitCheck: 'Sono state apportate alcune modifiche, si è sicuri di proseguire?',
      checkDate: 'Errore nella data, ricontrollare prima di proseguire',
      canNotBeBefore: 'non può essere minore di | non possono essere minori di',
      invalid: 'Non valido | Non valida',
      sureToDelete: 'Si è sicuri di voler eliminare l\'elemento',
      continue: 'Prosegui',
      valid: 'Valido | Valida',
      onlyExcel: 'Vengono accettati solo file di tipo xls, xlsx o ods',
      fileHasDifferentLang: 'La lingua del file è diversa dalla lingua del browser. Cambiare la lingua del browser o del file.',
      fileIsEmpty: 'Il file risulta vuoto',
      noCalendarForSelectedStructure: 'Non è presente alcun calendario standard per la struttura selezionata',
      roleNotEnabled: 'L\'utente utilizzato non possiede i permessi per accedere al sistema tramite browser',
      notProgrammedTripForThisDate: 'Non ci sono percorsi programmati per la data selezionata'
    },
    errors: {
      noTripManager: 'Nessun gestore percorso trovato',
      importChildren1: 'Il bambino risulta già associato alla struttura ',
      importChildren2: 'per l\'anno ',
      importChildren3: 'dal ',
      importChildren4: 'al ',
      checkRegistrationDate: 'ricontrollare la data d\' iscrizione',
      checkDischargeDate: 'ricontrollare la data di cancellazione',
      currentYear1: 'L\'anno scolastico inizia il',
      currentYear2: 'e termina il',
      birthDateMoreThanNow: 'La data di nascita del bambino non può essere maggiore della data odierna (Data di nascita: ',
      tripDateMinThanNow: 'La data del percorso non può essere minore della data odierna (Data del percorso: ',
      birthDateMoreThanMinDate: 'La data di nascita del bambino non può essere maggiore dell\'inizio dell\'anno scolastico (Data inizio anno scolastico: ',
      inUse: 'L\'elemento selezionato è già stato utilizzato e non può essere rimosso',
      generic: 'Qualcosa è andato storto',
      unusual: 'Elemento già esistente',
      login: 'Utente o password errati',
      surnameLength: 'Errore nel cognome, la lunghezza non è stata rispettata e il campo è richiesto. (50 caratteri)',
      nameLength: 'Errore nel nome(*), la lunghezza non è stata rispettata e il campo è richiesto. (50 caratteri)',
      taxCode: 'Errore nel codice fiscale, non risulta valido',
      duplicateTaxCode: 'Il codice fiscale inserito esiste già nel sistema',
      birthDate: 'Errore, la data di nascita è un campo richiesto, (formato GG/MM/AAAA)',
      tripDate: 'Errore, la data del percorso è un campo richiesto, (formato GG/MM/AAAA)',
      residenceAddress: 'L\'indirizzo di residenza è richiesto (Massimo 50 caratteri)',
      residenceCommon: 'Il comune di residenza è richiesto (Massimo 30 caratteri)',
      residenceProvince: 'La provincia di residenza è richiesto (Massimo 30 caratteri)',
      mainPhone: 'Il telefono principale è richiesto e dev\'essere composto da numeri (Massimo 20 cifre)',
      parent1: 'Il genitore principale è richiesto. (Massimo 50 caratteri)',
      parent2: 'Il genitore secondario non può superare 50 caratteri.',
      taxCodeParent1: 'Il codice fiscale del genitore principale non risulta valido.',
      taxCodeParent2: 'Il codice fiscale del genitore secondario non risulta valido.',
      mainEmail: 'La email ufficiale non può superare 50 caratteri)',
      secondaryEmail: 'La email secondaria non deve superare 50 caratteri',
      secondaryPhone: 'Il telefono secondario dev\'essere composto da numeri e non può superare le 20 cifre',
      section: 'L\'identificativo o il nome di una sezione esistente è richiesto.',
      sectionNotFound: 'L\'identificativo o il nome della sezione non è stato trovato. (Valore cercato:',
      structure: 'L\'identificativo o il nome di una struttura esistente è richiesto.',
      structureNotFound: 'L\'identificativo o il nome della struttura non è stato trovato. (Valore cercato:',
      registrationDate: 'La data d\'iscrizione è richiesta. (formato GG-MM-AAAA)',
      discharge: 'La data di cancellazione è richiesta. (formato GG-MM-AAAA)',
      registrationDateAndDischargeAreIncompatible: 'La data di cancellazione è minore di quella di iscrizione',
      errorsList: 'Lista errori',
      isADuplicateOfRow: 'è un duplicato della riga',
      sectionOfOtherStructure: 'La sezione selezionata non è compatibile con la struttura',
      alreadyAssociated: 'Il bambino risulta gia associato alla struttura',
      genericMaxLength1: 'Errore, la lunghezza massima non è stata rispettata (Massimo',
      genericMaxLength2: 'caratteri)',
      departureNotValid: 'Lo stato scelto non è valido per ',
      departureRegistration: 'Registrazione salita/discesa',
      typeMissing: 'Errore, tipo di record mancante',
      line: 'Il campo linea è richiesto. (Massimo 30 caratteri)',
      table: 'Il campo tabella è richiesto. (Massimo 30 caratteri)',
      descriptionTrip: 'La descrizione del percorso non risulta valido. (Massimo 50 caratteri)',
      nameTrip: 'Il nome del percorso non risulta valido. (Massimo 50 caratteri)',
      nameStop: 'Il nome della fermata non risulta valido. (Massimo 50 caratteri)',
      vehicle: 'La vettura non risulta valida. (Massimo 50 caratteri)',
      address: 'Il campo indirizzo è richiesto. (Massimo 50 caratteri)',
      status: 'Il campo stato non è valido. (Valori consentiti sono SALITA o DISCESA)',
      notes: 'Il campo note non è valido. (Massimo 100 caratteri)',
      startTime: 'Il campo orario è richiesto nel formato HH:MM.',
      structureStop: 'Il campo struttura non risulta valido. (Massimo 50 caratteri)',
      civicNumber: 'Il numero civico non risulta valido. (Massimo 20 caratteri)',
      muchEmails: 'Non è possibile inserire ulteriori indirizzi email',
      registerError: 'Si è verificato un errore nell\'inserimento del registro.',
      deposit: 'Il campo id_deposito è richiesto. E\' possibile ricavarlo dall\'anagrafica depositi.',
      depositNotExist: 'L\'id_deposito inserito non esiste.',
      assistant: 'L\'id assistente non è stato inserito oppure non risulta valido.',
      taxCodeNotExist: 'Il codice fiscale inserito non esiste nel sistema.',
      stopError: 'Si è verificato un errore nell\'inserimento della fermata.',
      sessionExpired: 'La sessione risulta inattiva da più di 15 minuti, eseguire nuovamente l\'accesso',
      newPassword: 'Inserire la password (minimo 6 caratteri), la nuova password dev\'essere differente da quella vecchia',
      newPasswordConfirm: 'Inserire la password di conferma correttamente e controllare che sia diversa dalla vecchia password',
      muchReset: 'Massimo numero di reset raggiunto (3), ' +
        'il pulsante verrà riabilitato dopo 30 secondi',
      invalidUsername: 'L\'username inserito non risulta valido',
      resetPassword: 'Errore durante la richiesta di reset della password'

    },
    months: {
      january: 'Gennaio',
      february: 'Febbraio',
      march: 'Marzo',
      april: 'Aprile',
      may: 'Maggio',
      june: 'Giugno',
      july: 'Luglio',
      august: 'Agosto',
      september: 'Settembre',
      october: 'Ottobre',
      november: 'Novembre',
      december: 'Dicembre'
    },
    constraints: {
      codice_fiscale: 'Il codice fiscale risulta duplicato',
      anno_struttura_bambino: 'Bambino già associato alla struttura per l’anno specificato',
      attributi_bambino: 'Attributo duplicato',
      foto_bambino: 'Foto già associata al bambino indicato',
      giorno_voce_calendario: 'Giorno già inserito per la voce calendario indicata',
      anno_scolastico: 'Anno scolastico già registrato',
      attributo_struttura: 'Attributo già presente per la struttura indicata',
      partita_iva: 'Partita IVA duplicata',
      ragione_sociale: 'Ragione sociale duplicata',
      username: 'Nome utente già presente',
      struttura_sezione: 'Sezione già associata alla struttura indicata',
      anno_bambino: 'Bambino già associato ad una struttura per l’anno scolastico selezionato',
      denominazione_struttura: 'Denominazione di struttura duplicato',
      targa: 'Targa già presente nel sistema',
      fermata_gita: 'Fermata già presente nel percorso'
    },
    httpCode: {
      400: {
        2: 'Errore durante la lettura o l\'interpretazione di un parametro in ingresso.',
        4: 'Errore interno del sistema',
        7: 'Impossibile eliminare o modificare',
        8: 'Violazione di univocità, campo primario duplicato',
        9: 'Violazione del controllo sull\'inserimento',
        10: 'Errore durante l\'inserimento o la modifica di un entità dovuto alla violazione di un constraint di univocità',
        11: 'Errore durante l\'inserimento o la modifica di un entità dovuto alla violazione di un constraint su una o più colonne'
      },
      403: 'Accesso negato',
      404: 'Risorsa non trovata',
      409: {
        7: 'Conflitto durante la rimozione'
      },
      413: 'Dimensione dei dati troppo grande',
      422: {
        3: 'Errore durante la comunicazione'
      },
      423: {
        1: 'La password risulta scaduta'
      },
      440: 'La sessione risulta scaduta',
      500: {
        1: 'Errore imprevisto lato server',
        4: 'Chiave non permessa',
        5: 'Spazio insufficente per aggiungere una nuova chiave',
        6: 'Chiave in fase di modifica',
        7: 'Indice chiave non corretto',
        8: 'Impossibile aggiornare le informazioni del tracker',
        9: 'Impossibile aggiornare il comando'
      },
      502: 'Errore 502, errore di comunicazione'
    }
  }
}

export default messages
