<template>
  <div class="login-image pb-5 pt-5" :style="loginImage">
    <b-container class="h-100">
      <b-row align-h="center" align-v="center" class="h-75">
        <b-button v-show="input.password === 'develop'" @click="input = {username: 'superadmin', password: 'superadmin'}">SA</b-button>

        <b-button v-show="input.password === 'develop'" @click="input = {username: 'admin_awtech', password: 'awtech2019'}">A</b-button>

        <b-col cols="12" sm="12" md="6" lg="5" xl="5">
          <b-card class="mt-4 b-c-yellow-opacity">
            <b-form id="login-form">
              <b-row align-h="center" class="mb-4">
                <i class="login-logo"/>
              </b-row>

              <b-row align-h="center">
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <!-- User -->
                  <b-form-group>
                    <b-form-input v-model="input.username"
                                  autocomplete="current-username"
                                  id="input-username"
                                  :placeholder="$tc('message.user', 1)">
                    </b-form-input>
                    <i class="fas fa-user mt-2 form-icon c-green"/>
                  </b-form-group>
                </b-col>

                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <!-- Password -->
                  <b-form-group>
                    <b-form-input v-model="input.password"
                                  autocomplete="current-password"
                                  id="input-password"
                                  type="password"
                                  :placeholder="!showNewPasswordForm ? $t('message.password') : $t('message.oldPassword')">
                    </b-form-input>
                    <i class="fas fa-lock mt-2 form-icon c-green"/>
                  </b-form-group>

                  <!-- New password -->
                  <b-form-group v-if="showNewPasswordForm">
                    <b-form-input v-model="newPassword"
                                  id="nw-new-password"
                                  type="password"
                                  :placeholder="$t('message.insertNewPassword') "
                                  maxlength="50"
                                  :state="(newPassword.length > 5 || newPassword.length === 0) && (newPassword !== input.password)">
                    </b-form-input>
                    <i class="fas fa-lock mt-2 form-icon c-green"/>
                    <b-form-invalid-feedback>
                      {{$tc('errors.newPassword', 1)}}
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <!-- Confirm new password -->
                  <b-form-group v-if="showNewPasswordForm">
                    <b-form-input v-model="confirmNewPassword"
                                  id="nw-new-password-confirm"
                                  type="password"
                                  :placeholder="$t('message.insertConfirmPassword') "
                                  maxlength="50"
                                  :state="confirmNewPassword === newPassword && input.password !== confirmNewPassword">
                    </b-form-input>
                    <i class="fas fa-lock mt-2 form-icon c-green"/>
                    <b-form-invalid-feedback id="child-passwordCheck-invalid">
                      {{$tc('errors.newPasswordConfirm', 1)}}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

              </b-row>

              <b-row class="mt-2">
                <b-col cols="6">
                  <b-button id="nw-change-password"
                            class="w-100 b-c-green"
                            v-if="!logged"
                            :disabled="checkLoginButton()"
                            v-on:click="setLocalStorage(true)"
                            type="button"> {{$tc('message.changePassword', 1)}}
                  </b-button>
                </b-col>
                <b-col cols="6">
                  <b-button id="login-button" v-on:click="setLocalStorage()" class="w-100 b-c-green" v-if="(!logged && !showNewPasswordForm)" :disabled="checkLoginButton()">
                    {{$tc('message.login', 1)}}
                  </b-button>
                </b-col>
                <b-col cols="6">
                  <b-button id="nw-back"
                            class="w-100 b-c-green"
                            v-if="logged"
                            type="button"
                            v-on:click="resetLogin()"> {{$tc('message.back', 1)}}
                  </b-button>
                </b-col>
                <b-col cols="6">
                  <b-button id="nw-change-password-reset"
                            class="w-100 b-c-green"
                            v-if="showNewPasswordForm"
                            type="button"
                            v-on:click="resetPassword()"
                            :disabled="newPassword !== confirmNewPassword || !confirmNewPassword || !input.username || !input.password || input.password === newPassword || input.password === confirmNewPassword"> {{$tc('message.login', 1)}}
                  </b-button>
                </b-col>
              </b-row>

              <b-row align-h="center" class="mt-3">
                <h6 :class="input.username ? 'bbb-c-turquoise clickable' : 'myDisabled'"
                    @click="input.username ? openForgottenPasswordModal() : null">
                  {{$tc('message.forgottenPassword', 1)}}
                </h6>
              </b-row>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <b-modal ref="forgottenPasswordModal" id="forgotten-password-modal" :title="$tc('message.confirmUser', 1)">
      <b-container>
        <b-row align-h="center">
          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <b-form>
              <!-- Username -->
              <b-form-group horizontal
                            :label-cols="4"
                            label="Username:"
                            label-class="text-lg-right"
                            label-for="input-username">
                <b-form-input v-model="userToReset"
                              :disabled="passwordAttempts >= maxAttempts"
                              autocomplete="username"
                              id="username"
                              name="input.username"
                              type="text"
                              :placeholder="$tc('message.user', 1)">
                </b-form-input>
              </b-form-group>
            </b-form>
          </b-col>
        </b-row>
      </b-container>

      <div slot="modal-footer" class="w-100">
        <b-button id="delete-cancel"
                  type="button"
                  class="float-right ml-3 mt-2 b-c-green"
                  @click="hideForgottenPasswordModal()"> {{$tc('message.close', 1)}}
        </b-button>

        <b-button id="delete-continue"
                  type="button"
                  class="float-right ml-3 mt-2 b-c-green"
                  :disabled="!input.username || passwordAttempts >= maxAttempts"
                  v-on:click="checkUser()"> {{$tc('message.confirm', 1)}} {{passwordAttempts >= maxAttempts ? `(${countDown})` : ''}}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import _ from 'lodash'
import { DateTime } from 'luxon'

export default {
  name: 'Login',
  mounted () {
    this.getLoginImage()
    this.$root.$on('resize', () => {
      this.getLoginImage()
    })
    if (Vue.getLocalStorage('expPasswordAttempt')) {
      this.passwordAttempts = this.maxAttempts
      this.checkCountdown()
    }
  },
  data () {
    return {
      loginImage: '',
      input: {},
      showNewPasswordForm: false,
      newPassword: '',
      confirmNewPassword: '',
      logged: false,
      userToReset: null,
      passwordAttempts: 0,
      countDown: 30,
      maxAttempts: 3
    }
  },
  methods: {
    getLoginImage () {
      const size = Vue.myGetWindowSize()
      let backGround = ''

      switch (size) {
        case 'cols':
          backGround = 'background: url("/img/bbbus-login-720x1280.jpg")'
          break

        case 'sm':
          backGround = 'background: url("/img/bbbus-login-720x1280.jpg")'
          break

        case 'md':
          backGround = 'background: url("/img/bbbus-login-1024x683.jpg")'
          break

        default:
          backGround = 'background: url("/img/bbbus-login-1920x1280.jpg")'
          break
      }
      this.loginImage = backGround
    },
    checkLoginButton () {
      return !this.input.username || !this.input.password
    },
    async setLocalStorage (changePassword) {
      await Vue.myLogin(this, this.input.username, this.input.password).then(r => {
        const roleId = r && r.data && r.data.utente && r.data.utente.ruolo && r.data.utente.ruolo.id
        this.logged = true
        if (changePassword) {
          this.showNewPasswordForm = true
        } else {
          this.logIn(roleId)
        }
      }).catch((error) => {
        console.log(error)
        if (error.response.status === 423) {
          this.showNewPasswordForm = true
          this.logged = true
        }
        Vue.manageErrors(this, error)
      })
    },
    logIn (roleId) {
      switch (roleId) {
        case 1: // super admin role Id
          this.$router.push('/superadmin').catch(() => {})
          Vue.success(this, `${this.$tc('message.login')} ${this.$tc('message.done', 1).toLowerCase()}`)
          break

        case 2: // admin role Id
          this.$router.push('/admin').catch(() => {})
          break

        default:
          Vue.error(this, this.$tc('warnings.roleNotEnabled'))
          Vue.deleteLocalStorage('session')
          Vue.deleteLocalStorage('structure')
          break
      }
    },
    resetLogin () {
      this.input = {
        username: null,
        password: null
      }
      this.logged = false
      this.showNewPasswordForm = false
      this.newPassword = ''
      this.confirmNewPassword = ''
    },
    resetPassword () {
      axios.patch(`${Vue.getBaseUrl()}/cambio_password`, { password: this.newPassword }, {
        auth: {
          username: this.input.username,
          password: this.input.password
        }
      }).then((r) => {
        this.showNewPasswordForm = false
        this.newPassword = null
        this.confirmNewPassword = null
        Vue.success(this, this.$tc('success.passwordChanged'))

        const session = r.data
        Vue.setLocalStorage('session', session)
        this.logged = true

        const roleId = r && r.data && r.data.utente && r.data.utente.ruolo && r.data.utente.ruolo.id
        this.logIn(roleId)
        this.$root.$emit('logged', true)
      }).catch((error) => {
        Vue.manageErrors(this, error)
        if (error.response.status === 403) {
          this.resetLogin()
        } else if (error.response.status === 440) {
          this.$root.$emit('sessionExpired', this.$tc('errors.sessionExpired'))
        }
      })
    },
    openForgottenPasswordModal () {
      this.userToReset = _.cloneDeep(this.input.username)
      this.$refs.forgottenPasswordModal.show()
    },
    checkCountdown () {
      setTimeout(() => {
        const now = DateTime.local().ts
        const expTimestamp = Vue.getLocalStorage('expPasswordAttempt') || now
        const checkTs = parseInt(expTimestamp, 10)
        const secLeft = parseInt((checkTs - now) / 1000, 10)

        if (secLeft > 0) {
          this.countDown = secLeft
          this.checkCountdown()
        } else {
          this.countDown = 30
          this.passwordAttempts = 0
          Vue.deleteLocalStorage('expPasswordAttempt')
        }
      }, 995)
    },
    hideForgottenPasswordModal () {
      this.$refs.forgottenPasswordModal.hide()
    },
    checkUser () {
      this.passwordAttempts += 1
      Vue.patchOrPost(false, '/reset_password', { utente: this.userToReset }).then(() => {
        Vue.success(this, this.$tc('success.resetPassword'))

        if (this.passwordAttempts >= this.maxAttempts) {
          Vue.error(this, this.$tc('errors.muchReset'))
          this.startDelayCounter(30)
        } else {
          this.hideForgottenPasswordModal()
        }
      }).catch((error) => {
        if (error && error.response &&
            error.response.status === 404 &&
            error.response.data.code === 5) {
          Vue.error(this, this.$tc('errors.invalidUsername'))
        } else {
          Vue.error(this, this.$tc('errors.resetPassword'))
        }

        if (this.passwordAttempts >= this.maxAttempts) {
          Vue.error(this, this.$tc('errors.muchReset'))
          this.startDelayCounter(30)
        }
      })
    },
    startDelayCounter (sec) {
      Vue.setLocalStorage('expPasswordAttempt', DateTime.local().plus({ seconds: sec }).ts)

      this.checkCountdown()
    }
  }
}
</script>

<style scoped>
.login-logo {
  height: calc(100px + 5 * ((90vw - 100px) / 100));
  width: calc(100px + 5 * ((90vw - 100px) / 100));
  background: url("../../public/logo.svg") no-repeat center;
  background-size: cover;
  position: relative;
}

.login-logo::before{
  left: 18px;
  top: 10px;
  position: relative;
}

.form-icon {
  position: absolute;
  top: 2px;
  left: 28px;
}

#input-username, #input-password {
  padding-left: 40px;
}

.card {
  background: rgba(255, 255, 255, 0.6);
}

.bbb-c-turquoise { color: #007A79; }
a:link, .clickable {
  cursor: pointer !important;
}
</style>
