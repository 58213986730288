<template>
  <div class="d-flex">
    <b-container class="m-auto">
      <b-row align-h="center" align-v="center">
        <h1><i :class="!isMobile ? 'fas fa-exclamation-triangle c-green fa-3x' : 'fas fa-exclamation-triangle c-green'"/></h1>
      </b-row>
      <b-row align-h="center" align-v="center">
        <h5 v-if="isMobile">{{$t('message.page404')}}</h5>
        <h1 v-else>{{$t('message.page404')}}</h1>
      </b-row>
      <b-row align-h="center" align-v="center">
        <h1>4 0 4</h1>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import Vue from 'vue'

  export default {
    name: 'Page404',
    mounted () {
      this.checkWindowWidth()
      this.$root.$on('resize', () => this.checkWindowWidth())
    },
    data () {
      return {
        isMobile: false
      }
    },
    methods: {
      checkWindowWidth () {
        const size = Vue.myGetWindowSize()
        this.isMobile = (size !== 'md' && size !== 'lg' && size !== 'xl')
      }
    }
  }
</script>

<style scoped>

</style>
