<template>
    <div></div>
</template>

<script>
  import Vue from 'vue'
  export default {
    name: 'Logout',
    created () {
      Vue.deleteLocalStorage('session')
      Vue.deleteLocalStorage('year')
      Vue.deleteLocalStorage('structure')
      Vue.deleteLocalStorage('trip-manager')
      this.$router.push('/').catch(() => {})
    }
  }
</script>

<style scoped>

</style>
