<template>
  <div class="column-left mr-2">
    <form class="mb-4">
      <b-row>
        <b-form-group class="mb-1"
                      :label="`${$tc('message.management', 1)} ${$tc('message.child', 1).toLowerCase()}`"
        />
      </b-row>
      <b-row class="distance-content content-margins">
        <b-form-checkbox
            :checked="childCreate && childRead"
            :disabled="!childRead"
            @change="$emit('updatePermission', maskValue('CHILD', 'CREATE'))">
          <i class="fa fa-plus-circle crud-icon"/>
        </b-form-checkbox>
        <b-form-checkbox
            :checked="childRead"
            @change="$emit('updatePermission', maskValue('CHILD', 'READ'))">
          <i class="fa fa-search crud-icon"/>
        </b-form-checkbox>
        <b-form-checkbox
            :checked="childUpdate && childRead"
            :disabled="!childRead"
            @change="$emit('updatePermission', maskValue('CHILD', 'UPDATE'))">
          <i class="fa fa-edit crud-icon"/>
        </b-form-checkbox>
        <!-- <b-form-checkbox
            :checked="childDelete && childRead"
            :disabled="!childRead"
            @change="$emit('updatePermission', maskValue('CHILD', 'DELETE'))">
          <i class="fa fa-trash crud-icon"/>
        </b-form-checkbox> -->
      </b-row>
    </form>
    <form class="mb-4">
      <b-row>
        <b-form-group class="mb-1"
                      :label="`${$tc('message.management', 1)} ${$tc('message.trip', 1).toLowerCase()}`"
        />
      </b-row>
      <b-row class="distance-content">
        <b-form-checkbox
            :checked="tripCreate && tripRead"
            :disabled="!tripRead"
            @change="$emit('updatePermission', maskValue('TRIP', 'CREATE'))">
          <i class="fa fa-plus-circle crud-icon"/>
        </b-form-checkbox>
        <b-form-checkbox
            :checked="tripRead"
            @change="$emit('updatePermission', maskValue('TRIP', 'READ'))">
          <i class="fa fa-search crud-icon"/>
        </b-form-checkbox>
        <b-form-checkbox
            :checked="tripUpdate && tripRead"
            :disabled="!tripRead"
            @change="$emit('updatePermission', maskValue('TRIP', 'UPDATE'))">
          <i class="fa fa-edit crud-icon"/>
        </b-form-checkbox>
        <b-form-checkbox
            :checked="tripDelete && tripRead"
            :disabled="!tripRead"
            @change="$emit('updatePermission', maskValue('TRIP', 'DELETE'))">
          <i class="fa fa-trash crud-icon"/>
        </b-form-checkbox>
      </b-row>
    </form>
    <form class="mb-4">
      <b-row>
        <b-form-group class="mb-1"
                      :label="`${$tc('message.management', 1)} ${$tc('message.stop', 1).toLowerCase()}`"
        />
      </b-row>
      <b-row class="distance-content">
        <b-form-checkbox
            :checked="stopCreate && stopRead"
            :disabled="!stopRead"
            @change="$emit('updatePermission', maskValue('STOP', 'CREATE'))">
          <i class="fa fa-plus-circle crud-icon"/>
        </b-form-checkbox>
        <b-form-checkbox
            :checked="stopRead"
            @change="$emit('updatePermission', maskValue('STOP', 'READ'))">
          <i class="fa fa-search crud-icon"/>
        </b-form-checkbox>
        <b-form-checkbox
            :checked="stopUpdate && stopRead"
            :disabled="!stopRead"
            @change="$emit('updatePermission', maskValue('STOP', 'UPDATE'))">
          <i class="fa fa-edit crud-icon"/>
        </b-form-checkbox>
        <b-form-checkbox
            :checked="stopDelete && stopRead"
            :disabled="!stopRead"
            @change="$emit('updatePermission', maskValue('STOP', 'DELETE'))">
          <i class="fa fa-trash crud-icon"/>
        </b-form-checkbox>
      </b-row>
    </form>

    <form class="mb-4">
      <b-row>
        <b-form-group class="mb-1"
                      :label="`${$tc('message.management', 1)} ${$tc('message.associate', 1).toLowerCase()} ${$tc('message.child', 1).toLowerCase()}`"
        />
      </b-row>
      <b-row class="distance-content">
        <b-form-checkbox
            :checked="associationChildTripCreate && associationChildTripRead"
            :disabled="!associationChildTripRead"
            @change="$emit('updatePermission', maskValue('ASSOCIATION_CHILD_TRIP', 'CREATE'))">
          <i class="fa fa-plus-circle crud-icon"/>
        </b-form-checkbox>
        <b-form-checkbox
            :checked="associationChildTripRead"
            @change="$emit('updatePermission', maskValue('ASSOCIATION_CHILD_TRIP', 'READ'))">
          <i class="fa fa-search crud-icon"/>
        </b-form-checkbox>
        <b-form-checkbox
            :checked="associationChildTripUpdate && associationChildTripRead"
            :disabled="!associationChildTripRead"
            @change="$emit('updatePermission', maskValue('ASSOCIATION_CHILD_TRIP', 'UPDATE'))">
          <i class="fa fa-edit crud-icon"/>
        </b-form-checkbox>
        <b-form-checkbox
            :checked="associationChildTripDelete && associationChildTripRead"
            :disabled="!associationChildTripRead"
            @change="$emit('updatePermission', maskValue('ASSOCIATION_CHILD_TRIP', 'DELETE'))">
          <i class="fa fa-trash crud-icon"/>
        </b-form-checkbox>
      </b-row>
    </form>

    <form class="mb-4">
      <b-row>
        <b-form-group class="mb-1"
                      :label="`${$tc('message.management', 1)} ${$tc('message.associate', 1).toLowerCase()} ${$tc('message.operator', 1).toLowerCase()}`"
        />
      </b-row>
      <b-row class="distance-content">
        <b-form-checkbox
            :checked="associationOperatorTripCreate && associationOperatorTripRead"
            :disabled="!associationOperatorTripRead"
            @change="$emit('updatePermission', maskValue('ASSOCIATION_OPERATOR_TRIP', 'CREATE'))">
          <i class="fa fa-plus-circle crud-icon"/>
        </b-form-checkbox>
        <b-form-checkbox
            :checked="associationOperatorTripRead"
            @change="$emit('updatePermission', maskValue('ASSOCIATION_OPERATOR_TRIP', 'READ'))">
          <i class="fa fa-search crud-icon"/>
        </b-form-checkbox>
        <b-form-checkbox
            :checked="associationOperatorTripUpdate && associationOperatorTripRead"
            :disabled="!associationOperatorTripRead"
            @change="$emit('updatePermission', maskValue('ASSOCIATION_OPERATOR_TRIP', 'UPDATE'))">
          <i class="fa fa-edit crud-icon"/>
        </b-form-checkbox>
        <b-form-checkbox
            :checked="associationOperatorTripDelete && associationOperatorTripRead"
            :disabled="!associationOperatorTripRead"
            @change="$emit('updatePermission', maskValue('ASSOCIATION_OPERATOR_TRIP', 'DELETE'))">
          <i class="fa fa-trash crud-icon"/>
        </b-form-checkbox>
      </b-row>
    </form>

    <form class="mb-4">
      <b-row>
        <b-form-group class="mb-1"
                      :label="`${$tc('message.management', 1)} ${$tc('message.attribute', 2).toLowerCase()} `"
        />
      </b-row>
      <b-row class="distance-content">
        <b-form-checkbox
            :checked="attributeCreate && attributeRead"
            :disabled="!attributeRead"
            @change="$emit('updatePermission', maskValue('ATTRIBUTE', 'CREATE'))">
          <i class="fa fa-plus-circle crud-icon"/>
        </b-form-checkbox>
        <b-form-checkbox
            :checked="attributeRead"
            @change="$emit('updatePermission', maskValue('ATTRIBUTE', 'READ'))">
          <i class="fa fa-search crud-icon"/>
        </b-form-checkbox>
        <b-form-checkbox
            :checked="attributeUpdate && attributeRead"
            :disabled="!attributeRead"
            @change="$emit('updatePermission', maskValue('ATTRIBUTE', 'UPDATE'))">
          <i class="fa fa-edit crud-icon"/>
        </b-form-checkbox>
        <b-form-checkbox
            :checked="attributeDelete && attributeRead"
            :disabled="!attributeRead"
            @change="$emit('updatePermission', maskValue('ATTRIBUTE', 'DELETE'))">
          <i class="fa fa-trash crud-icon"/>
        </b-form-checkbox>
      </b-row>
    </form>

    <form class="mb-4">
      <b-row>
        <b-form-group class="mb-1"
                      :label="`${$tc('message.management', 1)} ${$tc('message.deposit', 2).toLowerCase()} `"
        />
      </b-row>
      <b-row class="distance-content">
        <b-form-checkbox
            :checked="depositCreate && depositRead"
            :disabled="!depositRead"
            @change="$emit('updatePermission', maskValue('DEPOSIT', 'CREATE'))">
          <i class="fa fa-plus-circle crud-icon"/>
        </b-form-checkbox>
        <b-form-checkbox
            :checked="depositRead"
            @change="$emit('updatePermission', maskValue('DEPOSIT', 'READ'))">
          <i class="fa fa-search crud-icon"/>
        </b-form-checkbox>
        <b-form-checkbox
            :checked="depositUpdate && depositRead"
            :disabled="!depositRead"
            @change="$emit('updatePermission', maskValue('DEPOSIT', 'UPDATE'))">
          <i class="fa fa-edit crud-icon"/>
        </b-form-checkbox>
        <b-form-checkbox
            :checked="depositDelete && depositRead"
            :disabled="!depositRead"
            @change="$emit('updatePermission', maskValue('DEPOSIT', 'DELETE'))">
          <i class="fa fa-trash crud-icon"/>
        </b-form-checkbox>
      </b-row>
    </form>
  </div>
</template>

<script>
export default {
  name: 'PermissionMask',
  props: [
    'childRead',
    'childCreate',
    'childUpdate',
    'childDelete',
    'tripCreate',
    'tripRead',
    'tripUpdate',
    'tripDelete',
    'stopRead',
    'stopCreate',
    'stopUpdate',
    'stopDelete',
    'associationOperatorTripCreate',
    'associationOperatorTripRead',
    'associationOperatorTripUpdate',
    'associationOperatorTripDelete',
    'associationChildTripCreate',
    'associationChildTripRead',
    'associationChildTripUpdate',
    'associationChildTripDelete',
    'attributeCreate',
    'attributeRead',
    'attributeUpdate',
    'attributeDelete',
    'depositCreate',
    'depositRead',
    'depositUpdate',
    'depositDelete'
  ]
}
</script>

<style scoped>
.distance-content {
  justify-content: space-between;
}

.content-margins {
  padding-right: 33%;
}

</style>
