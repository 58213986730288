<template>
    <b-container class="mw-100 child">
        <b-card class="mt-4 mb-4">
            <standard-table ref="mainTable"
                            :items="items"
                            :fields="fields"
                            :theadTrClass="session && session.utente && session.utente.auth_mask && session.utente.auth_mask[maskValue('CHILD', 'UPDATE')] === '1' ? 'first-2-table-cols' : 'first-table-cols'"

                            :before-search="{cols: 12,sm: 12,md: 12,lg: 12,xl: 12}"

                            :row-before-search="{cols: 12,sm: 12,md: 12,lg: 4,xl: 4,order: 1,orderSm: 1,orderMd: 1,orderLg: 3,orderXl: 3}"
                            :row-search="{cols: 12,sm: 12,md: 12,lg: 4,xl: 4,order: 1,orderSm: 2,orderMd: 2,orderLg: 1,orderXl: 1}"
                            :row-after-search="{cols: 12, sm: 12, md: 12, lg: 4, xl: 4, order: 2, orderSm: 3, orderMd: 3, orderLg: 2, orderXl: 2}"

                            :row-pagination="{cols: 12, sm: 12, md: 6, lg: 5, xl: 5}"
                            :row-between-pagination="{cols: 12, sm: 12, md: 1, lg: 1, xl: 4}"
                            :row-per-page="{cols: 12, sm: 12, md: 5, lg: 3, xl: 2}"
                            :row-after-per-page="{cols: 12, sm: 12, md: 12, lg: 3, xl: 1}"

                            fullWidth="true"
                            noContainerPadding="true"
                            @rowInfo="showRowInfo"
                            @rowEdit="pushToEdit"
            >
                <b-container slot="before-search" class="mw-100 p-0 mb-2" v-if="session && session.utente && session.utente.auth_mask && session.utente.auth_mask[maskValue('CHILD', 'CREATE')] === '1'">
                    <b-row align-h="end">
                        <b-col cols="auto">
                            <b-button @click="showImportModal">
                                {{$tc('message.importChildren', 1)}}
                            </b-button>
                        </b-col>

                        <b-col cols="auto">
                            <b-button @click="showExportModal()">
                                {{$tc('message.exportChildren', 1)}}
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>

                <b-container slot="row-before-search" class="mw-100 p-0" v-if="session && session.utente && session.utente.auth_mask && session.utente.auth_mask[maskValue('CHILD', 'CREATE')] === '1'">
                    <b-row align-h="end">
                        <b-col cols="auto">
                            <b-button v-on:click="pushToEdit()" class="mb-3 b-c-blue" id="add-child">
                                {{$t('message.add')}}
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>

                <b-container slot="row-after-per-page" class="mw-100 p-0">
                    <b-row>
                        <b-col>
                            <b-button class="float-right mt-3 mt-md-0 mt-lg-0 mt-xl-0" id="excel-button" @click="$refs.mainTable.excel($tc('message.child', 2), getFields())">Excel</b-button>
                        </b-col>
                    </b-row>
                </b-container>

                <b-container slot="row-after-search" class="mw-100 p-0">
                    <b-row>
                        <b-col>
                            <b-button v-on:click="resetFilters()" class="b-c-green mb-2 mr-2" id="reset-filters">
                                {{$t('message.resetFilters')}}
                            </b-button>

                            <b-button @click="showCollapse = !showCollapse" :class="showCollapse ? 'b-c-red mb-2 mr-2' : 'b-c-green mb-2 mr-2'" id="show-filters">
                                <span v-show="!showCollapse" class="when-opened">{{$t('message.show')}} {{$tc('message.filter', 2).toLowerCase()}}</span>
                                <span v-show="showCollapse" class="when-closed">{{$t('message.hide')}} {{$tc('message.filter', 2).toLowerCase()}}</span>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>

                <b-container slot="after-search" class="mw-100 p-0">
                    <b-collapse id="my-collapse" v-model="showCollapse">
                        <b-container class="mw-100 m-0 p-0">
                            <b-row>
                                <b-col cols="12" sm="12" md="6" lg="4" xl="4">
                                    <!-- Structure -->
                                    <b-form-group :label="`${$tc('message.structure', 1)}`">
                                        <v-select :options="structureOptions"
                                                  :reduce="obj => obj.id"
                                                  label="den_sintetica"
                                                  v-model="structureFilter">
                                        </v-select>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" sm="12" md="6" lg="4" xl="4">
                                    <b-form-group :label="`${$tc('message.status', 1)}`">
                                          <v-select :options="childStatusOptions"
                                                    :reduce="obj => obj.value"
                                                    label="text"
                                                    placeholder="Seleziona"
                                                    v-model="dischargeFilter">
                                          </v-select>
                                    </b-form-group>
                                </b-col>

                              <b-col cols="12" sm="12" md="6" lg="4" xl="4">
                                <b-form-group :label="`${$tc('message.attribute', 2)}`">
                                  <v-select :options="attributeOptions"
                                            :reduce="obj => obj.id"
                                            label="nome"
                                            placeholder="Seleziona"
                                            v-model="attributeFilter">
                                  </v-select>
                                </b-form-group>
                              </b-col>

                              <b-col class="mt-1" cols="12" sm="12" md="6" lg="4" xl="4">
                                <b-form-group :label="`${$tc('message.accompanied', 1)}`">
                                  <v-select :options="accompaniedOptions"
                                            :reduce="obj => obj.value"
                                            label="text"
                                            placeholder="Seleziona"
                                            v-model="accompaniedFilter">
                                  </v-select>
                                </b-form-group>
                              </b-col>
                            </b-row>
                        </b-container>
                    </b-collapse>
                </b-container>

            </standard-table>
        </b-card>

        <b-modal ref="infoModal" id="infoModal" :title="`${$tc('message.details', 1)}`" size="lg">
            <b-container>
                <b-row>
                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.name', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.nome || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.surname', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.cognome || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.taxCode', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.codice_fiscale || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.birthDate', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.data_nascita || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.commonResidence', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.comune_residenza || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.provinceResidence', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.provincia_residenza || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.residenceAddress', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.indirizzo_residenza || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.parent1', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.genitore_1 || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.firstTaxCode', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.codice_fiscale_r1 || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.phone', 1)}} 1</b></h6>
                            <h6 class="ml-2">{{infoItem.telefono_principale || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.email', 1)}} 1</b></h6>
                            <h6 class="ml-2">{{infoItem.email_ufficiale || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.parent2', 1)}} 1</b></h6>
                            <h6 class="ml-2">{{infoItem.genitore_2 || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.otherTaxCode', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.codice_fiscale_r2 || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.phone', 1)}} 2</b></h6>
                            <h6 class="ml-2">{{infoItem.telefono_secondario || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.email', 1)}} 2</b></h6>
                            <h6 class="ml-2">{{infoItem.email_secondaria || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.dateStart', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.startDate || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.discharge', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.endDate || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.structure', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.structure || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                  <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <div class="d-flex">
                      <h6><b>{{$tc('message.specialEquipment', 1)}}</b></h6>
                      <h6 class="ml-2">{{infoItem.dotazioni_speciali ? 'Sì' : 'No' }}</h6>
                    </div>
                  </b-col>

                  <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <div class="d-flex">
                      <h6><b>{{$tc('message.attribute', 2)}}</b></h6>
                      <h6 v-if="infoItem.attributi && infoItem.attributi.length === 0">{{$tc('message.emptyValue')}}</h6>
                      <h6 v-for="attribute in infoItem.attributi" :key="attribute.id" class="ml-2">{{ attribute.nome }}</h6>
                    </div>
                  </b-col>

                  <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <div class="d-flex">
                      <h6><b>{{$tc('message.accompanied', 1)}}</b></h6>
                      <h6 class="ml-2">{{infoItem.accompagnato ? 'Sì' : 'No' }}</h6>
                    </div>
                  </b-col>
                </b-row>
            </b-container>

            <div slot="modal-footer" class="w-100 justify-content-end d-flex">
                <b-button class="b-c-green" @click="$refs.infoModal.hide()"> Ok </b-button>
            </div>
        </b-modal>

        <b-modal ref="exportModal" id="exportModal" :title="`${$tc('message.exportChildren', 1)}`" size="lg"
                 no-close-on-backdrop no-close-on-esc hide-header-close
                 @hide="checkExportModalClosing">
            <standard-table ref="exportTable"
                            :theadTrClass="'first-table-cols'"
                            :items="exportItems"
                            :fields="getFields(exportModalFields)"
                            :disableAutoUnSelection="true"

                            :before-search="{cols: 12,sm: 12,md: 12,lg: 12,xl: 12}"

                            :row-search="{cols: 12,sm: 12,md: 12,lg: 6,xl: 6}"
                            :row-after-search="{cols: 12, sm: 12, md: 12, lg: 6, xl: 6}"

                            :row-pagination="{cols: 12, sm: 12, md: 6, lg: 5, xl: 5}"
                            :row-between-pagination="{cols: 12, sm: 12, md: 1, lg: 4, xl: 4}"
                            :row-per-page="{cols: 12, sm: 12, md: 5, lg: 3, xl: 3}"
                            @rowSelected="handleExportSelection"
                            @allSelected="handleExportSelection"

                            fullWidth="true"
                            noContainerPadding="true"
                            @mounted="updateExportItems">
            </standard-table>

            <div slot="modal-footer" class="w-100 justify-content-end d-flex">
                <b-button type="button"
                          class="m-1"
                          @click="exportChildren(exportItems)"> {{$tc('message.export', 1)}}
                </b-button>

                <b-button type="button"
                          class="m-1"
                          @click="$refs.exportModal.hide('button')"> {{$tc('message.close', 1)}}
                </b-button>
            </div>
        </b-modal>

        <b-modal ref="importModal" id="importModal" :title="`${$tc('message.importChildren', 1)}`">
            <b-container>
                <b-row>
                    <b-col>
                        <!-- Document -->
                        <b-form-group label="Excel:">
                            <b-form-file v-model="childImportFile"
                                         id="import-children-file"
                                         ref="importChildrenFile"
                                         accept=".xlsx, .xls"
                                         v-on:input="childrenFileImported"
                                         :placeholder="$tc('message.selectFile', 1)">
                            </b-form-file>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col>
                        <h6 class="text-danger">{{$tc('warnings.editStructureChildImport', 1)}}</h6>
                    </b-col>
                </b-row>
            </b-container>

            <div slot="modal-footer" class="w-100 justify-content-end d-flex">
                <b-button class="b-c-green m-1"
                          v-on:click="startImport(childImportFile)"
                          :disabled="!enableImport || !childImportFile"> {{$t('message.import')}}
                </b-button>

                <b-button type="button"
                          class="m-1"
                          href="./ModelloImport.xlsx"
                          :target="'_blanck'" download> {{$tc('message.modelDownload', 1)}}
                </b-button>

                <b-button type="button"
                          class="m-1"
                          @click="$refs.importModal.hide()"> {{$tc('message.close', 1)}}
                </b-button>
            </div>
        </b-modal>

        <b-modal ref="importModalErrors" id="importModalErrors" :title="`${$t('message.caution')}!`"
                 @shown="errorsAreShown" size="lg"
                 no-close-on-backdrop no-close-on-esc hide-header-close>
            <b-container fluid>
                <b-row>
                    <b-col>
                        <h6 class="text-danger">{{$tc('warnings.editStructureChildImport', 1)}}</h6>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col>
                        <h4>{{$t('errors.errorsList')}}:</h4>
                    </b-col>
                </b-row>

                <b-row>
                    <b-container>
                        <b-list-group>
                            <b-list-group-item v-for="(item, index) in importErrors" :key="index">
                                <b-row>
                                    <b-col cols="12" sm="12" v-if="item.child">
                                        <h6 class="c-red font-weight-bold">{{$tc('message.row', 1)}}: {{item.child.index}}</h6>
                                        <h6 class="c-red font-weight-bold">{{item.child.cognome}} {{item.child.nome}} {{item.child.codice_fiscale ? `- ${item.child.codice_fiscale}` : ''}}</h6>
                                        <h6 class="c-red font-weight-bold">{{item.child.structureText ? `- ${item.child.structureText}` : ''}}</h6>
                                    </b-col>

                                    <b-col cols="12" sm="12">
                                        <div v-for="message in item.errors" :key="message">
                                            <h6 class="c-red font-italic">- {{message}} </h6>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-list-group-item>
                        </b-list-group>
                    </b-container>
                </b-row>
            </b-container>

            <div slot="modal-footer" class="w-100 justify-content-end d-flex">
                <b-button id="nw-ok-error-message" class="m-1 b-c-green" @click="hideMainErrorMessageModal()">Ok</b-button>
            </div>
        </b-modal>
    </b-container>
</template>

<script>
  import Vue from 'vue'
  import { DateTime } from 'luxon'
  import _, { isNil } from 'lodash'
  import axios from 'axios'

  export default {
    created () {
      this.childStatusOptions = [
        { text: this.$tc('message.active', 1), value: true },
        { text: this.$tc('message.notActive', 1), value: false },
        { text: this.$tc('message.all', 1), value: null }
      ]
      this.accompaniedOptions = [
        { text: this.$tc('message.accompanied', 1), value: true },
        { text: this.$tc('message.not', 1) + ' ' + this.$tc('message.accompanied', 1), value: false },
        { text: this.$tc('message.all', 1), value: null }
      ]
      this.fetchData()
    },
    watch: {
      structureFilter () {
        this.getChild()
      },
      dischargeFilter () {
        this.getChild()
      },
      attributeFilter () {
        this.getChild()
      },
      accompaniedFilter () {
        this.getChild()
      }
    },
    name: 'Child',
    data () {
      const session = Vue.getLocalStorage('session')
      return {
        session,
        originalItems: [],
        items: [],
        fields: [],
        mainTableCheckSizeFields: ['data_nascita', 'genitore_1', 'startDate', 'endDate'],
        mainTableFields: ['info', 'edit', 'cognome', 'nome', 'telefono_principale', 'structure'],
        exportModalFields: ['select', 'cognome', 'nome', 'structure'],
        showCollapse: false,
        dischargeFilter: true,
        infoItem: {},
        structureOptions: [],
        structureFilter: null,
        exportItems: [],
        childImportFile: null,
        enableImport: false,
        importErrors: [],
        childStatusOptions: [],
        accompaniedOptions: [],
        attributeOptions: [],
        attributeFilter: null,
        accompaniedFilter: null
      }
    },
    methods: {
      fetchData () {
        if (this.session && this.session.utente && this.session.utente.auth_mask && this.session.utente.auth_mask[this.maskValue('CHILD', 'UPDATE')] !== '1') {
          this.mainTableFields = ['info', 'cognome', 'nome', 'telefono_principale', 'structure']
        }
        this.$root.$emit('activeLoader', true)
        this.getOptions().then(() => {
          this.getChild().then(() => {
            this.$root.$emit('activeLoader', false)
          })
        })
      },
      getOptions () {
        const axiosArray = []
        this.checkFields()
        axiosArray.push(this.getStructures())
        axiosArray.push(this.getAttributes())
        return Vue.myAll(axiosArray)
      },
      getAttributes () {
        return Vue.myGet(this, '/attributo', 'attributi').then(attributes => {
          this.attributeOptions = _.orderBy(attributes, 'nome')
          return true
        }).catch(error => {
          Vue.manageErrors(this, error)
          return true
        })
      },
      getStructures () {
        return Vue.myGet(this, '/struttura', 'strutture').then(structures => {
          this.structureOptions = structures
          return true
        }).catch(error => {
          Vue.manageErrors(this, error)
          return true
        })
      },
      getChild () {
        const editedItems = []
        let path = '/bambino?'
        if (!isNil(this.dischargeFilter)) {
          path += 'attivi=' + this.dischargeFilter + '&'
        }
        if (!isNil(this.accompaniedFilter)) {
          path += 'accompagnato=' + this.accompaniedFilter
        }
        return Vue.myGet(this, path, 'bambini').then(children => {
            children.forEach(child => {
                const editedChild = child
              const foundedStructure = _.find(this.structureOptions, obj => obj.id === child.id_struttura)

                editedChild.id = child.id
                editedChild.data_nascita = DateTime.fromSQL(child.data_nascita).toFormat('dd/MM/yyyy')
                editedChild.startDate = child.data_iscrizione
                    ? DateTime.fromISO(child.data_iscrizione).toFormat('dd/MM/yyyy')
: null
                editedChild.endDate = child.data_cancellazione
                    ? DateTime.fromISO(child.data_cancellazione).toFormat('dd/MM/yyyy')
: null
              editedChild.structure = (foundedStructure && foundedStructure.den_sintetica) || null
              editedChild.structureId = (foundedStructure && foundedStructure.id) || null

                editedItems.push(editedChild)
            })
            this.originalItems = editedItems
            this.items = _.cloneDeep(editedItems)
          if (!isNil(this.structureFilter)) {
            this.items = _.filter(this.items, obj => obj.structureId === this.structureFilter)
          }
          if (!isNil(this.attributeFilter)) {
            this.items = _.filter(this.items, obj => !_.isNil(obj.attributi) && obj.attributi.length > 0 && !_.isNil(_.find(obj.attributi, item => item.id === this.attributeFilter)))
          }
            return true
        }).catch(error => {
          Vue.manageErrors(this, error)
          return true
        })
      },

      // table functions
      checkFields () {
        this.checkMainTableFields()
        this.$root.$on('resize', () => this.checkMainTableFields())
      },
      checkMainTableFields () {
        const size = Vue.myGetWindowSize()
        const hideBySize = size === 'sm' || size === 'md' || size === 'lg'
        const fields = !hideBySize ? _.union(this.mainTableCheckSizeFields, this.mainTableFields) : this.mainTableFields
        this.fields = this.getFields(fields, true)
      },
      getFields (fieldsToShow, hideOther) {
        const fields = [
          { key: 'select', label: '' },
          { key: 'info', label: '' },
          { key: 'edit', label: '' },
          { key: 'cognome', label: `${this.$tc('message.surname', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'nome', label: `${this.$tc('message.name', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'codice_fiscale', label: `${this.$tc('message.taxCode', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'data_nascita', label: `${this.$tc('message.birthDate', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'indirizzo_residenza', label: `${this.$tc('message.residenceAddress', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'comune_residenza', label: `${this.$tc('message.commonResidence', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'provincia_residenza', label: `${this.$tc('message.provinceResidence', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'telefono_principale', label: `${this.$tc('message.officialPhone', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'telefono_secondario', label: `${this.$tc('message.secondaryPhone', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'genitore_1', label: `${this.$tc('message.parent1', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'genitore_2', label: `${this.$tc('message.parent2', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'codice_fiscale_r1', label: `${this.$tc('message.firstTaxCode', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'codice_fiscale_r2', label: `${this.$tc('message.otherTaxCode', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'email_ufficiale', label: `${this.$tc('message.email', 1)} 1`, sortable: true, sortDirection: 'desc' },
          { key: 'email_secondaria', label: `${this.$tc('message.email', 1)} 2`, sortable: true, sortDirection: 'desc' },
          { key: 'structure', label: `${this.$tc('message.structure', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'startDate', label: `${this.$tc('message.dateStart', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'endDate', label: `${this.$tc('message.discharge', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'dotazioni_speciali', label: `${this.$tc('message.specialEquipment', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'accompagnato', label: `${this.$tc('message.accompanied', 1)}`, sortable: true, sortDirection: 'desc' }
        ]
        if (!fieldsToShow || fieldsToShow.length === 0) return fields
        const editedFields = []
        fields.forEach(field => {
          const foundedField = _.find(fieldsToShow, obj => obj === field.key)
          if (foundedField) {
            editedFields.push(field)
          } else if (hideOther) {
            const editedField = _.cloneDeep(field)
            editedField.hideInTable = true
            editedFields.push(editedField)
          }
        })
        return editedFields
      },
      showRowInfo (event) {
        this.infoItem = event && event.item
        this.$refs.infoModal.show()
      },
      pushToEdit (event) {
        const item = event && event.item
        item && item.id
? this.$router.push({
          name: 'EditChild',
          params: { id: item.id }
        })
: this.$router.push({
          name: 'EditChild',
          params: { id: 'new' }
        })
      },
      resetFilters () {
        this.structureFilter = null
        this.dischargeFilter = true
        this.$refs.mainTable.resetSearch()
      },

      // export functions
      showExportModal () {
        if (this.$refs && this.$refs.exportModal) this.$refs.exportModal.show()
      },
      checkExportModalClosing (event) {
        if (event.trigger !== 'button') {
          event.preventDefault()
          this.exportItems = []
        }
      },
      updateExportItems () {
        this.exportItems = _.cloneDeep(this.originalItems)
      },
      handleExportSelection (event) {
        const changedItems = Array.isArray(event) ? event : [event]
        changedItems.forEach(item => {
          const foundedItem = _.find(this.exportItems, obj => obj.id === item.id)
          foundedItem.selected = !item.selected
        })
      },
      exportChildren (items) {
        const editedItems = []
        items.forEach(item => {
          if (item.selected) editedItems.push(item)
        })
        Vue.excel(this.getFields(), editedItems, this.$tc('message.exportChildren', 1))
      },

      // import functions
      childrenFileImported (file) {
        if (file && file.name) {
          const xls = _.endsWith(file.name, 'xls')
          const xlsx = _.endsWith(file.name, 'xlsx')
          const ods = _.endsWith(file.name, 'ods')
          if (xls || xlsx || ods) {
            this.enableImport = true
          } else {
            Vue.warning(this, this.$tc('warnings.onlyExcel', 1))
            this.enableImport = false
            if (this.$refs.importChildrenFile) this.$refs.importChildrenFile.reset()
          }
        }
      },
      startImport (file) {
        this.$root.$emit('activeLoader', true)
        new Promise((resolve) => {
          resolve(Vue.parseExcel(file))
        }).then((result) => {
          const fieldsSeed = [
            'codice_fiscale',
            'cognome',
            'nome',
            'data_nascita',
            'indirizzo_residenza',
            'comune_residenza',
            'provincia_residenza',
            'telefono_principale',
            'genitore_1',
            'codice_fiscale_r1',
            'genitore_2',
            'codice_fiscale_r2',
            'email_ufficiale',
            'email_secondaria',
            'telefono_secondario',
            'structure',
            'startDate',
            'endDate',
              'dotazioni_speciali'
          ]
          const axiosArray = []
          const templatePage = _.first(result)
          let goAhead = true

          if (_.first(templatePage) && Object.keys(_.first(templatePage)).length !== 0) {
            const allColumns = Object.keys(_.first(templatePage))
            const fields = this.getFields(fieldsSeed, true)
            let hasCurrentLang = false
            fields.forEach(field => {
              if (!hasCurrentLang) {
                hasCurrentLang = _.find(allColumns, obj => obj === field.label || obj === field.key)
              }
            })
            goAhead = hasCurrentLang

            if (!hasCurrentLang) {
              Vue.warning(this, this.$tc('warnings.fileHasDifferentLang', 1))
            }
          } else {
            goAhead = false
            Vue.warning(this, this.$tc('warnings.fileIsEmpty', 1))
          }

          if (goAhead) {
            templatePage.forEach((item) => {
              axiosArray.push(item)
            })

            axios.all(_.map(axiosArray, child => this.checkDataToImport(child))).then((results) => {
              const errorsChildren = []
              const allChildren = []
              results.forEach((result) => {
                const child = result.child
                const errors = result.errors
                const duplicateChild = _.find(allChildren, obj => obj.codice_fiscale === child.codice_fiscale)
                const index = 2 + _.indexOf(results, result)
                const editedChild = child
                editedChild.index = index
                allChildren.push(editedChild)
                if (duplicateChild) {
                  errorsChildren.push({ errors: [`${this.$tc('message.row', 1)}: ${duplicateChild.index} ${this.$tc('errors.isADuplicateOfRow', 1).toLowerCase()}: ${editedChild.index}`] })
                }
                if (errors.length !== 0) {
                  const editedErrors = []
                  errors.forEach((error) => {
                    editedErrors.push(error)
                  })
                  errorsChildren.push({ child: editedChild, errors: editedErrors })
                }
              })
              this.closeImportModal()
              if (errorsChildren.length !== 0) {
                this.openImportModalError(errorsChildren)
              } else {
                Vue.success(this, this.$t('message.importSuccess'))
                this.$root.$emit('activeLoader', false)
                this.$router.push({ name: 'RefreshPage', params: { name: 'Child' } })
              }
            })
          } else {
            this.$root.$emit('activeLoader', false)
          }
        })
      },
      checkDataToImport (child) {
        const taxCodeRegex = /^[A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1}$/g
        // eslint-disable-next-line no-useless-escape
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const editedItem = {}
        const errors = []
        const currentYear = DateTime.local().year
        const minDate = DateTime.fromSQL(`${currentYear}-09-01`)
        const dateFormat = {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        }
        const taxCode = child[_.first(this.getFields(['codice_fiscale'])).label] || child.codice_fiscale
        const surname = child[_.first(this.getFields(['cognome'])).label] || child.cognome
        const name = child[_.first(this.getFields(['nome'])).label] || child.nome
        const birthDate = child[_.first(this.getFields(['data_nascita'])).label] || child.data_nascita
        const residenceAddress = child[_.first(this.getFields(['indirizzo_residenza'])).label] || child.indirizzo_residenza
        const residenceCommon = child[_.first(this.getFields(['comune_residenza'])).label] || child.comune_residenza
        const residenceProvince = child[_.first(this.getFields(['provincia_residenza'])).label] || child.provincia_residenza
        const mainPhone = child[_.first(this.getFields(['telefono_principale'])).label] || child.telefono_principale
        const parent1 = child[_.first(this.getFields(['genitore_1'])).label] || child.genitore_1
        const taxCodeParent1 = child[_.first(this.getFields(['codice_fiscale_r1'])).label] || child.codice_fiscale_r1
        const parent2 = child[_.first(this.getFields(['genitore_2'])).label] || child.genitore_2
        const taxCodeParent2 = child[_.first(this.getFields(['codice_fiscale_r2'])).label] || child.codice_fiscale_r2
        const mainEmail = child[_.first(this.getFields(['email_ufficiale'])).label] || child.email_ufficiale
        const secondaryEmail = child[_.first(this.getFields(['email_secondaria'])).label] || child.email_secondaria
        const secondaryPhone = child[_.first(this.getFields(['telefono_secondario'])).label] || child.telefono_secondario
        const structure = child[_.first(this.getFields(['structure'])).label] || child.id_struttura
        const registrationDate = child[_.first(this.getFields(['startDate'])).label] || child.data_iscrizione
        const cancellationDate = child[_.first(this.getFields(['endDate'])).label] || child.data_cancellazione
        const specialEquipment = child[_.first(this.getFields(['dotazioni_speciali'])).label] || child.dotazioni_speciali
        const accompanied = child[_.first(this.getFields(['accompagnato'])).label] || child.accompagnato

        if (!new RegExp(taxCodeRegex).test(taxCode)) errors.push(this.$t('errors.taxCode'))
        else editedItem.codice_fiscale = taxCode.toUpperCase()

        if (!surname || surname.length > 50 || _.trim(surname).length === 0) {
          errors.push(this.$t('errors.surnameLength'))
        } else editedItem.cognome = surname

        if (!name || name.length > 50 || _.trim(name).length === 0) {
          errors.push(this.$t('errors.nameLength'))
        } else editedItem.nome = name
        if (!birthDate ||
            isNaN(birthDate) ||
            !DateTime.fromFormat(new Date(Date.UTC(0, 0, birthDate - 1)).toLocaleDateString('it-IT', dateFormat), 'dd/MM/yyyy').isValid) {
          errors.push(this.$t('errors.birthDate'))
        } else {
          const date = DateTime.fromFormat(new Date(Date.UTC(0, 0, birthDate - 1)).toLocaleDateString('it-IT', dateFormat), 'dd/MM/yyyy')
          if (date >= DateTime.local()) {
            errors.push(`${this.$tc('errors.birthDateMoreThanNow')} ${birthDate} )`)
          } else if (date >= minDate) {
            errors.push(`${this.$tc('errors.birthDateMoreThanMinDate')} ${minDate.toFormat('dd/MM/yyyy')} )`)
          } else {
            editedItem.data_nascita = date.toSQLDate()
          }
        }

        if (!residenceAddress || residenceAddress.length > 50 || _.trim(residenceAddress).length === 0) {
          errors.push(this.$t('errors.residenceAddress'))
        } else editedItem.indirizzo_residenza = residenceAddress

        if (!residenceCommon || residenceCommon.length > 30 || _.trim(residenceCommon).length === 0) {
          errors.push(this.$t('errors.residenceCommon'))
        } else editedItem.comune_residenza = residenceCommon

        if (!residenceProvince || residenceProvince.length > 30 || _.trim(residenceProvince).length === 0) {
          errors.push(this.$t('errors.residenceProvince'))
        } else editedItem.provincia_residenza = residenceProvince

        if (!mainPhone || mainPhone.length > 20 || _.trim(mainPhone).length === 0 || isNaN(mainPhone)) {
          errors.push(this.$t('errors.mainPhone'))
        } else editedItem.telefono_principale = '' + parseInt(mainPhone)

        if (!parent1 || parent1.length > 50 || _.trim(parent1).length === 0) errors.push(this.$t('errors.parent1'))
        else editedItem.genitore_1 = parent1

        if (taxCodeParent1 && !new RegExp(taxCodeRegex).test(taxCodeParent1)) errors.push(this.$t('errors.taxCodeParent1'))
        else editedItem.codice_fiscale_r1 = taxCodeParent1 ? taxCodeParent1.toUpperCase() : null

        if (parent2 && parent2.length > 50) errors.push(this.$t('errors.parent2'))
        else editedItem.genitore_2 = parent2 || null

        if (taxCodeParent2 && !new RegExp(taxCodeRegex).test(taxCodeParent2)) errors.push(this.$t('errors.taxCodeParent2'))
        else editedItem.codice_fiscale_r2 = taxCodeParent2 ? taxCodeParent2.toUpperCase() : null

        if (mainEmail && (!emailRegex.test(mainEmail) || mainEmail.length > 50)) errors.push(this.$t('errors.mainEmail'))
        else editedItem.email_ufficiale = mainEmail

        if (secondaryEmail && !emailRegex.test(secondaryEmail)) errors.push(this.$t('errors.secondaryEmail'))
        else editedItem.email_secondaria = secondaryEmail || null

        if (secondaryPhone && (secondaryPhone.length > 20 || isNaN(secondaryPhone))) errors.push(this.$t('errors.secondaryPhone'))
        else editedItem.telefono_secondario = secondaryPhone ? '' + parseInt(secondaryPhone) : null

        if (!structure) {
          errors.push(this.$t('errors.structure'))
        } else {
          const foundedStructure = _.find(this.structureOptions, obj =>
              _.trim(`${obj[isNaN(structure) ? 'den_sintetica' : 'id']}`).toLowerCase() === _.trim(`${structure}`).toLowerCase())
          if (foundedStructure) {
            editedItem.id_struttura = parseInt(foundedStructure.id)
          } else {
            errors.push(`${this.$t('errors.structureNotFound')} ${structure})`)
          }
        }

        if (!registrationDate ||
            isNaN(registrationDate) ||
            !DateTime.fromFormat(new Date(Date.UTC(0, 0, registrationDate - 1)).toLocaleDateString('it-IT', dateFormat), 'dd/MM/yyyy').isValid) {
          errors.push(this.$t('errors.registrationDate'))
        } else {
          const date = DateTime.fromFormat(new Date(Date.UTC(0, 0, registrationDate - 1)).toLocaleDateString('it-IT', dateFormat), 'dd/MM/yyyy')
            editedItem.data_iscrizione = date.toISO()
        }
        if (!cancellationDate ||
            isNaN(cancellationDate) ||
            !DateTime.fromFormat(new Date(Date.UTC(0, 0, cancellationDate - 1)).toLocaleDateString('it-IT', dateFormat), 'dd/MM/yyyy').isValid) {
          errors.push(this.$t('errors.discharge'))
        } else {
          const date = DateTime.fromFormat(new Date(Date.UTC(0, 0, cancellationDate - 1)).toLocaleDateString('it-IT', dateFormat), 'dd/MM/yyyy')
          editedItem.data_cancellazione = date.toISO()
        }

        if (!isNil(editedItem.data_iscrizione) && !isNil(editedItem.data_cancellazione) && editedItem.data_iscrizione > editedItem.data_cancellazione) {
          errors.push(this.$t('errors.registrationDateAndDischargeAreIncompatible'))
        }

        if (!isNil(specialEquipment)) editedItem.dotazioni_speciali = specialEquipment === 'x' || specialEquipment === 'X'
        if (!isNil(accompanied)) editedItem.accompagnato = accompanied === 'x' || accompanied === 'X'

        if (errors.length === 0) {
          this.updateChildData(editedItem)
        }
        return { errors, child: editedItem }
      },
      updateChildData (excelItem) {
        const foundedChild = _.find(this.originalItems, obj => obj.codice_fiscale && excelItem.codice_fiscale &&
        _.trim(obj.codice_fiscale).toLowerCase() === _.trim(excelItem.codice_fiscale).toLowerCase())
        const child = _.cloneDeep(excelItem)
        delete child.index

        if (foundedChild) {
          child.id = foundedChild.id
        }
        return this.updateChild(child, excelItem).then(() => {
          return { errors: [], child: excelItem }
        }).catch(error => {
          return { errors: [Vue.manageErrors(this, error, true)], child: excelItem }
        })
      },
      updateChild (child) {
        return Vue.patchOrPost(!!child.id, `/bambino${child.id ? '/' + child.id : ''}`, child).then(r => {
          return r.data.id
        }).catch(error => {
          throw error
        })
      },
      showImportModal () {
        this.$refs.importModal.show()
        if (this.$refs.importChildrenFile) this.$refs.importChildrenFile.reset()
      },
      closeImportModal () {
        this.$refs.importModal.hide()
        this.childImportFile = null
      },
      openImportModalError (errors) {
        this.importErrors = errors
        this.$refs.importModalErrors.show()
      },
      errorsAreShown () {
        this.$root.$emit('activeLoader', false)
      },
      hideMainErrorMessageModal () {
        if (this.$refs.importModalErrors) this.$refs.importModalErrors.hide()
        if (!_.isNil(this.$refs.exportTable)) {
          this.$refs.exportTable.refresh()
        }
      }
    }
  }
</script>
