<template>
  <b-container fluid :class="isMobile ? 'p-0 m-0' : ''">
    <div class="modal-dialog" :class="!showStopEditing ? 'modal-xl' : ''"
         :style="showStopEditing ? 'min-width: 100%' :  ''">
      <div class="modal-content">
        <header class="modal-header">
          <b-container fluid class=" p-0 m-0 ">
            <b-row>
              <b-col cols="12" sm="12" md="12" lg="9" xl="">
                <h5 class="my-modal-title">{{ $tc('message.trip', 1) }}</h5>
              </b-col>
            </b-row>
          </b-container>
        </header>

        <div :class="isMobile ? 'modal-body p-1' : 'modal-body'">
          <b-container fluid>
              <b-row class="mb-2">
                <b-col cols="12" sm="12" md="6" lg="6" xl="4">
                  <!-- Name -->
                  <b class="mr-2">{{$tc('message.name', 1)}}:</b> {{input.nome || $tc('message.emptyValue')}}
                </b-col>

                <b-col cols="12" sm="12" md="12" lg="6" xl="4">
                  <!-- Description -->
                    <b class="mr-2">{{ $tc('message.description', 1) }}</b> {{input.descrizione || $tc('message.emptyValue')}}
                </b-col>

                <b-col cols="12" sm="12" md="6" lg="5" xl="4"
                       order="3" order-sm="3" order-md="2" order-lg="3">
                  <b class="mr-2">{{$tc('message.tripStatus', 1)}}:</b>{{ input.attiva ? $tc('message.enabled', 0) : $tc('message.disabled', 0) }}
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col cols="12" sm="12" md="12" lg="5" xl="4">
                  <!-- Line -->
                  <b class="mr-2">{{$tc('message.line', 1)}}:</b>{{input.linea || $tc('message.emptyValue')}}
                </b-col>

                <b-col cols="12" sm="12" md="12" lg="5" xl="4">
                  <!-- Table -->
                  <b class="mr-2">{{$tc('message.table', 1)}}:</b>{{input.tabella || $tc('message.emptyValue')}}
                </b-col>

                <b-col cols="12" sm="12" md="5" lg="5" xl="4" order="3" order-sm="3" order-md="2" order-lg="3">
                  <b class="mr-2">{{$tc('message.deposit', 1)}}:</b>{{input.deposito || $tc('message.emptyValue')}}
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col cols="12" sm="12" md="5" lg="5" xl="4">
                  <!-- Vehicle -->
                  <b class="mr-2">{{$tc('message.vehicle', 1)}}:</b>{{input.vettura || $tc('message.emptyValue')}}
                </b-col>
                <b-col cols="12" sm="12" md="5" lg="6" xl="4" v-if="tripType === 'SINGLE'">
                  <!-- SpecialEquipment -->
                  <b class="mr-2">{{$tc('message.specialEquipment', 1)}}:</b>{{input.totale_dotazioni_speciali || $tc('message.emptyValue')}}
                </b-col>
                <b-col v-if="tripType==='SINGLE'"  class="mt-1" cols="12" sm="12" md="5" lg="5" xl="4">
                  <!-- Date -->
                  <b class="mr-2">{{$tc('message.date', 1)}}:</b>{{input.data || $tc('message.emptyValue')}}
                </b-col>

                <b-col v-if="tripType==='PERIODIC'"  class="mt-1" cols="12" sm="12" md="5" lg="5" xl="4">
                  <!-- dateStart -->
                  <b class="mr-2">{{$tc('message.dateStart', 1)}}:</b>{{input.da || $tc('message.emptyValue')}}
                </b-col>
                <b-col v-if="tripType==='PERIODIC'"  class="mt-1" cols="12" sm="12" md="5" lg="5" xl="4">
                  <!-- dateEnd -->
                  <b class="mr-2">{{$tc('message.dateEnd', 1)}}:</b>{{input.a || $tc('message.emptyValue')}}
                </b-col>

              </b-row>
            <b-row class="mr-2">
              <b-col v-if="tripType==='PERIODIC' "  class="mt-1" cols="12" sm="12" md="5" lg="5" xl="5">
                <!-- days -->
                <b class="mr-2">{{$tc('message.days', 1)}}:</b>
                <b-row class="ml-2 mb-2">
                  <b-form-group :label="`${$tc('message.monday', 1)}`" class="mr-2">
                    <b-form-checkbox
                        :checked="input?.maschera_giorni !== undefined && input?.maschera_giorni.charAt(0) === '1'"
                        @change="input.maschera_giorni = input?.maschera_giorni.replaceAt(0, '1')"
                        :disabled="true">
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group :label="`${$tc('message.thursday', 1)}`" class="mr-2">
                    <b-form-checkbox
                        :checked="input?.maschera_giorni !== undefined && input?.maschera_giorni.charAt(1) === '1'"
                        @change="input.maschera_giorni = input?.maschera_giorni.replaceAt(1, '1')"
                        :disabled="true">
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group :label="`${$tc('message.wednesday', 1)}`" class="mr-2">
                    <b-form-checkbox
                        :checked="input?.maschera_giorni !== undefined && input?.maschera_giorni.charAt(2) === '1'"
                        @change="input.maschera_giorni = input?.maschera_giorni.replaceAt(2, '1')"
                        :disabled="true">
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group :label="`${$tc('message.tuesday', 1)}`" class="mr-2">
                    <b-form-checkbox
                        :checked="input?.maschera_giorni !== undefined && input?.maschera_giorni.charAt(3) === '1'"
                        @change="input.maschera_giorni = input?.maschera_giorni.replaceAt(3, '1')"
                        :disabled="true">
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group :label="`${$tc('message.friday', 1)}`" class="mr-2">
                    <b-form-checkbox
                        :checked="input?.maschera_giorni !== undefined && input?.maschera_giorni.charAt(4) === '1'"
                        @change="input.maschera_giorni = input?.maschera_giorni.replaceAt(4, '1')"
                        :disabled="true">
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group :label="`${$tc('message.saturday', 1)}`" class="mr-2">
                    <b-form-checkbox
                        :checked="input?.maschera_giorni !== undefined && input?.maschera_giorni.charAt(5) === '1'"
                        @change="input.maschera_giorni = input?.maschera_giorni.replaceAt(5, '1')"
                        :disabled="true">
                    </b-form-checkbox>
                  </b-form-group>
                </b-row>
              </b-col>
            </b-row>
              <b-row>
                <transition :name="squeezeStopList ? 'slide-right' : 'slide-left'" mode="out-in">
                  <b-col class="border mt-3"
                         cols="12" sm="12" md="12"
                         :lg="squeezeStopList ? '4' : '12'"
                         :xl="squeezeStopList ? '4' : '12'">
                    <b-container fluid class="m-0 p-0">
                      <b-row>
                        <b-col class="p-2">
                          <b-list-group class="mt-3 mb-3">
                              <transition-group id="trans-group" type="transition">
                                <b-list-group-item v-for="(element, index) in tripStopOption"
                                                   :id="'list-group-item-' + index"
                                                   :key="element.id" class="d-flex">
                                  <b-container class="px-1" id="list-container">
                                    <b-row align-h="center" id="stop-list">
                                      <b-col cols="auto" sm="6" md="8"
                                             :lg="squeezeStopList ? '5' : '10'"
                                             :xl="squeezeStopList ? '6' : '10'"
                                             class="mr-0 pl-0 pr-0 mr-auto">
                                        <div class="stop-details-row">
                                          <div class="stop-details-col">
                                            <b-badge class="b-c-green p-2">{{ updateTripStopIdx(element, index) }} {{element.orario_inizio}}
                                            </b-badge>
                                            <div class="stop-details-text"></div>

                                            <b-badge
                                                class="b-c-green d-flex mx-1 py-1"
                                                v-if="element.fermata.struttura && isMobile"
                                                v-b-popover.hover.top="element.struttura">
                                              <i class="fas fa-building p-2 fa-lg"/>
                                            </b-badge>
                                            <div class="stop-details-text"
                                                 v-if="isMobile">

                                            </div>

                                            <h6 v-if="element.fermata.nome" class="stop-details-text">
                                              {{ element.fermata.nome }},
                                            </h6>

                                            <div class="stop-details-text" v-if="!isMobile">
                                              <b-badge class="b-c-green d-flex mx-1 py-1"
                                                       v-if="element.fermata.struttura"
                                                       v-b-popover.hover.top="element.fermata.struttura">
                                                <i class="fas fa-building p-2 fa-lg"/>
                                              </b-badge>
                                            </div>

                                            <h6 class="stop-details-text" v-if="!isMobile">
                                              {{ element.fermata.struttura ? `${element.fermata.struttura},` : '' }}
                                            </h6>

                                            <h6 class="stop-details-text">
                                              {{ element.fermata.indirizzo ? `${element.fermata.indirizzo}` : '' }}
                                              {{ element.fermata.civico ? `, ${element.fermata.civico}` : '' }}
                                            </h6>
                                          </div>
                                        </div>
                                      </b-col>

                                      <b-col cols="auto" class="m-0 p-0 ml-auto" id="stop-buttons">
                                        <b-button id="user-button"
                                                  class="d-inline-flex fas fa-user-alt align-self-end mr-2"
                                                  size="sm" @click="showStopEditingSide(element, 'association')">
                                        </b-button>
                                        <b-button id="info-button"
                                                  class="d-inline-flex fas fa-eye align-self-end mr-2"
                                                  size="sm" @click="showStopEditingSide(element, 'edit');openInfoModal(element)">
                                        </b-button>
                                      </b-col>
                                    </b-row>
                                  </b-container>
                                </b-list-group-item>
                              </transition-group>
                          </b-list-group>
                        </b-col>
                      </b-row>
                    </b-container>
                  </b-col>
                </transition>

                <b-col id="select-children"
                       :class="!!showStopEditing ? `border mt-3 ${isMobile ? '' : ' border-left-0'}` : ''"
                       cols="12" sm="12" md="12" lg="8" xl="8">
                  <transition :name="!showStopEditing ? 'slide-right' : 'slide-left'" mode="out-in"
                              v-on:before-enter="squeezeStopList = true"
                              v-on:after-leave="squeezeStopList = false">
                    <b-container id="trip-stop-modal" fluid class="m-0 p-0" v-show="!!showStopEditing">

                      <b-row v-if="showStopEditing === 'association'">
                        <b-col>
                          <b-container fluid class="m-0 p-0 mt-3">
                            <b-tabs v-model="tabIndex">
                              <b-tab :title="`${$tc('message.child', 2)}`" active>
                                <standard-table v-if="tabIndex===0"
                                                ref="childrenlist"
                                                id="children-list"
                                                :noContainerPadding="true"
                                                :fullWidth="true"
                                                :items="getChildrenForTable(structureFilter)"
                                                :fields="getChildFields()"
                                                :customFields="getCustomFields('CHILDREN')"
                                                :noPaginationMargin="true"
                                                :row-before-search="{cols: 12,sm: 12,md: 12,lg: 12,xl: 12,order: 1,orderSm: 1,orderMd: 1,orderLg: 3,orderXl: 3}"
                                                :row-search="{cols: 12,sm: 12,md: 12,lg: 4,xl: 4,order: 1,orderSm: 2,orderMd: 2,orderLg: 1,orderXl: 1}"
                                                :row-after-search="{cols: 12, sm: 12, md: 12, lg: 12, xl: 6, order: 2, orderSm: 3, orderMd: 3, orderLg: 2, orderXl: 2}"

                                                :row-pagination="{cols: 12, sm: 12, md: 12, lg: 6, xl: 6}"
                                                :row-between-pagination="{cols: 12, sm: 4, md: 6, lg: 1, xl: 3}"
                                                :row-per-page="{cols: 12, sm: 8, md: 6, lg: 5, xl: 3}">
                                  <b-container slot="row-after-search" class="mw-100 p-0">
                                    <b-row>
                                      <b-col cols="auto">
                                        <b-button v-on:click="resetFilters()" class="b-c-green" id="reset-filters">
                                          {{ $t('message.resetFilters') }}
                                        </b-button>
                                      </b-col>

                                      <b-col cols="auto" class="mb-3">
                                        <b-button @click="showCollapse = !showCollapse"
                                                  :class="showCollapse ? 'b-c-red' : 'b-c-green'" id="show-filters">
                                      <span v-show="!showCollapse"
                                            class="when-opened">{{
                                          $t('message.show')
                                        }} {{ $tc('message.filter', 2).toLowerCase() }}</span>
                                          <span v-show="showCollapse"
                                                class="when-closed">{{
                                              $t('message.hide')
                                            }} {{ $tc('message.filter', 2).toLowerCase() }}</span>
                                        </b-button>
                                      </b-col>
                                    </b-row>
                                  </b-container>

                                  <b-container slot="after-search" class="mw-100 p-0"
                                  >
                                    <b-collapse id="my-collapse" v-model="showCollapse">
                                      <b-container class="mw-100 m-0 p-0">
                                        <b-row>
                                          <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                                            <!-- Structure -->
                                            <b-form-group :label="`${$tc('message.structure', 1)}`">
                                              <v-select :options="structureOptions"
                                                        :reduce="obj => obj.id"
                                                        label="den_sintetica"
                                                        v-model="structureFilter">
                                              </v-select>
                                            </b-form-group>
                                          </b-col>
                                          <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                                            <!-- Attributes -->
                                            <b-form-group :label="`${$tc('message.attribute', 2)}`">
                                              <v-select :options="attributeOptions"
                                                        :reduce="obj => obj.id"
                                                        label="nome"
                                                        placeholder="Seleziona"
                                                        v-model="attributeFilter">
                                              </v-select>
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                      </b-container>
                                    </b-collapse>
                                  </b-container>

                                  <slot slot="up&down" slot-scope="row">
                                    <transition name="fade" :duration="{ enter: 330, leave: 1 }">
                                      <i class="fas fa-ban fa-lg c-ls-gray hover-not-allowed"
                                         v-if="!row.item.selected"/>
                                    </transition>

                                    <transition name="fade"
                                                :duration="{ enter: 330, leave: 1 }">
                                      <i class="fas fa-arrow-up fa-lg c-green hover-enabled"
                                         v-b-popover.hover.right="$tc('message.up', 1)"
                                         v-if="row.item.salita && row.item.selected">
                                      </i>
                                    </transition>

                                    <transition name="fade" :duration="{ enter: 330, leave: 1 }">
                                      <i class="fas fa-arrow-down fa-lg c-red hover-enabled"
                                         v-b-popover.hover.right="$tc('message.down', 1)"
                                         v-if="!row.item.salita && row.item.selected"/>
                                    </transition>

                                    {{
                                      isMobile ? (row.item.selected ? (row.item.salita ? $tc('message.up', 1) : $tc('message.down', 1)) : '') : ''
                                    }}
                                  </slot>
                                  <slot slot="note" slot-scope="row">
                                    <b   v-if="row.item.selected" class="mr-2">{{row.item.note}}</b>
                                  </slot>
                                </standard-table>
                              </b-tab>
                              <b-tab :title="`${$tc('message.operator', 2)}`">
                                <standard-table
                                    ref="operatorslist"
                                    id="operators-list"
                                    :noContainerPadding="true"
                                    :fullWidth="true"
                                    :items="getOperatorsForTable()"
                                    :fields="getOperatorFields()"
                                    :disableAutoUnSelection="true"
                                    :noPaginationMargin="true"
                                    :customFields="getCustomFields()"
                                    :row-before-search="{cols: 12,sm: 12,md: 12,lg: 12,xl: 12,order: 1,orderSm: 1,orderMd: 1,orderLg: 3,orderXl: 3}"
                                    :row-search="{cols: 12,sm: 12,md: 12,lg: 4,xl: 4,order: 1,orderSm: 2,orderMd: 2,orderLg: 1,orderXl: 1}"
                                    :row-after-search="{cols: 12, sm: 12, md: 12, lg: 12, xl: 6, order: 2, orderSm: 3, orderMd: 3, orderLg: 2, orderXl: 2}"

                                    :row-pagination="{cols: 12, sm: 12, md: 12, lg: 6, xl: 6}"
                                    :row-between-pagination="{cols: 12, sm: 4, md: 6, lg: 1, xl: 3}"
                                    :row-per-page="{cols: 12, sm: 8, md: 6, lg: 5, xl: 3}">

                                  <b-container slot="after-search" class="mw-100 p-0">
                                  </b-container>
                                  <slot slot="id_assistente" slot-scope="row">
                                    <h6 class="text-center">{{row.item.id_letterale}}</h6>
                                  </slot>
                                  <slot slot="up&down" slot-scope="row">
                                    <transition name="fade" :duration="{ enter: 330, leave: 1 }">
                                      <i class="fas fa-ban fa-lg c-ls-gray hover-not-allowed"
                                         v-if="!row.item.selected"/>
                                    </transition>

                                    <transition name="fade"
                                                :duration="{ enter: 330, leave: 1 }">
                                      <i class="fas fa-arrow-up fa-lg c-green hover-enabled"
                                         v-b-popover.hover.right="$tc('message.up', 1)"
                                         v-if="row.item.salita && row.item.selected">
                                      </i>
                                    </transition>

                                    <transition name="fade" :duration="{ enter: 330, leave: 1 }">
                                      <i class="fas fa-arrow-down fa-lg c-red hover-enabled"
                                         v-b-popover.hover.right="$tc('message.down', 1)"
                                         v-if="!row.item.salita && row.item.selected"/>
                                    </transition>

                                    {{
                                      isMobile ? (row.item.selected ? (row.item.salita ? $tc('message.up', 1) : $tc('message.down', 1)) : '') : ''
                                    }}
                                  </slot>
                                </standard-table>
                              </b-tab>
                            </b-tabs>

                          </b-container>
                        </b-col>
                      </b-row>
                    </b-container>
                  </transition>
                </b-col>
              </b-row>
          </b-container>
        </div>
      </div>
    </div>
    <b-modal ref="infoModal" id="infoModal" :title="`${$tc('message.details', 1)}`" hide-footer>
      <b-container>
      <b-row>
        <b-col>
          <b-container fluid class="m-0 p-0">
            <b-row>
              <b-col cols="12">
                <!-- Name -->
                <div class="d-flex">
                  <h6><b>{{ $tc('message.name', 1) }}</b></h6>
                  <h6 class="ml-2">{{ stop.nome || $tc('message.emptyValue') }}</h6>
                </div>
              </b-col>

              <b-col cols="12">
                <!-- Structure -->
                <div class="d-flex">
                  <h6><b>{{ $tc('message.structure', 1) }}</b></h6>
                  <h6 class="ml-2">{{ stop.struttura || $tc('message.emptyValue') }}</h6>
                </div>
              </b-col>

              <b-col cols="12">
                <!-- Address -->
                <div class="d-flex">
                  <h6><b>{{ $tc('message.address', 1) }}</b></h6>
                  <h6 class="ml-2">{{ stop.indirizzo || $tc('message.emptyValue') }}</h6>
                </div>
              </b-col>

              <b-col cols="12">
                <!-- Civic number -->
                <div class="d-flex">
                  <h6><b>{{ $tc('message.civicNumber', 1) }}</b></h6>
                  <h6 class="ml-2">{{ stop.civico || $tc('message.emptyValue') }}</h6>
                </div>
              </b-col>

              <b-col cols="12">
                <!-- Description -->
                <div class="d-flex">
                  <h6><b>{{ $tc('message.description', 1) }}</b></h6>
                  <h6 class="ml-2">{{ stop.descrizione || $tc('message.emptyValue') }}</h6>
                </div>
              </b-col>

              <b-col cols="12">
                <div class="d-flex">
                  <h6><b>{{ $tc('message.startTime', 1) }}</b></h6>
                  <h6 class="ml-2">{{ stopData.orario_inizio || $tc('message.emptyValue') }}</h6>
                </div>
              </b-col>

              <b-col cols="12">
                <div class="d-flex">
                  <h6><b>{{ $tc('message.endTime', 1) }}</b></h6>
                  <h6 class="ml-2">{{ stopData.orario_fine || $tc('message.emptyValue') }}</h6>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
        <div slot="modal-footer" class="w-100 justify-content-end d-flex">
          <b-button class="b-c-green" @click="$refs.infoModal.hide()"> Ok</b-button>
        </div>
      </b-container>
    </b-modal>
  </b-container>
</template>

<script>
import Vue from 'vue'
import _, { isNil } from 'lodash'
import { DateTime } from 'luxon'

export default {
  name: 'ShowTripInfo',
  created () {
    this.fetchData()
  },
  mounted () {
    this.$root.$on('change-lang', () => {
      this.$nextTick(() => {
        if (this && this.$refs && this.$refs.mainForm) this.$refs.mainForm.validate()
        if (this && this.$refs && this.$refs.stopForm && !!this.showStopEditing) this.$refs.stopForm.validate()
      })
    })
    this.$root.$on('resize', () => this.checkWindowSize())
    this.$nextTick(() => this.checkWindowSize())
  },
  data () {
    const tripId = this.$route.params.id
    const tripType = this.$route.params.type
    const lodash = _
    const backPath = '/registry/trip'
    const session = Vue.getLocalStorage('session')
    return {
      backPath,
      tripId,
      lodash,
      DateTime,
      session,
      tripType,
      isMobile: false,
      input: {
        maschera_giorni: '000000'
      },
      stop: {},
      stopData: {},
      structureOptions: [],
      originalChildOptions: [],
      childOptions: [],
      filterableChildOptions: [],
      stopOption: [],
      tripStopOption: [],
      childrenTripStop: [],
      operatorsTripStop: [],
      showStopEditing: null,
      squeezeStopList: false,
      showCollapse: false,
      structureFilter: null,
      users: [],
      originalOperatorsItems: [],
      operatorOptions: [],
      filterableAssistantOptions: [],
      originalAssociation: [],
      tabIndex: 0,
      attributeOptions: [],
      attributeFilter: null,
      originalTripStopOptions: [],
      depositOptions: [],
      optionPathForRequest: '',
      assistantOptions: [],
      standardAssistants: [],
      associatedAssistants: [],
      originalOperatorsTripStop: []
    }
  },
  methods: {
    checkWindowSize () {
      const size = Vue.myGetWindowSize()
      this.isMobile = (size === 'sm' || size === 'cols' || size === 'md')
    },
    // Getting data
    fetchData () {
      if (isNil(this.tripType)) {
        this.tripType = Vue.getLocalStorage('tripType')
      }
      this.optionPathForRequest = this.tripType === 'SINGLE' ? 'gita' : 'periodico'
      this.input = { attiva: true, maschera_giorni: '000000' }
      return this.getOptions().then(() => {
        return this.getTrip().then(() => {
          this.$root.$emit('activeLoader', false)
          return true
        })
      })
    },
    getOptions () {
      const axiosArray = []
      axiosArray.push(this.getStructures())
      axiosArray.push(this.getChildren())
      axiosArray.push(this.getAllStop())
      axiosArray.push(this.getUsers())
      axiosArray.push(this.getAssistants())
      axiosArray.push(this.getOperator())
      axiosArray.push(this.getAllTripStop())
      axiosArray.push(this.getTripChildren())
      axiosArray.push(this.getTripOperators())
      axiosArray.push(this.getAttributes())
      axiosArray.push(this.getDeposits())
      return Vue.myAll(axiosArray)
    },
    getAttributes () {
      return Vue.myGet(this, '/attributo', 'attributi').then(attributes => {
        this.attributeOptions = _.orderBy(attributes, 'nome')
        return true
      }).catch(error => {
        Vue.manageErrors(this, error)
        return true
      })
    },
    getAssistants () {
      return Vue.myGet(this, '/bus/assistente', 'assistenti').then(assistants => {
        this.standardAssistants = _.orderBy(assistants, 'id_letterale')
        return true
      }).catch(error => {
        Vue.manageErrors(this, error)
        return true
      })
    },
    getDeposits () {
      return Vue.myGet(this, '/deposito', 'depositi').then(deposits => {
        this.depositOptions = _.orderBy(deposits, 'nome')
        return true
      }).catch(error => {
        Vue.manageErrors(this, error)
        return true
      })
    },
    getStructures () {
      return Vue.myGet(this, '/struttura', 'strutture').then(structures => {
        this.structureOptions = _.orderBy(structures, 'den_sintetica')
        return true
      }).catch(error => {
        Vue.manageErrors(this, error)
        return true
      })
    },
    getChildren () {
      return Vue.myGet(this, '/bambino', 'bambini').then(children => {
        const editedItems = []
        children.forEach(item => {
          if (!item.data_cancellazione || (item.data_cancellazione && DateTime.fromISO(item.data_cancellazione) >= DateTime.local())) {
            const structure = _.find(this.structureOptions, structure => structure.id === item.id_struttura)
            if (!isNil(structure)) item.struttura = structure.den_sintetica
            editedItems.push(item)
          }
        })
        this.originalChildOptions = editedItems
      }).catch(error => {
        Vue.manageErrors(this, error)
        return true
      })
    },
    getOperator () {
      Vue.myGet(this, '/operatore?id_ruolo=3', 'operatori').then((operators) => {
        const options = [{ value: null, text: this.$t('message.selectOption') }]

        operators.forEach((operator) => {
          options.push({
            text: operator.nome + ' ' + operator.cognome,
            value: operator.id
          })
        })
        this.operatorOptions = options
        this.originalOperatorsItems = _.cloneDeep(operators)
          Vue.myGet(this, '/bus/assistente?gita=' + this.tripId, 'assistenti').then((assistants) => {
            Vue.myGet(this, '/gita_operatore?gita=' + this.tripId, 'associazioni').then((tripOperators) => {
              assistants.forEach((assistant) => {
              assistant.id_assistente = assistant.id
              delete assistant.id
                const founded = _.find(tripOperators, item => item.id_assistente === assistant.id_assistente)
                if (!isNil(founded)) {
                assistant.selected = true
                  assistant.id_operatore = founded.id_operatore
                assistant.id_gita_operatore = founded.id
              } else {
                assistant.selected = false
                assistant.id_operatore = null
              }
            })
            this.associatedAssistants = assistants
            this.originalAssociation = tripOperators
            this.$root.$emit('activeLoader', false)
            return true
          }).catch(error => {
            Vue.manageErrors(this, error)
            return false
          })
        }).catch(error => {
          Vue.manageErrors(this, error)
          return false
        })
}).catch(error => {
        Vue.manageErrors(this, error)
        return false
      })
    },
    getAllStop () {
      return Vue.myGet(this, '/bus/fermata', 'fermate').then(stops => {
        this.stopOption = stops
        return true
      }).catch(error => {
        Vue.manageErrors(this, error)
        return true
      })
    },
    getAllTripStop () {
      if (!isNaN(this.tripId)) {
        this.showStopEditing = false
        return Vue.myGet(this, `/bus/${this.optionPathForRequest}_fermata?${this.optionPathForRequest}=${this.tripId}`, 'fermate').then(stops => {
          const editedTrips = []
          stops.forEach(trip => {
            if (!trip.removed) {
              editedTrips.push(trip)
            }
          })
          this.tripStopOption = _.orderBy(editedTrips, 'orario_inizio')
          this.originalTripStopOptions = _.cloneDeep(this.tripStopOption)
          return true
        }).catch(error => {
          Vue.manageErrors(this, error)
          return true
        })
      }
      return true
    },
    getTripChildren () {
      if (!isNaN(this.tripId)) {
        return Vue.myGet(this, `/bus/bambino_${this.optionPathForRequest}_fermata?${this.optionPathForRequest}=${this.tripId}`, 'bambini').then(items => {
          this.childrenTripStop = items
          return true
        }).catch(error => {
          Vue.manageErrors(this, error)
          return true
        })
      }
      return true
    },
    getTripOperators () {
      if (!isNaN(this.tripId)) {
        return Vue.myGet(this, `/bus/operatore_${this.optionPathForRequest}_fermata?${this.optionPathForRequest}=${this.tripId}`, 'operatori').then(items => {
          this.operatorsTripStop = items
          this.originalOperatorsTripStop = items
          return true
        }).catch(error => {
          Vue.manageErrors(this, error)
          return true
        })
      }
      return true
    },
    getTrip () {
      if (this.tripId !== 'new') {
        return Vue.myGet(this, this.tripType === 'SINGLE' ? `/bus/gita/${this.tripId}` : `/bus/percorso_periodico/${this.tripId}`, this.tripType === 'SINGLE' ? 'gite' : 'percorsi_periodici').then((trip) => {
          this.input = trip
          this.input.deposito = _.find(this.depositOptions, item => item.id === trip.id_deposito).nome
          const dateFormat = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          }
          this.input.data = new Date(trip.data).toLocaleDateString('it-IT', dateFormat)
          if (!isNil(trip.da)) {
            this.input.da = new Date(trip.da).toLocaleDateString('it-IT', dateFormat)
          }
          if (!isNil(trip.a)) {
            this.input.a = new Date(trip.a).toLocaleDateString('it-IT', dateFormat)
          }
          return true
        }).catch(error => {
          Vue.manageErrors(this, error)
          return true
        })
      } else {
        return new Promise(resolve => resolve())
      }
    },
    getChildFields () {
      return [
        { key: 'nome', label: `${this.$tc('message.name', 1)}`, sortable: true, sortDirection: 'desc' },
        { key: 'cognome', label: `${this.$tc('message.surname', 1)}`, sortable: true, sortDirection: 'desc' },
        {
          key: 'codice_fiscale',
          label: `${this.$tc('message.taxCode', 1)}`,
          sortable: true,
          sortDirection: 'desc',
          hideInTable: true
        },
        { key: 'struttura', label: `${this.$tc('message.structure', 1)}`, sortable: true, sortDirection: 'desc' }
      ]
    },
    getOperatorFields () {
      return [
        { key: 'id_letterale', label: `${this.$tc('message.assistantId', 1)}`, sortable: true, sortDirection: 'desc' },
        { key: 'nome', label: `${this.$tc('message.name', 1)}`, sortable: true, sortDirection: 'desc' },
        { key: 'cognome', label: `${this.$tc('message.surname', 1)}`, sortable: true, sortDirection: 'desc' },
        {
          key: 'codice_fiscale',
          label: `${this.$tc('message.taxCode', 1)}`,
          sortable: true,
          sortDirection: 'desc',
          hideInTable: true
        }
      ]
    },
    getCustomFields (type) {
      const fields = [
        { key: 'up&down', label: `${this.$tc('message.status', 1)}`, position: 0 }
      ]
      if (type === 'CHILDREN') {
        fields.push({ key: 'note', label: `${this.$tc('message.note', 2)} ${this.$tc('message.for', 1)} ${this.$tc('message.operator', 1).toLowerCase()}`, sortable: true, sortDirection: 'desc' })
      }
      return fields
    },
    getEditStopOptions (options, field) {
      const editedItem = []
      options.forEach(option => {
        if (option[field] && !_.find(editedItem, obj => obj[field] === option[field])) {
          editedItem.push(option)
        }
      })
      return _.orderBy(editedItem, field)
    },
    getChildrenForTable (structure) {
      let editedItems = _.cloneDeep(this.filterableChildOptions)
      if (structure) editedItems = _.filter(editedItems, obj => obj.id_struttura === structure)
      if (!isNil(this.attributeFilter)) {
        editedItems = _.filter(editedItems, obj => !_.isNil(obj.attributi) && obj.attributi.length > 0 && !_.isNil(_.find(obj.attributi, item => item.id === this.attributeFilter)))
      }
      editedItems = editedItems.sort((a, b) => {
            if (this.$refs.childrenlist && this.$refs.childrenlist.sortDesc) {
              if (a.cognome.toLowerCase() < b.cognome.toLowerCase()) { return 1 }
              if (a.cognome.toLowerCase() > b.cognome.toLowerCase()) { return -1 }
              return 0
            } else {
              if (a.cognome.toLowerCase() < b.cognome.toLowerCase()) { return -1 }
              if (a.cognome.toLowerCase() > b.cognome.toLowerCase()) { return 1 }
              return 0
            }
          }
      )
      return editedItems
    },
    getOperatorsForTable () {
      const editedItems = _.cloneDeep(this.filterableAssistantOptions)
      const associatedOperators = _.filter(this.operatorFilteredOptions, item => item.selected === true)
      associatedOperators.forEach((item) => {
        const foundedAssistant = _.find(editedItems, editedItem => editedItem.id_assistente === item.id_assistente)
        if (!isNil(foundedAssistant)) {
          item = foundedAssistant
        }
      })

      this.assistantOptions = editedItems
      return editedItems
    },

    updateTripStopIdx (item, newIndex) {
      item.draggableIndex = newIndex + 1
    },

    // Trip-stop-child functions
    showStopEditingSide (item, type) {
      this.tripStopOption.forEach((element) => {
        element.class = ''
      })
      item.class = 'b-g-selected'
      if (this.$refs.stopForm) this.$refs.stopForm.reset()
      if (item && item.fermata) this.stop = _.cloneDeep(item.fermata)
      else this.stop = {}
      this.checkAvailableChildren(item)
      this.checkAvailableOperators(item)
      this.showStopEditing = type === 'association' ? type : ''
    },
    openInfoModal (item) {
      if (item && item.fermata) this.stop = _.cloneDeep(item.fermata)
      this.$refs.infoModal.show()
    },
    checkAvailableChildren (item) {
      const allTripStop = _.cloneDeep(this.tripStopOption)
      const originalChildrenTripStop = _.cloneDeep(this.childrenTripStop)
      const originalChildOptions = _.cloneDeep(this.originalChildOptions)
      const remainingChildOption = []
      const allChildrenTripStop = !_.isNil(item) && !_.isNil(item.id) ? _.filter(originalChildrenTripStop, childStop => !isNil(childStop.id_gita_fermata) ? childStop.id_gita_fermata === item.id : childStop.id_periodico_fermata === item.id) : []
      const tripStop = !_.isNil(item) && !_.isNil(item.id) ? _.filter(allTripStop, childStop => childStop.id === item.id) : []
      const editedTripStop = tripStop
      editedTripStop.children = []
      if (allChildrenTripStop && allChildrenTripStop.length !== 0) {
        allChildrenTripStop.forEach(childTripStop => {
          const foundedChild = _.find(originalChildOptions, obj => obj.id === childTripStop.id_bambino)
          if (foundedChild) {
            const editedChild = foundedChild
            editedChild.tripChildId = childTripStop.id
            editedChild.selected = true
            editedChild.note = childTripStop.note
            editedChild.salita = childTripStop.salita
            editedTripStop.children.push(editedChild)
            remainingChildOption.push(editedChild)
          }
        })
      }

      this.childOptions = _.orderBy(remainingChildOption, 'nome')
      this.filterableChildOptions = _.cloneDeep(this.childOptions)

      this.stopData = item || { id_gita: parseInt(this.tripId) }
    },

    checkAvailableOperators (item) {
      const originalOperatorsTripStop = _.cloneDeep(this.operatorsTripStop)
      const allOperatorsTripStop = !_.isNil(item) && !_.isNil(item.id) ? _.filter(originalOperatorsTripStop, operatorStop => !isNil(operatorStop.id_gita_fermata) ? operatorStop.id_gita_fermata === item.id : operatorStop.id_periodico_fermata === item.id) : []
      let standardAssistants = this.standardAssistants
      standardAssistants.forEach((item) => {
        item.selected = false
      })
      if (allOperatorsTripStop && allOperatorsTripStop.length !== 0) {
        allOperatorsTripStop.forEach(operatorTripStop => {
            const editedOperator = _.find(standardAssistants, obj => obj.id === operatorTripStop.id_assistente)
            editedOperator.selected = true
            editedOperator.salita = operatorTripStop.salita
            const foundAssociation = _.find(this.originalAssociation, association => association.id_assistente === operatorTripStop.id_assistente)
            if (!isNil(foundAssociation) && !isNil(foundAssociation.operatore)) {
                editedOperator.nome = foundAssociation.operatore.nome
                editedOperator.cognome = foundAssociation.operatore.cognome
            }
        })
      }
      standardAssistants = _.filter(standardAssistants, assistant => assistant.selected === true)
      this.operatorFilteredOptions = _.orderBy(standardAssistants, 'id_letterale')
      this.filterableAssistantOptions = this.operatorFilteredOptions
      this.stopData = item || { id_gita: parseInt(this.tripId) }
    },
    resetFilters () {
      this.$refs.childrenlist.resetSearch()
      this.$refs.operatorslist.resetSearch()
    },
    showExportModal () {
      if (this.$refs && this.$refs.associateOperatorModal) this.$refs.associateOperatorModal.show()
    },
    getUsers () {
      this.users = []
      return Vue.myGet(this, '/utente?all=true', 'utenti').then(users => {
        this.users = users
        return true
      }).catch(error => {
        Vue.manageErrors(this, error)
        return true
      })
    }
  }
}
</script>

<style scoped>
.stop-details-row {
  display: grid;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
}

.stop-details-col {
  min-width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.stop-details-text {
  flex-grow: 0;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0;
  align-self: center;
  margin-left: 0.1em;
}

.b-g-selected{
  background-color: #abe2e2;
}

.my-icon {
  height: calc(35px + 6 * ((100vw - 150px) / 8000)) !important;
  width: calc(35px + 6 * ((100vw - 150px) / 8000)) !important;
}
</style>
