<template>
  <b-container fluid>
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <header class="modal-header">
          <h5 class="my-modal-title">{{$tc('message.deposit', 1)}}</h5>
        </header>

        <div class="modal-body">
          <ValidationObserver ref="form" v-slot="{ invalid, pending }">
            <b-row>
              <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                <!-- Name -->
                <ValidationProvider :rules="{ required:true, max: 50 }" :name="`${$tc('message.name', 1)}`" v-slot="{ errors }">
                  <b-form-group :label="`${$tc('message.name', 1)} (*)`">
                    <b-form-input id="name"
                                  type="text"
                                  v-model="input.nome"
                                  :state="lodash.first(errors) && itemChanged ? false : null">
                    </b-form-input>

                    <b-form-invalid-feedback id="inputLiveFeedback">
                      {{ lodash.first(errors) }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                <!-- Description -->
                <ValidationProvider :rules="{ max: 50 }" :name="`${$tc('message.description', 1)}`" v-slot="{ errors }">
                  <b-form-group :label="`${$tc('message.description', 1)}`">
                    <b-form-input id="description"
                                  type="text"
                                  v-model="input.descrizione"
                                  :state="lodash.first(errors) && itemChanged ? false : null">
                    </b-form-input>

                    <b-form-invalid-feedback id="inputLiveFeedback">
                      {{ lodash.first(errors) }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col cols="12" sm="12" md="12" lg="6" xl="6"  >
              <!-- Emails -->
              <ValidationProvider :rules="{ max: 50, email: true }" :name="`${$tc('message.email', 1)}`" v-slot="{ errors }">
                <b-form-group :label="`${$tc('message.email', 1)}`">
              <b-input-group>
                <b-form-input id="email"
                              v-model="email"
                              :state="lodash.first(errors) || input.indirizzi_email.length === 5 ? false : null">

                </b-form-input>
                <b-input-group-append>
                <b-button class="b-c-green" :disabled="lodash.first(errors) || email.length === 0 || input.indirizzi_email.length === 5 ? true : null" @click="input.indirizzi_email.push(email); email = ''">{{$tc('message.add', 1)}}</b-button>
                </b-input-group-append>
                <b-form-invalid-feedback id="inputLiveFeedback">
                  {{ input.indirizzi_email.length === 5 ? $tc('errors.muchEmails', 1) :lodash.first(errors) }}
                </b-form-invalid-feedback>
              </b-input-group>
                </b-form-group>
              </ValidationProvider>
              </b-col>

              <b-col cols="12" sm="12" md="12" lg="6" xl="6"  >
                <!-- Emails -->
                  <b-form-group :label="`${$tc('message.email', 1)} ${$tc('message.added', 3).toLowerCase()}`" >
                    <b-input-group v-for="(item,index) in input.indirizzi_email" :key="index" class="mb-1">
                          <b-form-input :disabled="true" :placeholder="item" style="background-color: white !important;">{{item}}</b-form-input>
                          <b-input-group-append>
                            <b-button class="b-c-green" @click="input.indirizzi_email = input.indirizzi_email.filter(function(el) {return el !== item})">{{$tc('message.deselectLabel', 1)}}</b-button>
                          </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
              </b-col>
            </b-row>

            <b-row class="my-border-top" align-h="end">
              <b-col cols="12" sm="12" md="12" lg="auto" xl="auto">
                <b-button id="save"
                          type="button"
                          class="float-left mt-2 b-c-green"
                          :disabled="input.indirizzi_email === null || input.indirizzi_email.length === 0"
                          @click="$refs.modalCalendar.show()"> {{$tc('message.sendNotification', 1)}}
                </b-button>
              </b-col>
              <b-col cols="12" sm="12" md="12" lg="auto" xl="auto">
                <b-button id="save"
                          type="button"
                          class="float-right mt-2 b-c-green"
                          :disabled="invalid || pending || !itemChanged"
                          @click="save(false)"> {{$tc('message.save', 1)}}
                </b-button>
              </b-col>

              <b-col cols="12" sm="12" md="12" lg="auto" xl="auto">
                <b-button id="save-and-exit"
                          type="button"
                          class="float-right mt-2 b-c-green"
                          :disabled="invalid || pending || !itemChanged"
                          @click="save(true)"> {{$tc('message.saveClose', 1)}}
                </b-button>
              </b-col>

              <b-col cols="12" sm="12" md="12" lg="auto" xl="auto">
                <b-button id="cancel-button"
                          type="button"
                          class="float-right mt-2"
                          @click="exitCheck(itemChanged)"> {{$tc('message.close', 1)}}
                </b-button>
              </b-col>
            </b-row>
          </ValidationObserver>
        </div>
        <b-modal ref="modalCalendar" id="modalCalendar" :title="`${$t('message.choose')} ${$t('message.date').toLowerCase()}!`"
                 no-close-on-backdrop no-close-on-esc hide-header-close hide-footer>
          <b-container fluid>
              <!-- Date start -->
                <b-form-group :label="`${$tc('message.date', 1)} (*)`">
                  <b-input-group>
                    <flat-pickr id="date"
                                ref="date"
                                v-model="notification.data"
                                :config="dateConfig">
                    </flat-pickr>

                    <div class="input-group-append">
                      <b-button class="fas fa-trash"
                                @click="notification.data = null"
                                :disabled="!notification.data">
                      </b-button>
                    </div>
                  </b-input-group>
                </b-form-group>
            <div slot="modal-footer" class="w-100 justify-content-end d-flex">
              <b-button id="nw-ok-error-message" class="m-1 b-c-green" :disabled="notification.data === null" @click="sendNotification()">{{$tc('message.sendNotification', 1)}}</b-button>
            </div>
          </b-container>

        </b-modal>
      </div>
    </div>
  </b-container>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'
import { Italian } from 'flatpickr/dist/l10n/it'

export default {
  name: 'EditDeposit',
  created () {
    this.fetchData().then(() => {
      this.addWatcher()
    })
  },
  mounted () {
    this.$root.$on('change-lang', () => {
      this.$nextTick(() => {
        if (this && this.$refs && this.$refs.form) this.$refs.form.validate()
      })
    })
  },
  data () {
    const depositId = this.$route.params.id
    const lodash = _
    const backPath = '/registry/deposit'
    const dateConfig = {
      dateFormat: 'Y-m-d',
      mode: 'single',
      altFormat: 'D j M Y',
      altInput: true,
      locale: Italian,
      minDate: new Date(),
      maxDate: '',
      disable: []
    }
    return {
      dateConfig,
      backPath,
      depositId,
      lodash,
      input: {
        indirizzi_email: []
      },
      watcher: null,
      itemChanged: false,
      email: '',
      notification: {
        data: null,
        id_deposito: depositId
      }
    }
  },
  methods: {
    resetWatcher () {
      this.$root.$emit('activeLoader', true)
      if (this.watcher) this.watcher()
      this.itemChanged = false
    },
    addWatcher () {
      this.$root.$emit('activeLoader', false)
      this.watcher = this.$watch('input', () => {
        this.itemChanged = true
      }, { deep: true })
    },
    fetchData () {
      this.resetWatcher()
      const id = this.depositId
      if (id !== 'new') {
        return Vue.myGet(this, `/deposito/${id}`).then(result => {
          this.input = result
          return true
        }).catch(error => {
          Vue.manageErrors(this, error)
          return true
        })
      }
      return new Promise(resolve => resolve())
    },
    exitCheck (itemChanged) {
      if (itemChanged) this.$root.$emit('exit-check', { redirect: this.backPath })
      else this.$router.push(this.backPath)
    },
    save (pushBack) {
      Vue.patchOrPost(this.depositId !== 'new',
          `/deposito${this.depositId !== 'new' ? '/' + this.depositId : ''}`, this.input).then(r => {
        Vue.success(this, `${this.$tc('message.deposit', 1)} ${this.$t(this.depositId !== 'new' ? 'success.putSuccess' : 'success.postSuccess')}`)
        if (pushBack) this.$router.push(this.backPath)
        else this.$router.push({ name: 'RefreshPage', params: { id: this.input.id || r.data.id, name: 'EditDeposit' } })
      }).catch(errorObj => {
        Vue.manageErrors(this, errorObj)
      })
    },
    pushToEdit (event) {
      const item = event && event.item
      item && item.id
          ? this.$router.push({
            name: 'EditDeposit',
            params: { id: item.id }
          })
          : this.$router.push({
            name: 'EditDeposit',
            params: { id: 'new' }
          })
    },
    async sendNotification () {
      this.$root.$emit('activeLoader', true)
      await Vue.patchOrPost(false,
          '/notifica_deposito', this.notification).then(r => {
            if (r.data.success === false) {
              Vue.warning(this, this.$tc('warnings.notProgrammedTripForThisDate', 1))
            } else {
              Vue.success(this, `${this.$tc('message.notificationSendedToRecipients', 1)}`)
            }
        this.$root.$emit('activeLoader', false)
        this.$router.push(this.backPath)
      }).catch(errorObj => {
        Vue.manageErrors(this, errorObj)
      })
    }
  }
}
</script>
<style scoped>
.form-control::placeholder {
  color: black !important;
  opacity: 1;
}
</style>
