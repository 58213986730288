<template>
  <span class="mw-100">
    <span v-for="field in fields" :key="field">
      <number-flip :value="getValue(field, value)" :color="color" :width="width"></number-flip>
    </span>
  </span>
</template>

<script>
import { Settings, DateTime } from 'luxon'

export default {
  name: 'Calendarpicker',
  props: ['value', 'fields', 'color', 'width'],
  methods: {
    getValue (field, value) {
      return DateTime.fromISO(value)[field]
    }
  },
  mounted () {
    this.$root.$on('change-lang', (val) => {
      Settings.defaultLocale = val
    })
  }
}
</script>

<style scoped>

</style>
