import _ from 'lodash'

const MySelectValidationClass = {
  install (Vue) {
    Vue.MySelectValidationClass = (it, ref, groupChanged, valid, errors, required) => {
      if (it.$refs[ref] && it.$refs[ref].$el && it.$refs[ref].$el.children && it.$refs[ref].$el.children.length !== 0) {
        const select = _.first(it.$refs[ref].$el.children)
        const validClass = 'vs__dropdown-toggle form-control is-valid'
        const invalidClass = 'vs__dropdown-toggle form-control is-invalid'
        const nullClass = 'vs__dropdown-toggle'
        const hasErrors = _.first(errors)

        if (required) {
          select.className = hasErrors && groupChanged ? invalidClass : (valid ? (groupChanged ? validClass : nullClass) : nullClass)
        } else {
          select.className = hasErrors && groupChanged ? invalidClass : nullClass
        }
      }
    }
  }
}

export default MySelectValidationClass
