/* eslint-disable no-param-reassign */
const MyLocalStorage = {
  install (Vue) {
    const prefix = 'BBBus'

    Vue.setLocalStorage = (key, object) => {
      key = `${prefix}.${key}`
      return localStorage.setItem(key, JSON.stringify(object))
    }

    Vue.getLocalStorage = (key) => {
      key = `${prefix}.${key}`
      return JSON.parse(localStorage.getItem(key))
    }

    Vue.deleteLocalStorage = (key) => {
      key = `${prefix}.${key}`
      return localStorage.removeItem(key)
    }
  }
}

export default MyLocalStorage
