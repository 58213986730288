// main components
import Vue from 'vue'
import Router from 'vue-router'
import routeViews from '../router-views'

// custom plugins
import MyExcelFromDate from '@/plugins/my-excel-from-table'
import MyTableFilter from '@/plugins/my-table-filter'
import MyChangeBreadcrumb from '@/plugins/my-change-breadcrumb'
import MySelectAll from '@/plugins/my-select-all'
import MyTableSorted from '@/plugins/my-table-sorted'
import MyCheckAllSelected from '@/plugins/my-check-all-selected'
import MyGetWindowSize from '@/plugins/my-get-window-size'
import MyLocalStorage from '@/plugins/my-local-storage'
import MyServerInteraction from '@/plugins/my-server-interaction'
import MyNotify from '@/plugins/my-notify'
import MyParseExcel from '@/plugins/my-parse-excel'
import MyDownloadFile from '@/plugins/my-download-file'
import myAuthorizationMaskIndexes from '@/plugins/my-authorization-mask-indexes'

// Vue installations
Vue.use(Router)
Vue.use(MyExcelFromDate)
Vue.use(MyTableFilter)
Vue.use(MyChangeBreadcrumb)
Vue.use(MySelectAll)
Vue.use(MyTableSorted)
Vue.use(MyCheckAllSelected)
Vue.use(MyGetWindowSize)
Vue.use(MyLocalStorage)
Vue.use(MyServerInteraction)
Vue.use(MyNotify)
Vue.use(MyParseExcel)
Vue.use(MyDownloadFile)
const route = new Router({
  routes: routeViews.routes
})

route.beforeEach((to, from, next) => {
  const loggedIn = Vue.getLocalStorage('logged')
  const session = Vue.getLocalStorage('session')
  // eslint-disable-next-line camelcase
  const auth_mask = session !== undefined && session !== null && session.operator !== undefined ? session.operator.auth_mask : null
  if (loggedIn || to.name === '/') {
    const timer = Vue.getLocalStorage('timer')
    clearInterval(timer)
    const timerPersonalizationObu = Vue.getLocalStorage('timerPersonalizationObu')
    clearInterval(timerPersonalizationObu)
  }

  const translatePathName = myAuthorizationMaskIndexes.pathNameForTranslate(to.name)
  // eslint-disable-next-line camelcase
  if (auth_mask && auth_mask[myAuthorizationMaskIndexes.CRUD(translatePathName, 'READ')] === '0') {
    next({
      path: '/path_not_active'
    })
  } else {
    next()
  }
})
export default route
